import Twitter from '../../assets/images/twitter-lite.svg'
import IndiaFlag from "../../assets/images/india-flag.svg"
import DublinFlag from "../../assets/images/dublin-flag.svg";
import TrustWallet from '../../assets/images/trust-wallet.svg';
import UPriceCardLogo from '../../assets/images/unidef-icon-yellow.svg'
import { routePaths } from '../../routes';
import Info from '../../assets/images/info-circle-lite.svg';
import Token1 from '../../assets/images/token1.png'
import Token2 from '../../assets/images/token2.png'
import Token3 from '../../assets/images/token3.png'
import newTokenCard1 from '../../assets/images/new-token-card1.svg';
import newTokenCard2 from '../../assets/images/new-token-card2.svg';
import newTokenCard3 from '../../assets/images/new-token-card3.svg';
import newTokenCard4 from '../../assets/images/new-token-card4.svg';
import newTokenCard1S6 from '../../assets/images/s6-card1.svg';
import newTokenCard2S6 from '../../assets/images/s6-card2.svg';
import newTokenCard3S6 from '../../assets/images/s6-card3.svg';
import newTokenCard4S6 from '../../assets/images/s6-card4.svg';
import newTokenCard5S6 from '../../assets/images/s6-card5.svg';
import TokenChart from '../../assets/images/token-chart.webp'
import twitterTokenCard from '../../assets/images/twitter-token-card.svg';
import faceBookTokenCard from '../../assets/images/facebook-token.svg';
import instagramTokenCard from '../../assets/images/instagram-token.svg';
import teligramTokenCard from '../../assets/images/teligram-token.svg';
import linkdinTokenCard from '../../assets/images/linkdin-circle.png'
import ForumTokenCard from '../../assets/images/forum-home-dark.svg'
import telegramIconMobile from '../../assets/images/telegram-uranium.svg';
import facebookIconMobile from '../../assets/images/facebook-uranium.svg';
import instagramIconMobile from '../../assets/images/instagram-uranium.svg';
import linkedinIconMobile from '../../assets/images/linkedin-uranium.svg';
import twitterIconMobile from '../../assets/images/twitter-icon-mobile.svg'
import unidefFouramMobile from '../../assets/images/unidef-fouram-mobile.svg';
import coinMarkrtcapMobile from '../../assets/images/CoinMarketCap.svg'
import PlusIcon1 from '../../assets/images/plus-icon.svg';
import oneImage from '../../assets/images/one-images.webp'
import twoImage from '../../assets/images/two-images.webp'
import threeImage from '../../assets/images/three-images.webp'
import fourImage from '../../assets/images/four-images.webp'
import homeSlider1 from '../../assets/images/home-slider-image1.svg'
import homeSlider3 from '../../assets/images/home-slider-image3.png'
import homeSlider2 from '../../assets/images/home-slider-image2.svg'
import AguniumImage from '../../assets/images/agunium-image.png'
import NexensuImage from '../../assets/images/nexensu-image.png'
import UnidefIlabsHome from '../../assets/images/ilabs-image.png'
import AgilanceImage from '../../assets/images/agilance-image.png'
import TwitterHome from '../../assets/images/twitter-home-black.svg'
import TeligramHome from '../../assets/images/teligram-home-black.svg'
import InstagramHome from '../../assets/images/insta-home-black.svg'
import FacebookHome from '../../assets/images/facebook-home-black.svg'
import TwitterHomeColored from '../../assets/images/twitter-home-colord.svg'
import TeligramHomeColored from '../../assets/images/teligram-home-colord.svg'
import InstagramHomeColored from '../../assets/images/instagram-home-colord.svg'
import FacebookHomeColored from '../../assets/images/facebook-home-colord.svg'
import TwitterHomeS9 from '../../assets/images/twitter-home-lite.svg'
import TelegramHomeS9 from '../../assets/images/telegram-home-lite.svg'
import InstagramHomeS9 from '../../assets/images/instagram-home-lite.svg'
import FacebookHomeS9 from '../../assets/images/facebook-home-lite.svg'
import coinMarkrtcap from '../../assets/images/coinMarket-cap.svg'
import LinkedInHomeS9 from '../../assets/images/linkdin-home-lite.svg'
import LinkdinHomeColored from '../../assets/images/linkdin-circle.png'
import LinkdinHome from '../../assets/images/linkdin-new-home-lite1.svg'
import PancakeSwap from '../../assets/images/PancakeSwap.svg'
import oneinch from '../../assets/images/1inch.svg'
import Matcha from '../../assets/images/Matcha.svg'
import Bitrue from '../../assets/images/Biture.svg'
import Bitforex from '../../assets/images/Bitforex.png'
import Digifinex from '../../assets/images/Digifinex.svg'
import MetaMask from '../../assets/images/Metamask.svg'
import TrustWallet1 from '../../assets/images/trust-wallet-lite.svg'
import MathWallet from '../../assets/images/Math Wallet.svg'
import Coinnine from '../../assets/images/Coin98.svg'
import SafePal from '../../assets/images/SafePal.svg'
import TokenPocket from '../../assets/images/Token Pocket.svg'
import CoinMarketCap from '../../assets/images/CoinMarketCap.svg'
import CoinGecko from '../../assets/images/CoinGecko.svg'
import BSC from '../../assets/images/BSCScan.svg'
import Binance from '../../assets/images/Binance.svg'
import LiveConWatch from '../../assets/images/LiveCoinWatch.svg'
import aboutSlider1 from '../../assets/images/about-slider-1.webp'
import aboutSlider2 from '../../assets/images/about-slider-2.webp'
import aboutSlider3 from '../../assets/images/about-slider-3.webp'
import Loc1 from '../../assets/images/loc1.webp'
import Loc2 from '../../assets/images/loc2.webp'
import Loc3 from '../../assets/images/loc3.webp'
import Loc4 from '../../assets/images/loc4.webp'
import Loc5 from '../../assets/images/loc5.webp'
import Loc6 from '../../assets/images/loc6.webp'
import Loc7 from '../../assets/images/loc7.webp'
import Loc8 from '../../assets/images/loc8.webp'
import LocationNew1 from '../../assets/images/loc-mob1.webp'
import LocationNew2 from '../../assets/images/loc-mob2.webp'
import LocationNew3 from '../../assets/images/loc-mob3.webp'
import LocationNew4 from '../../assets/images/loc-mob4.webp'
import LocationNew5 from '../../assets/images/loc-mob5.webp'
import LocationNew6 from '../../assets/images/loc-mob6.webp'
import LocationNew7 from '../../assets/images/loc-mob7.webp'
import LocationNew8 from '../../assets/images/loc-mob8.webp'
import OrigalFile from '../../files/Original.txt';
import HDFile from '../../files/HD.txt';
import K4File from '../../files/4k.txt';
import WebFile from '../../files/web.txt';
import Article1 from '../../assets/images/article1.png';
import Article2 from '../../assets/images/article2.png';
import Article3 from '../../assets/images/article3.png';
// import Article4 from '../../assets/images/article1.png';
// import Article5 from '../../assets/images/article2.png';
// import Article6 from '../../assets/images/article3.png';
// import Article7 from '../../assets/images/article1.png';
import discordIconMobile from '../../assets/images/discord-uranium.svg'
import CircleWhite from '../../assets/images/circle-white-mini.svg'
import InvCard1 from '../../assets/images/inv-card1.webp'
import InvCard2 from '../../assets/images/inv-card2.webp'
import InvCard3 from '../../assets/images/inv-card3.webp'
import Mexc from '../../assets/images/mexc-logo.svg'
import Chine from '../../assets/images/chine-flag.png'
import Turkey from '../../assets/images/turkey-flag.png'
import UAE from '../../assets/images/uae-flag.png'
import UK from '../../assets/images/uk-flag.png'
import USA from '../../assets/images/usa-flg.png'
import InvaCard1 from '../../assets/images/inva-1.svg'
import InvaCard2 from '../../assets/images/inva-2.svg'
import InvaCard3 from '../../assets/images/inva-3.svg'
import TwitterColoredAgunium from '../../assets/images/twitter-colored-agunium.svg'
import TwitterAgunium from '../../assets/images/twitter-agunium.svg'
import TeligramColoredAgunium from '../../assets/images/teligram-colored-agunium.svg'
import TeligramAgunium from '../../assets/images/teligram-agunium.svg'
import linkdinColoredAgunium from '../../assets/images/linkdin-colored-agunium.svg'
import linkdinAgunium from '../../assets/images/linkdin-agunium.svg'
import coinBase from '../../assets/images/coinbase-slider.svg'
import binance from '../../assets/images/binance-slider.svg'
import cnn from '../../assets/images/cnn-slider.svg'
import nexenSlider1 from '../../assets/images/nexen-slider-1.png'
import nexenSlider2 from '../../assets/images/nexen-slider-2.png'
import nexenSlider3 from '../../assets/images/nexen-slider-3.png'
import LinkdinHomeHover from '../../assets/images/linkdin-circle-color.svg';
import Bitcoin from '../../assets/images/BTC.svg'
import Ethereum from '../../assets/images/Ethereum.svg'
import BNB from '../../assets/images/BNB-Chain.svg'
import XRPLedger from '../../assets/images/XRP-Ledger.svg'
import Dogecoin from '../../assets/images/Dogecoin.svg'
import Tron from '../../assets/images/Tron.svg'
import Polygon from '../../assets/images/Polygon.svg'
import Uniswap from '../../assets/images/uniswap.png'
import TheGraph from '../../assets/images/The-Graph.svg'
import Algorand from '../../assets/images/Algorand.svg'
import Fantom from '../../assets/images/Fantom.svg'
import Decentraland from '../../assets/images/Decentraland.svg'
import KuCoin from '../../assets/images/Kucoin.svg'
import Chiliz from '../../assets/images/Chiliz.svg'
import IOTA from '../../assets/images/IOTA.svg'
import Huobi from '../../assets/images/Huobi.svg'
import TrustWalletCoin from '../../assets/images/Trust-Wallet-coin.svg'
import PancakeSwapCoin from '../../assets/images/Pancake-Swap.svg'
import HarmonyONE from '../../assets/images/Harmony-One.svg'
import Bitmart from '../../assets/images/Bitmart.svg'
import OKB from '../../assets/images/OKB.svg'
import Aave from '../../assets/images/Aave.svg'
import Pepe from '../../assets/images/Pepe.svg'
import Neo from '../../assets/images/Neo.svg'
import Loopring from '../../assets/images/Loopring.svg'
import Waves from '../../assets/images/Waves.svg'
import Verge from '../../assets/images/Verge.svg'
import Youtub from '../../assets/images/youtub-dark.svg'
import YoutubHover from '../../assets/images/youtub.svg'
import bannerFooter from '../../assets/images/banner-icon.svg'
import Youtub1 from '../../assets/images/youtube-gray.svg'
import FacebookHome1 from '../../assets/images/facebook-gray.svg'
import LinkdinHome1 from '../../assets/images/linkedin-gray.svg'
import InstagramHome1 from '../../assets/images/instagram-gray.svg'
import TeligramHome1 from '../../assets/images/telegram-gray.svg'
import TwitterHome1 from '../../assets/images/twitter-gray.svg'
export const TRANSLATIONS_EN = {
  //meta detail all pages
  home_title: "Unidef | The Investor & Builder of a Decentralized Future",
  home_description: "Unidef is a global network of organizations, working together to accelerate the world's transition to a decentralized future.",
  home_keyword: "Unidef, Unidef Global, Unidef Network, Unidef Organization, Agunium, Nexensu, Agilance, UnidefiLabs, Decentralized Future, Web3, Metaverse, Crypto, Cryptocurrency, NFTs, DeFi, AI",
  //
  verify: "Verify",
  apply_to_ilabs: "Apply to iLabs",
  apply_at_unidef: "Work at Unidef",
  partner_with_unidef: "Partner with Unidef",
  get_started: "Get Started",
  get_u: "Get U",
  see_all: "See All",
  ok: "OK",
  search: "Search",
  nav_link_about: "About",
  our_brands: "Our Brands",
  footer_description_1: "Unidef refers to one or more of United Decentralized Foundations (“UDF”), its global network of member organizations, and their related entities (collectively, the “Unidef organization”). UDF (also referred to as “Unidef Global”) and each of its member organizations and related entities are legally separate and independent entities, which cannot obligate or bind each other in respect of third parties. UDF and each UDF member firm and the related entity is liable only for its own acts and omissions, and not those of each other. Unidef Global does not provide services to clients. Please see www.unidef.org to learn more.",
  footer_description_2: "The property and any materials on the property are provided by Unidef WordWide Services (UWS). The products, services, information, and/or materials contained within these web pages may not be available for residents of certain jurisdictions. You should carefully consider your investment experience, financial situation, investment objectives, and risk tolerance and consult an independent financial adviser prior to making any investment. This material should not be construed as financial and tax advice. Investing in tokens is speculative and involves a high degree of risk. Tokens (e.g., shares, SAFTs, contracts, interests) may have no value, and investors may lose some or all of their investment. In the future, investors may not be able to sell their tokens for anything. You are solely responsible for your decisions. Unidef and/or its affiliates are not responsible for any losses you may incur. You can see our full Crypto Disclosure ",
  here: "here.",
  footer_description_3: "All product names, logos, and brands are the property of their respective owners. The use of these names, logos, and brands is for identification purposes only and does not imply endorsement or affiliation.",
  footer_description_4: "By using this website, you understand the information being presented is provided for informational purposes only and agree to our ",
  terms_of_Use: "Terms of Use ",
  and: "and ",
  privacy_policy: "Privacy Policy. ",
  footer_description_5: "For more information, see our ",
  disclosures: "disclosures",
  enter_query: "Enter query",
  verify_modal1_title1: "Verified Source",
  verify_modal1_subdetail: "The source you entered is an official domain from Unidef.",
  verify_modal2_title1: "Source Not Verified",
  verify_modal2_subdetail: "The source you entered is not verified and is not official. Please exercise caution and only interact with verified official Unidef sources.",
  footer_contact_address_text: "Unidef (U) Verified Smart Contract Address (BEP20):",
  footer_contact_address_copy: "0x89dB9B433FeC1307d3dc8908f2813e9f7a1d38F0",
  copied: "copied",
  copiright_tag: "© 2022-2023, Unidef and/or its affiliates",
  learn_more: "learn more",
  get_direction: "Get direction",
  apply_now: "Apply Now",
  token_new_s4_title1: "Made For",
  token_new_s4_title2: "Growth",
  token_new_s4_sub_title: "As the Unidef ecosystem expands, U’s impact on the new economy and Web3 will become more prominent. U serves as the open-source protocol layer of the ecosystem, serving several purposes, including:",
  token_new_s5_title1: "Made For ",
  token_new_s5_title2: "Mass Adoption.",
  token_new_s5_sub_title: "More and more wallets, exchanges, platforms, app providers, and service providers are joining us to support a decentralized future. At Unidef, we believe in the power of community to build towards this future. Interested in joining our community? ",
  contact_us: "Contact us",
  token_new_s6_title1: "How to Get",
  token_new_s6_title2: "Unidef (U)?",
  token_new_s6_sub_desc1: "Buying Unidef from ",
  token_new_s6_sub_desc2: "Pancake",
  token_new_s6_sub_desc5: "Swap.",
  token_new_s6_sub_desc4: " We're all for a decentralized future, which is why we suggest buying U from ",
  token_new_s6_sub_desc8: " Finance. If you prefer centralized options, U is also available on other exchanges.",
  sticky_button_text: "BUY/SELL",
  sticky_button_token_name: "Unidef (Ticker: U)",
  sticky_button_token_price: "$0.0002206",
  sticky_button_token_different: "+0.86%",
  sticky_button_text1: "READ DISCLAIMER",
  sticky_read1: "This information is drawn from other sources on the internet. unidef.org does not endorse or recommend any exchanges or make any representations with respect to exchanges or the purchase or sale of digital assets more generally. It’s advisable to conduct your own due diligence before relying on any third party or third-party technology, and providers may vary significantly in their compliance, data security, and privacy practices.",
  sticky_read2: "This website does not constitute an offer to purchase or solicitation to sell, nor is it a recommendation to buy or sell, any token or other product. Purchasing and selling tokens is inherently risky and holders must bear their risk of loss. Neither the authors of this website nor any participants in the Unidef project accept any liability for losses or taxes that holders, purchasers or sellers of Unidef (Ticker: U) may incur. The value of U may decrease and may be highly volatile.",
  sticky_read3: "This website is not directed towards any person located in a jurisdiction where purchasing, selling or holding U is prohibited or regulated. Consult your local laws before transacting in any cryptocurrency.",
  sticky_read1_highlite: [
    {
      id: 1,
      title: "unidef.org"
    }
  ],
  top_header_slider_data: [
    {
      id: 1,
      title: "Unidef launches Decemount to accelerate the world's transition to a decentralized future",
      url: "https://cointelegraph.com/press-releases/unidef-launches-decemount-to-accelerate-the-worlds-transition-to-a-decentralized-future/",
    },
    {
      id: 2,
      title: "Unidef Launches Revolutionary Initiative to Assist Crypto Community Affected by Centralized Platforms Misconduct",
      url: "https://news.bitcoin.com/unidef-launches-revolutionary-initiative-to-assist-crypto-community-affected-by-centralised-platforms-misconduct/",
    },
  ],
  disclaimer: "Disclaimer",
  breakBox_data: [
    {
      id: 1,
      title1: "Operational ",
      title2: "excellence",
      desc: "For us, our ability to maintain consistency of delivery lies in our commitment to an ongoing effort...",
    },
    {
      id: 2,
      title1: "Integrity, fairness, ",
      title2: "and responsibility",
      desc: "We believe that a world that works is one that works for all people, not just a select few based on influence..."
    },
    {
      id: 3,
      title1: "Success ",
      title2: "in unity",
      desc: "Alone we can do so little, together we can do so much. These are the words we live by, and it is reflected..."
    },
    {
      id: 4,
      title1: "Bring ",
      title2: "good vibes",
      desc: "Our success is driven by our ability to build relationships. We have the courage to foster trust, knowing..."
    },
    {
      id: 5,
      title1: "Owner’s ",
      title2: "Mentality",
      desc: "For us, our ability to maintain consistency of delivery lies in our commitment to an ongoing effort..."
    },
    {
      id: 6,
      title1: "Education ",
      title2: "",
      desc: "We believe that a world that works is one that works for all people, not just a select few based on influence..."
    },
  ],
  unidef_factor: [
    {
      id: 1,
      title: "Unidef iLabs",
      url: routePaths.ilabsScreen,
      description1: "Investing Capital & Expertise ",
      description2: "In Exceptional Founders",
      btnTxt: "Learn More",
    },
    {
      id: 2,
      title: "Agunium",
      url: routePaths.aguniumScreen,
      description1: "Investing in Cryptos",
      description2: "Since 2015",
      btnTxt: "Learn More",
    },
    {
      id: 3,
      title: "Nexensu",
      url: routePaths.nexensuScreen,
      description1: "Integrated Marketing Agency",
      description2: "Designed For What's Next",
      btnTxt: "Learn More",
    },
    {
      id: 4,
      title: "Agilance",
      url: routePaths.agilanceScreen,
      description1: "Integrated legal solutions",
      description2: "For A Decentralized Future",
      btnTxt: "Learn More",
    },
  ],
  unidef_footer_link: [
    {
      id: 1,
      title: "Learn",
      content: [
        { id: 1, name: 'Unidef', url: routePaths.homeScreenNew, target: "_self" },
        { id: 2, name: 'Unidef iLabs', url: routePaths.ilabsScreen, target: "_self" },
        { id: 3, name: 'Agunium', url: routePaths.aguniumScreen, target: "_self" },
        { id: 4, name: 'Nexensu', url: routePaths.nexensuScreen, target: "_self" },
        { id: 5, name: 'Agilance', url: routePaths.agilanceScreen, target: "_self" },
        { id: 6, name: 'VisionX', url: "http://visionx.foundation/", target: "_blank" }
      ]
    },
    {
      id: 2,
      title: "Contact",
      content: [
        { id: 1, name: 'General Enquiries', url: "mailto:info@unidef.org", target: "_self" },
        { id: 2, name: 'Investment & Partnership', url: "mailto:partner@unidef.org", target: "_self" },
        { id: 3, name: 'Legal Solutions', url: "mailto:Legal@unidef.org", target: "_self" },
        { id: 4, name: 'Marketing Solutions', url: "mailto:nexensu@unidef.org", target: "_self" },
        { id: 5, name: 'Media Relations', url: "mailto:media@unidef.org", target: "_self" },
        { id: 6, name: 'Request a Speaker', url: "mailto:Media@unidef.org", target: "_self" },
      ]
    },
    {
      id: 3,
      title: "Legal",
      content: [
        { id: 1, name: 'Unidef Verify', url: routePaths.newVerifyScreen, target: "_self" },
        { id: 2, name: 'Terms & Conditions', url: "https://go.unidef.org/TC", target: "_blank" },
        { id: 3, name: 'Privacy Policy', url: "https://go.unidef.org/Privacy", target: "_blank" },
        { id: 4, name: 'Social Media Disclaimer', url: "https://go.unidef.org/SMD", target: "_blank" },
        { id: 5, name: 'Disclosures', url: "https://go.unidef.org/FDS", target: "_blank" },
      ]
    },
    {
      id: 4,
      title: "Unidef",
      content: [
        { id: 1, name: 'About', url:"https://unidef.org/#about", target: "_self" },
        { id: 2, name: 'Careers', url: "https://www.linkedin.com/company/unidef?original_referer=http%3A%2F%2Flocalhost%3A3000%2F", target: "_blank" },
        { id: 3, name: 'LinkedIn', url: "https://linkedin.com/company/unidef", target: "_blank" },
        { id: 4, name: 'Newsroom', url: "", target: "_self" },
      ]
    },
  ],
  about_sub_navbar_data: [
    {
      id: 1,
      title: "Overview",
      redirect: ''
    },
    {
      id: 2,
      title: "Careers",
      // url: "/career",
      redirect: ''
    },
    {
      id: 3,
      title: "Mission",
      // url: "/mission",
      redirect: ''
    },
    {
      id: 4,
      title: "Press",
      to: routePaths.newAboutScreen,
      redirect: ''
    },
    {
      id: 5,
      title: "Contact",
      redirect: ''
    },
  ],
  //remove check after 
  questions: " Questions",
  question: " Question",
  steps: "steps",
  ///////
  utoken_accordian_data: [
    {
      id: 1,
      title: "Learn more about where we can hire",
      description: "Join a fast-growing community of developers and innovators connected all over the world, building the new era of the internet.",
    }
  ],
  //navigation link
  navigation_link_main_page: [
    {
      id: 1,
      name: "About",
      to: routePaths.homeScreenNew,
      eventKey: "about",
    },
    {
      id: 2,
      name: "Our Networks",
      to: routePaths.homeScreenNew,
      eventKey: "ourNetworks"
    },
    {
      id: 3,
      name: "Our Actions",
      to: routePaths.homeScreenNew,
      eventKey: "ourActions"
    },
    {
      id: 4,
      name: "Careers",
      to: routePaths.homeScreenNew,
      eventKey: "careers"
    },
    {
      id: 5,
      name: "Contact",
      to: routePaths.homeScreenNew,
      eventKey: "contact"
    },
    {
      id: 6,
      name: "More",
      to: routePaths.homeScreenNew,
      eventKey: "more"
    },
  ],
  navigation_link_uranium_page: [
    {
      id: 1,
      name: "About",
      to: routePaths.uraniumScreen,
      eventKey: "about"
    },
    {
      id: 2,
      name: "Our Networks",
      to: routePaths.homeScreenNew,
      eventKey: "ourNetworks"
    },
    {
      id: 3,
      name: "Our Actions",
      to: routePaths.homeScreenNew,
      eventKey: "ourActions"
    },
    {
      id: 4,
      name: "Careers",
      to: routePaths.homeScreenNew,
      eventKey: "careers"
    },
    {
      id: 5,
      name: "Contact",
      to: routePaths.uraniumScreen,
      eventKey: "contact"
    },
    {
      id: 6,
      name: "More",
      to: routePaths.homeScreenNew,
      eventKey: "more"
    },
  ],
  navigation_link_token_page: [
    {
      id: 1,
      name: "About",
      to: routePaths.homeScreenNew,
      eventKey: "about"
    },
    {
      id: 2,
      name: "Our Networks",
      to: routePaths.homeScreenNew,
      eventKey: "ourNetworks"
    },
    {
      id: 3,
      name: "Our Actions",
      to: routePaths.homeScreenNew,
      eventKey: "ourActions"
    },
    {
      id: 4,
      name: "Careers",
      to: routePaths.homeScreenNew,
      eventKey: "careers"
    },
    {
      id: 5,
      name: "Contact",
      to: routePaths.homeScreenNew,
      eventKey: "contact"
    },
    {
      id: 6,
      name: "More",
      to: routePaths.homeScreenNew,
      eventKey: "more"
    },
  ],
  navigation_link_new_about_page: [
    {
      id: 1,
      name: "About",
      to: routePaths.newAboutScreen,
      eventKey: "about"
    },
    {
      id: 2,
      name: "Our Bands",
      to: routePaths.newAboutScreen,
      eventKey: "ourBrand"
    },
    {
      id: 3,
      name: "Our Actions",
      to: routePaths.newAboutScreen,
      eventKey: "ourAction"
    },
    {
      id: 4,
      name: "Careers",
      to: routePaths.newAboutScreen,
      eventKey: "careers"
    },
    {
      id: 5,
      name: "Stay in Touch",
      to: routePaths.newAboutScreen,
      eventKey: "stayInTouch"
    },
  ],
  navigation_link_new_agunium_page: [
    {
      id: 1,
      name: "About",
      to: routePaths.aguniumScreen,
      eventKey: "about"
    },
    {
      id: 2,
      name: "Our Bands",
      to: routePaths.aguniumScreen,
      eventKey: "ourBrand"
    },
    {
      id: 3,
      name: "Our Actions",
      to: routePaths.aguniumScreen,
      eventKey: "ourAction"
    },
    {
      id: 4,
      name: "Careers",
      to: routePaths.aguniumScreen,
      eventKey: "careers"
    },
    {
      id: 5,
      name: "Stay in Touch",
      to: routePaths.aguniumScreen,
      eventKey: "stayInTouch"
    },
    {
      id: 6,
      name: "More",
      to: routePaths.aguniumScreen,
      eventKey: "more"
    },
  ],
  navigation_link_new_ilabs_page: [
    {
      id: 1,
      name: "About",
      to: routePaths.ilabsScreen,
      eventKey: "about"
    },
    {
      id: 2,
      name: "Our Bands",
      to: routePaths.ilabsScreen,
      eventKey: "ourBrand"
    },
    {
      id: 3,
      name: "Our Actions",
      to: routePaths.ilabsScreen,
      eventKey: "ourAction"
    },
    {
      id: 4,
      name: "Careers",
      to: routePaths.ilabsScreen,
      eventKey: "careers"
    },
    {
      id: 5,
      name: "Stay in Touch",
      to: routePaths.ilabsScreen,
      eventKey: "stayInTouch"
    },
  ],
  ///tokenNewPage-data
  unidef_coin: "Unidef (U)",
  token_sub_navbar_data: [
    {
      id: 1,
      title: "Overview",
      redirect: ''
    },
    {
      id: 2,
      title: "Decemount",
      // link: '/decemount',
      redirect: routePaths.uraniumScreen,
    },
    {
      id: 3,
      title: "Governance",
      link: 'https://docs.unidef.org/decemount',
      target: '_blank'
    },
    {
      id: 4,
      title: "Discussion",
      link: 'https://forum.decemount.unidef.org/',
      target: '_blank'
    },
    {
      id: 5,
      title: "Proposals",
      link: 'https://vote.decemount.unidef.org/',
      target: '_blank'
    },
    {
      id: 6,
      title: "News",
      redirect: ''
    },
    {
      id: 7,
      title: "Community",
      redirect: '',
      // link: 'https://t.me/UnidefGlobal',
      target: '_blank'
    },
  ],
  token_sub_navbar_data1: [
    {
      id: 1,
      title: "Overview",
      redirect: ''
    },
    {
      id: 2,
      title: "Unidef (U)",
      // link: '/unidef',
      redirect: routePaths.tokenNewScreen,
      target: '_blank'
    },
    {
      id: 3,
      title: "Governance",
      link: 'https://docs.unidef.org/decemount',
      target: '_blank'
    },
    {
      id: 4,
      title: "Discussion",
      link: 'https://forum.decemount.unidef.org/',
      target: '_blank'
    },
    {
      id: 5,
      title: "Proposals",
      link: 'https://vote.decemount.unidef.org/',
      target: '_blank'
    },
    {
      id: 6,
      title: "News",
      redirect: ''
    },
    {
      id: 7,
      title: "Community",
      redirect: '',
      // link: 'https://t.me/UnidefGlobal',
      target: '_blank'
    },
  ],
  ///token-data
  token_header_text: 'This website is still under construction.',
  your_key_for: "Your Key To ",
  working_paper: "Working Paper",
  a_decentralized: "A Decentralized",
  future: "Future",
  token_sub_desc1: "Unidef Coin ",
  token_sub_desc2: "(Ticker: U) ",
  token_sub_desc3: "is the key to shaping Uranium's ambitious mission of building the world's ",
  token_sub_desc4: "largest ",
  token_sub_desc5: "Decentralized Autonomous ",
  token_sub_desc6: "Organization.",
  token_s1_text: "Utility. Governance. Community. Exposure.",
  about_unidef: "About Unidef (U)",
  token_s2_t1: "Unidef (Ticker:",
  token_s2_t2: "U",
  token_s2_t3: ") is a utility and governance token designed to empower the community to own and drive a decentralized future. It serves as a bridge that connects people and organizations to embrace what truly matters. ",
  token_s2_t4: "Get U today ",
  token_s2_t5: "and take the first step towards a decentralized future.",
  show_more_data: [
    {
      id: 1,
      img: UPriceCardLogo,
      title: "The Power Of One",
      description: "U is a utility token that is used for accessing the entire Unidef interconnected global networks, including Decemount, Unidef Way, and Unidef iLabs, as well as any projects invested under the Unidef umbrella.",
      showMore: "show more",
      data: [
        {
          id: 1,
          description: "Additionally, U serves as the governance token within the Decemount Network. As the Unidef ecosystem expands, U’s impact on the new economy and Web3 will become more prominent.",
        },
        {
          id: 2,
          description: "And all of this is possible thanks to the \"Power of One\" – fueled by Unidef (U) – that drives the entire process.",
        },
      ]
    },
    {
      id: 2,
      img: UPriceCardLogo,
      title: "The Power Of U",
      description: "U is a governance and utility token designed to empower the community to own and drive a decentralized future. It will serve as decentralized protocol layer for community-led initiatives, making a decentralized future accessible to all.",
      showMore: "show more",
      data: [
        {
          id: 10,
          description: "With U, you hold the key to shaping Decemount's bold mission of building the World's Largest Network of DAOs.",
        },
      ]
    },
    {
      id: 3,
      img: UPriceCardLogo,
      title: "Reflect Your Valuesn",
      description: "U represents more than just a utility and governance token.Every time you get or use U –, you make a statement - about who you are and what you stand for. That's why we've teamed up with organizations that put \"doing the right thing\" at the heart of their businesses.",
    },
    {
      id: 4,
      img: UPriceCardLogo,
      title: "Join the Movement Now!",
      description: "At Decemount, everyone is welcome to embrace the decentralized world. We've compiled a list of key resources for you, covering education, technical support, and more, to help you get started. Click to expand and access these resources:",
      showMore: "show more",
      data: [
        {
          id: 1,
          dotImg: CircleWhite,
          title: "unidef.org/decemount :",
          url: "https://unidef.org/decemount",
          description: " This is the hub for Decemount ' s DAO, providing an interface to educate members on the governance process and access to the necessary channels for the efficient operation of the DAO.",
        },
        {
          id: 2,
          dotImg: CircleWhite,
          title: "docs.decemount.unidef.org :",
          url: "https://docs.decemount.unidef.org",
          description: " This is your ultimate guide to enhancing your experiences with Decemount. On this website, you'll learn about Decemount, get a better understanding of what exactly Decemount is, and delve into advanced topics, along with exploring more use cases. Let's get started!",
        },
        {
          id: 3,
          dotImg: CircleWhite,
          title: "forum.decemount.unidef.org :",
          url: "https://forum.decemount.unidef.org/",
          description: " This is where it all starts. Any Decemount Improvement Proposals (DIPs) must be submitted as posts and verified by moderators to ensure compliance with approved guidelines. Only U holders who have been through a wallet authentication process have the ability to submit DIPs or comment on them.",
        },
        {
          id: 4,
          dotImg: CircleWhite,
          title: "vote.decemount.unidef.org :",
          url: "https://vote.decemount.unidef.org/",
          description: " This channel is used for voting. Once an idea has been approved, it proceeds to Snapshot, where voting takes place. Authenticated U holders can vote after going through a wallet authentication process. This process ensures the secure, reliable, and efficient functioning of the DAO while allowing its members maximum utility in driving forward a decentralized future!",
        },
        {
          id: 5,
          dotImg: CircleWhite,
          title: "@decemount :",
          url: '/decemount',
          idName: "uranium-section-one",
          description: " This social media will provide news and notifications related to Decemount. Follow us on  ",
          and: " and ",
          onegap: ", ",
          twogap: ", ",
          buttonName1: "Twitter",
          link1: "https://twitter.com/intent/follow?screen_name=Decemount",
          buttonName2: "Telegram",
          link2: "https://t.me/Decemount",
          buttonName3: "Instagram",
          link3: "https://www.instagram.com/Decemount/",
          buttonName4: "Facebook",
          link4: "https://www.facebook.com/Decemount",
          description8: " to stay up-to-date on the latest developments!.",
        }
      ]
    },
  ],
  show_more_data_uranium: [
    {
      id: 1,
      img: UPriceCardLogo,
      title: "The Power Of One",
      description: "Decemount is made up of DAOs. These member DAOs and each of their related initiatives, along with Unidef and its member entities, form Unidef Organization. This structure allows Unidef (Ticker: U) to become an industry leader at all levels — locally, nationally, and globally. Join us in our mission to build a decentralized future.",
    },
    {
      id: 2,
      img: UPriceCardLogo,
      title: "The Power Of U",
      description: "Unidef (also known by its ticker symbol, U) is the key to shaping Decemount's ambitious mission of building the world's largest network of Decentralized Autonomous Organizations.",
      description1: "U holders will come together as a collective, governing Decemount's treasury, partnerships initiatives, and operational ecosystem.",
    },
    {
      id: 3,
      img: UPriceCardLogo,
      title: "The Decentralized Governance",
      description: "Decemount's DAO is the autonomous governing body behind Decemount, whose mission is to empower builders of a decentralized future. It is an independent establishment that  makes its own decisions based on the wishes of its members.",
      description1: "U holders determine its future through decentralized governance, leveraging the power of their votes to shape and support its vibrant ecosystem. Every U holder's voice counts, and every vote matters!",
    },
    {
      id: 4,
      img: UPriceCardLogo,
      title: "Join the Movement Now!",
      description: "At Decemount, everyone is welcome to embrace the decentralized world. We've compiled a list of key resources for you, covering education, technical support, and more, to help you get started. Click to expand and access these resources:",
      showMore: "show more",
      data: [
        {
          id: 1,
          title: "unidef.org/decemount: ",
          url: 'https://unidef.org/decemount',
          description: "This is the hub for Decemount's DAO, providing an interface to educate members on the governance process and access to the necessary channels for the efficient operation of the DAO.",
        },
        {
          id: 2,
          title: "docs.decemount.unidef.org: ",
          url: "https://docs.decemount.unidef.org",
          description: "This is your ultimate guide to enhancing your experiences with Decemount. On this website, you'll learn about Decemount, get a better understanding of what exactly Decemount is, and delve into advanced topics, along with exploring more use cases. Let's get started!",
        },
        {
          id: 3,
          title: "forum.decemount.unidef.org: ",
          url: "https://forum.decemount.unidef.org/",
          description: " This is where it all starts. Any Decemount Improvement Proposals (DIPs) must be submitted as posts and reviewed by moderators to ensure compliance with approved guidelines. Only U holders who have been through a wallet authentication process have the ability to submit DIPs or comment on them",
        },
        {
          id: 4,
          title: "vote.decemount.unidef.org: ",
          url: "https://vote.decemount.unidef.org/",
          description: " This channel is used for voting. Once an idea has been approved, it proceeds to Snapshot, where voting takes place. Authenticated U holders can vote after going through a wallet authentication process. This process ensures the secure, reliable, and efficient functioning of the DAO  while allowing its members maximum utility in driving forward a decentralized future!",
        },
        {
          id: 5,
          title: "@decemount: ",
          url: '/decemount',
          idName: "uranium-section-one",
          description: " This social media will provide news and notifications related to Decemount. Follow us on  ",
          and: " and ",
          onegap: ", ",
          twogap: ", ",
          buttonName1: "Twitter",
          link1: "https://twitter.com/intent/follow?screen_name=Decemount",
          buttonName2: "Telegram",
          link2: "https://t.me/Decemount",
          buttonName3: "Instagram",
          link3: "https://www.instagram.com/Decemount/",
          buttonName4: "Facebook",
          link4: "https://www.facebook.com/Decemount",
          description8: " to stay up-to-date on the latest developments!.",
        },
      ]
    },
  ],
  token_number_data: [
    {
      id: 1,
      title: "$0.0002206",
      subTitle: "Unidef Price (U)",
    },
    {
      id: 1,
      title: "U172B",
      subTitle: "Circulating Supply",
    },
    {
      id: 1,
      title: "$37M",
      subTitle: "Market Cap",
    }
  ],
  token_mobile_number_data: [
    {
      id: 1,
      data: [
        {
          id: 1,
          title: "$0.0002206",
          subTitle: "Unidef Price (U)",
        },
        {
          id: 1,
          title: "U172B",
          subTitle: "Circulating Supply",
        },
        {
          id: 1,
          title: "$37M",
          subTitle: "Market Cap",
        }
      ]
    },
    {
      id: 2,
      img: TokenChart
    }
  ],
  token_s2_text2: "Audited by leading global security firms, providing real-time monitoring and analysis.",
  token_s3_title1: "The Unidef (U)",
  token_s3_title2: "Trust Center",
  token_s3_t2: "Transparency builds trust, and we like to be perfectly clear.",
  token_s3_t3: "#6",
  token_s3_t4: "#13",
  token_s3_tooltip: "The metrics here are for informational purposes only.Results were generated a few mins ago. Pricing data is updated frequently — currency in USD.",
  token_coin_slider_data: [
    {
      id: 1,
      img: Token1,
      name: "New York Times",
      description: "U token enjoys significant Growth in 2022 by Unidef! enjoys significant.",
      date: "January 02, 2023",
    },
    {
      id: 2,
      img: Token2,
      name: "BBC",
      description: "BNB additionally uses a real-time burning mechanism based on gas fees.",
      date: "January 04, 2023",
    },
    {
      id: 3,
      img: Token3,
      name: "Coinbase",
      description: "BNB is the cryptocurrency coin that powers the BNB Chain ecosystem.",
      date: "January 16, 2023",
    },
    {
      id: 4,
      img: Token1,
      name: "Marca",
      description: "U token enjoys significant Growth in 2022 by Unidef! enjoys significant.",
      date: "January 25, 2023",
    }, {
      id: 5,
      img: Token2,
      name: "New York Times",
      description: "BNB additionally uses a real-time burning mechanism based on gas fees.",
      date: "January 28, 2023",
    },
    {
      id: 6,
      img: Token3,
      name: "New York Times",
      description: "BNB is the cryptocurrency coin that powers the BNB Chain ecosystem.",
      date: "January 02, 2023",
    },
    {
      id: 7,
      img: Token1,
      name: "BBC",
      description: "U token enjoys significant Growth in 2022 by Unidef! enjoys significant.",
      date: "January 04, 2023",
    },
    {
      id: 8,
      img: Token2,
      name: "Coinbase",
      description: "BNB additionally uses a real-time burning mechanism based on gas fees.",
      date: "January 16, 2023",
    },
    {
      id: 9,
      img: Token3,
      name: "Marca",
      description: "BNB is the cryptocurrency coin that powers the BNB Chain ecosystem.",
      date: "January 25, 2023",
    }, {
      id: 10,
      img: Token1,
      name: "New York Times",
      description: "U token enjoys significant Growth in 2022 by Unidef! enjoys significant.",
      date: "January 28, 2023",
    }
  ],
  token_s8_title1: "Your Questions About",
  token_s8_title2: "Unidef (U), ",
  token_s8_title3: "Answered.",
  token_s8_datalist:
    [
      {
        id: 1,
        title: "What is Unidef?",
        description0: "Unidef is a global network of organizations working together to accelerate the world's transition to a decentralized future. ",
        description1: "Guided by this notion, we are committed to supporting decentralized technologies, decentralized assets, decentralized entities, and various initiatives that empower all people, communities, and the planet. Our global network brings together private enterprises, governments, nonprofits, and others who have common values and goals.",
      },
      {
        id: 2,
        title: "What is Decemount?",
        description0: "Unidef Decemount (commonly known as Decemount) is a network of DAOs (Decentralized Autonomous Organizations) working together to empower the next generation of organizations with Unidef - governed initiatives.It is a part of Unidef's interconnected network of organizations that work to accelerate the world's transition to a decentralized future. ",
        description1: "Decemount is designed for individuals and organizations who are building a decentralized future, and it focuses on promoting decentralized technologies, protocols, assets, and entities.",
      },
      {
        id: 3,
        title: "What is Unidef Way?",
        description0: "Unidef Way is a global network of local and global nonprofit fundraising and charity affiliates working together to fight poverty, disease, and inequity around the world. We leverage decentralized echnologies, protocols, assets, and entities to amplify the impact of our efforts and tackle society’s most pressing issues head-on.",
        description1: "It is a part of Unidef's interconnected network of organizations that work to accelerate the world's transition to a decentralized future.",
      },
      {
        id: 4,
        title: "What is Unidef iLabs?",
        description0: "Unidef iLabs is the investment and incubation arm of Unidef, which identifies, supports, and empowers innovative entrepreneurs, startups, enterprises, and communities through investments, incubation, and various initiatives.",
        description1: "The organization focuses on projects that support the transition towards a decentralized future, such as those involving blockchain technology, decentralized finance (DeFi), Web3, Metaverse, and other cutting-edge decentralized solutions.",
      },
      {
        id: 5,
        title: "Does Unidef have its own currency?",
        description: "Yes, Unidef has its own digital currency called Unidef (also known by its ticker symbol, U or $U). U is the digital currency in the Unidef ecosystem for carrying out key functions and supporting the transition to a decentralized future.",
      },
      {
        id: 6,
        title: "Has Unidef (U) been audited by a security company?",
        description0: "Yes, Unidef (Ticker: U) has undergone extensive audits by independent security companies. The coin has been subjected to rigorous testing against potential security threats and weaknesses, and the ",
        btnName: "results",
        link: "https://skynet.certik.com/projects/unidef",
        description001: " are available to the public.",
      },
      {
        id: 7,
        title: "What is Decemount's goal in terms of driving global adoption of Unidef native currency (U)?",
        description: "Decemount's goal is to accelerate the global adoption of Unidef (U) and to explore synergies that link its value to the broader Web3 ecosystem. To achieve this, Decemount is focused on empowering users with tools and resources to understand, use, and interact with U; building strategic partnerships; offering token swap and co-development initiatives; providing support through token holdings and pledges; awarding grants for social good; facilitating collaboration between specialized DAOs; connecting with the community via events and other initiatives; and creating an investing infrastructure for Web3. Together, our efforts will help drive the widespread adoption of Unidef (U) around the world.",
      },
      {
        id: 8,
        title: "Where can I get U?",
        description0: "$U or U is the ticker symbol for Unidef, which serves as the native digital currency of the interconnected network of organizations with the same name - Unidef. You can get U from exchanges or wallets directly. ",
        btnName: "Learn more",
        link: "https://unidef.org/Unidef",
        description1: " Unidef(U) Verified Smart Contract Address(BEP20) is: 0x89dB9B433FeC1307d3dc8908f2813e9f7a1d38F0",
      },
      {
        id: 9,
        title: "Which wallets support U?",
        description0: "It's worth noting that any crypto wallet that supports BEP-20 tokens can support U as well. However, TrustWallet and MetaMask are highly recommended due to their seamless integration with the token and their user-friendly interfaces.",
        description1: "Unidef (U) Verified Smart Contract Address (BEP20) is: 0x89dB9B433FeC1307d3dc8908f2813e9f7a1d38F0",
        description2: "Disclaimer: This information is drawn from other sources on the internet. Unidef does not endorse or recommend any wallets or make any representations with respect to wallets or the purchase or sale of digital assets, more generally. It’s advisable to conduct your own due diligence before relying on any third party or third-party technology, and providers may vary significantly in their compliance, data security, and privacy practices.",
      },
      {
        id: 10,
        title: "Which exchanges support U?",
        description0: "We're all for a decentralized future, which is why we suggest buying U from PancakeSwap.Finance If you prefer centralized options, U is also available on Bitrue, BitForex, DigiFinex, and more.",
        description1: "Disclaimer: This information is drawn from other sources on the internet. Unidef does not endorse or recommend any exchanges or make any representations with respect to exchanges or the purchase or sale of digital assets, more generally. It’s advisable to conduct your own due diligence before relying on any third party or third-party technology, and providers may vary significantly in their compliance, data security, and privacy practices.",
      },
      {
        id: 11,
        title: "When will the U launch on other blockchains?",
        description: "We expect to launch Unidef (U) on additional blockchains next year. The future is multi-chain.",
      },
      {
        id: 12,
        title: "What is the partnership/token swap/product roadmap?",
        description0: "Decemount is looking to develop strategic partnerships with projects that hold the potential for mutual benefit. Decemount plans to swap tokens with established and emerging projects, providing them with support and resources to help them become category winners.",
        description1: "The DAO roadmap is a dynamic document that adapts to the organization's growth and community feedback. This approach allows Decemount to respond efficiently to changes in the Crypto and Web3 industries. Additionally, Unidef has numerous affiliated R&D centers that prepare proposals for voting, shaping the future of Decemount. The organization welcomes all ideas and contributions. Visit the Decemount Forum or Governance Module for more information on involvement.",
      },
      {
        id: 13,
        title: "Looking for more information about Unidef?",
        description0: "Join ",
        btnName: "our community",
        link: "https://t.me/UnidefGlobal",
        description001: " and get the help you need right away! Be sure to follow @UnidefGlobal on Twitter, Telegram, Instagram, and Facebook for the latest updates and news.",
      },
    ],
  uranium_s8_datalist:
    [
      {
        id: 1,
        title: "What is Unidef?",
        description0: "Unidef is a global network of organizations working together to accelerate the world's transition to a decentralized future.",
        description1: " Guided by this notion, we are committed to supporting decentralized technologies, decentralized assets, decentralized entities, and various initiatives that empower all people, communities, and the planet. Our global network brings together private enterprises, governments, nonprofits, and others who have common values and goals.",
        linkopen: "https://twitter.com/UnidefGlobal",
      },
      {
        id: 2,
        title: "What is Decemount?",
        description0: "Unidef Decemount (commonly known as Decemount) is a network of DAOs (Decentralized Autonomous Organizations) working together to empower the next generation of organizations with Unidef-governed initiatives. It is a part of Unidef's interconnected network of organizations that seek to accelerate the world's transition to a decentralized future.",
        description1: "Decemount is designed for individuals and organizations who are building a decentralized future, and it focuses on promoting decentralized technologies, protocols, assets, and entities.",
        linkopen: "https://twitter.com/UnidefGlobal",
      },
      {
        id: 3,
        title: "Who owns and controls the Decemount Treasury?",
        description0: "Decemount Treasury is owned and controlled by its decentralized autonomous organization (DAO). U holders have voting rights within the DAO, allowing them to decide on project proposals, investments, and product development. The DAO treasury is also managed by the U holders.",
        description1: "The treasury is funded through fees paid by Unidef members as well as profits from ventures and investments in other projects and assets. Through sound financial management and strategic partnerships with leading industry players, it is building a robust ecosystem capable of becoming the world's largest network of DAOs.",
      },
      {
        id: 4,
        title: "How does Decemount grow its treasury?",
        description: "Decemount grows its treasury through pledged contributions from Unidef members, specialized autonomous entities, token swaps, venture deals with emerging projects, and yield strategies. As partners succeed and the Web3 industry grows, the success of Decemount partners and treasury growth strategies will increase treasury contributions, allowing more resources to be deployed. This is the flywheel for growth - and Decemount   is at the center of it all",
      },
      {
        id: 5,
        title: "Is Decemount DAO a registered organization?",
        description: "Decemount DAO is a decentralized autonomous organization, not an official legal entity. The DAO operates through decentralized platforms and utilizes smart contracts to manage its operations. Decemount is a collection of token holders who agree to be governed by a set of smart contracts on the blockchain. Therefore, it does not require any official registration.",
      },
      {
        id: 6,
        title: "Does Unidef control Decemount? If not, what role do they play?",
        description0: "Decemount is decentralized and autonomous, meaning they operate independently from Unidef. However, Unidef does provide guidance to the collective, helping to shape its direction through strategic partnerships, funding opportunities, and technical support. The partners in the Unidef ecosystem are also essential for the success of the collective, as their input helps inform decisions about projects and services that will best benefit members.",
        description1: " A decentralized autonomous organization (DAO) is the best way to give every community member a vote on important decisions, whether it's a technical upgrade or a decision to fund a new idea. However, in today's reality, a DAO cannot sign a lease, hire people, make merchandise, or do whatever the community decides to do on its own. Unidef is responsible for the day-to-day administration, bookkeeping, project management, and other tasks that ensure the Decemount DAO community’s ideas have the support they need to become a reality.",
      },
      {
        id: 7,
        title: "How to participate in Decemount?",
        description0: "The DAO operates on the BNB blockchain. Anyone can join the network and become an active participant by purchasing Unidef (Ticker: U), which is the native currency of the Unidef ecosystem When you have U, you are able to use it for voting on proposals related to governance decisions, developing applications, or gaining access to services within the Unidef ecosystem.",
        description1: "Apart from these options, there are other ways you can take part in Decemount: Become part of our community and collaborate with us – this includes becoming a community member, contributor, partner, or token holder",
        description2: "Unidef Coin contract code is: 0x89dB9B433FeC1307d3dc8908f2813e9f7a1d38F0",
      },
      {
        id: 8,
        title: "Does Unidef have its own currency?",
        description: "Yes, Unidef has its own digital currency called Unidef (also known by its ticker symbol, U or $U). U is the digital currency in the Unidef ecosystem for carrying out key functions and supporting the transition to a decentralized future.",
      },
      {
        id: 9,
        title: "Has Unidef (U) been audited by a security company?",
        description0: "Yes, Unidef (Ticker: U) has undergone extensive audits by independent security companies. The coin has been subjected to rigorous testing against potential security threats and weaknesses, and the ",
        btnName: "results",
        link: "https://skynet.certik.com/projects/unidef",
        description001: " are available to the public.",
      },
      {
        id: 10,
        title: "What is Decemount's goal in terms of driving global adoption of Unidef native currency (U)?",
        description: "We at Decemount are dedicated to promoting a decentralized future. As part of this mission, we aim to accelerate the global adoption of Unidef (U) and explore synergies that link its value to the broader Web3 ecosystem. To achieve this, Decemount is focused on empowering users with tools and resources to understand, use, and interact with U; building strategic partnerships; offering token swap and co-development initiatives; providing support through token holdings and pledges; awarding grants for social good; facilitating collaboration between specialized DAOs; connecting with the community via events and other initiatives; and creating an investing infrastructure for Web3. Together, our efforts will help drive the widespread adoption of Unidef (U) around the world.",
      },
      {
        id: 11,
        title: "Where can I get U?",
        description0: "$U or U is the ticker symbol for Unidef, which serves as the native digital currency of the interconnected network of organizations with the same name - Unidef. You can get U from exchanges or wallets directly. ",
        btnName: "Learn more",
        link: "https://unidef.org/Unidef",
        description1: " Unidef (U) Verified Smart Contract Address (BEP20) is: 89dB9B433FeC1307d3dc8908f2813e9f7a1d38F0",
      },
      {
        id: 12,
        title: "Which wallets support U?",
        description0: "It's worth noting that any crypto wallet that supports BEP-20 tokens can support U as well. However, TrustWallet and MetaMask are highly recommended due to their seamless integration with the token and their user- friendly interfaces.",
        description1: "Unidef (U) Verified Smart Contract Address (BEP20) is: 0x89dB9B433FeC1307d3dc8908f2813e9f7a1d38F0",
        // description2: "Unidef Coin contract code is: 0x89dB9B433FeC1307d3dc8908f2813e9f7a1d38F0",
      },
      {
        id: 13,
        title: "Which exchanges support U?",
        description: "We're all for a decentralized future, which is why we suggest buying U from PancakeSwap.Finance If you prefer centralized options, U is also available on Bitrue, BitForex, DigiFinex, and more.",
      },
      {
        id: 14,
        title: "When will the U launch on other blockchains?",
        description0: "Decemount is looking to develop strategic partnerships with projects that hold the potential for mutual benefit. Decemount plans to swap tokens with established and emerging projects, providing them with support and resources to help them become category winners.",
        description1: "The DAO roadmap is a dynamic document that adapts to the organization's growth and community feedback. This approach allows Decemount to respond efficiently to changes in the Crypto and Web3 industries. Additionally, Unidef has multiple affiliated R&D centers that prepare proposals for voting, shaping the future of Decemount. The organization welcomes all ideas and contributions. Visit the Decemount Forum or Governance Module for more information on involvement",
      },
      {
        id: 15,
        title: "Looking for more information about Unidef?",
        description0: "Join ",
        btnName: "our community",
        link: "https://t.me/UnidefGlobal",
        description001: " and get the help you need right away! Be sure to follow @UnidefGlobal on Twitter, Telegram, Instagram, and Facebook for the latest updates and news.",
      },
    ],
  footer_s5_sub2: "This information is drawn from other sources on the internet. unidef.org does not endorse or recommend any exchanges or make any representations with respect to exchanges or the purchase or sale of digital assets, more generally. It's advisable to conduct your own due diligence before relying on any third party or third-party technology, and providers may vary significantly in their compliance, data security, and privacy practices.",
  footer_s5_sub3: "Unidef.org does not constitute an offer to purchase or solicitation to sell, nor is it a recommendation to buy or sell any token, coin, crypto, or other product.Purchasing and selling tokens is inherently risky, and holders must bear the risk of loss.Neither the owner(s) and/ or operator(s) of this website nor any participants in the Unidef ecosystem accept any liability for losses or taxes that holders, purchasers, or sellers of Unidef(Ticker: U) may incur.The value of U may decrease and may be highly volatile.",
  footer_s5_sub7: "This website is not directed toward any person located in a jurisdiction where purchasing, selling, or holding U is prohibited or regulated.Consult your local laws before transacting in any cryptocurrency.",
  new_token_breakBox_data: [
    {
      id: 1,
      title1: "Access",
      desc: "As a U holder, you have access to the entire Unidef interconnected global networks, including Decemount,    Unidef Way, and Unidef iLabs, as well as any projects invested under the Unidef umbrella. This is made possible through 'The Power of One,' fueled by Unidef (U).",
      flagImage: newTokenCard3,
    },
    {
      id: 2,
      title1: "Governance ",
      desc: "As a key player in Decemount's ambitious mission of building the largest network of DAOs (Decentralized    Autonomous Organizations), U shapes the governance process and empowers community-led initiatives",
      flagImage: newTokenCard1,
    },
    {
      id: 3,
      title1: "The Unification of Spend ",
      desc: "U is the ecosystem’s utility token, providing all users with a shared and open currency that can be used for all payments, transactions, and interactions without centralized intermediaries. This streamlines cross-network activity, reduces friction, and enables seamless transactions.",
      flagImage: newTokenCard2,
    },
    {
      id: 4,
      title1: "Incentivization ",
      desc: "U acts as a tool to coordinate activities across all networks and is awarded to community members for their participation in initiatives. This incentive system promotes a thriving and engaged community that is committed to building a decentralized future.",
      flagImage: newTokenCard4,
    },
  ],
  token_new_s5_wallets_list: [
    {
      id: 1,
      title: "Get $U",
      customTile:
        [
          {
            id: 1,
            url: Mexc,
            title: 'Mexc',
            socialIcon: Twitter,
            twitterLink: 'https://twitter.com/MEXC_Global',
            link: "https://www.mexc.com/tr-TR/exchange/U_USDT?_from=search_spot_trade",
          },
          {
            id: 2,
            url: oneinch,
            title: '1inch',
            socialIcon: Twitter,
            twitterLink: 'https://twitter.com/1inch?s=11&t=GM3UOEaUwsDphTZtqr35aw',
            link: "https://app.1inch.io/#/56/simple/swap/BNB/0x89db9b433fec1307d3dc8908f2813e9f7a1d38f0/import-token",
          },
          {
            id: 3,
            url: Matcha,
            title: 'Matcha',
            socialIcon: Twitter,
            twitterLink: 'https://twitter.com/matchaxyz',
            link: "https://www.matcha.xyz/markets/56/0x89db9b433fec1307d3dc8908f2813e9f7a1d38f0/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
          },
          {
            id: 4,
            url: Bitrue,
            title: 'Bitrue',
            socialIcon: Twitter,
            twitterLink: 'https://twitter.com/BitrueOfficial',
            link: "https://www.bitrue.com/trade/u_usdt",
          },
          {
            id: 5,
            url: Bitforex,
            title: 'Bitforex',
            socialIcon: Twitter,
            twitterLink: 'https://twitter.com/bitforexcom?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
            link: "https://www.bitforex.com/en/spot/u_usdt",
          },
          {
            id: 6,
            url: Digifinex,
            title: 'Digifinex',
            socialIcon: Twitter,
            twitterLink: 'https://twitter.com/digifinex',
            link: "https://www.digifinex.com/en-ww/trade/USDT/U",
          },
          {
            id: 7,
            url: PancakeSwap,
            title: 'PancakeSwap',
            socialIcon: Twitter,
            twitterLink: 'https://twitter.com/pancakeswap',
            link: "https://pancakeswap.finance/swap?outputCurrency=0x89dB9B433FeC1307d3dc8908f2813e9f7a1d38F0&chainId=56&inputCurrency=BNB"
          },
        ]
    },
    {
      id: 2,
      title: "Hold $U",
      customTile:
        [
          {
            id: 1,
            url: MetaMask,
            title: 'MetaMask',
            socialIcon: Twitter,
            twitterLink: 'https://twitter.com/metamask?s=11&t=GM3UOEaUwsDphTZtqr35aw',
            link: "https://metamask.io/",
          },
          {
            id: 2,
            url: TrustWallet1,
            title: 'Trust Wallet',
            socialIcon: Twitter,
            twitterLink: 'https://twitter.com/trustwallet',
            link: "https://trustwallet.com/",
          },
          {
            id: 3,
            url: MathWallet,
            title: 'MathWallet',
            socialIcon: Twitter,
            twitterLink: 'https://twitter.com/Mathwallet',
            link: "https://mathwallet.org/en-us/",
          },
          {
            id: 4,
            url: Coinnine,
            title: 'Coin98',
            socialIcon: Twitter,
            twitterLink: 'https://twitter.com/coin98_wallet',
            link: "https://coin98.com/",
          },
          {
            id: 5,
            url: SafePal,
            title: 'SafePal',
            socialIcon: Twitter,
            twitterLink: 'https://twitter.com/iSafePal',
            link: "https://www.safepal.com/",
          },
          {
            id: 6,
            url: TokenPocket,
            title: 'TokenPocket',
            socialIcon: Twitter,
            twitterLink: 'https://twitter.com/TokenPocket_TP?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
            link: "https://www.tokenpocket.pro/",
          },
        ]
    },
    {
      id: 3,
      title: "Watch $U",
      customTile:
        [
          {
            id: 1,
            url: CoinMarketCap,
            title: 'CoinMarketCap',
            socialIcon: Twitter,
            twitterLink: 'https://twitter.com/CoinMarketCap',
            link: "https://coinmarketcap.com/currencies/unidef/",
          },
          {
            id: 2,
            url: CoinGecko,
            title: 'CoinGecko',
            socialIcon: Twitter,
            twitterLink: 'https://twitter.com/coingecko',
            link: "https://www.coingecko.com/en/coins/unidef",
          },
          {
            id: 3,
            url: BSC,
            title: 'BSC Scan',
            socialIcon: Twitter,
            twitterLink: 'https://twitter.com/BscScan',
            link: "https://bscscan.com/token/0x89dB9B433FeC1307d3dc8908f2813e9f7a1d38F0",
          },
          {
            id: 4,
            url: Binance,
            title: 'Binance',
            socialIcon: Twitter,
            twitterLink: 'https://twitter.com/binance',
            link: "https://www.binance.com/en/price/unidef",
          },
          {
            id: 5,
            url: LiveConWatch,
            title: 'LiveConWatch',
            socialIcon: Twitter,
            twitterLink: 'https://twitter.com/livecoinwatch',
            link: "https://www.livecoinwatch.com/price/Unidef-U",
          },
        ]
    },
    {
      id: 4,
      title: "Use $U",
      customTile:
        [
          {
            id: 1,
            url: MetaMask,
            title: 'MetaMask',
            socialIcon: Twitter,
            twitterLink: 'https://twitter.com/metamask?s=11&t=GM3UOEaUwsDphTZtqr35aw',
            link: "https://metamask.io/",
          },
          {
            id: 2,
            url: TrustWallet1,
            title: 'Trust Wallet',
            socialIcon: Twitter,
            twitterLink: 'https://twitter.com/trustwallet',
            link: "https://trustwallet.com/",
          },
        ]
    },
  ],
  token_new_s5_wallets_list_mobile: [
    {
      id: 1,
      title: "Get $U",
      customTile:
        [
          {
            data1: [
              {
                id: 1,
                url: Mexc,
                title: 'Mexc',
                socialIcon: Twitter,
                twitterLink: 'https://twitter.com/MEXC_Global',
                link: "https://www.mexc.com/tr-TR/exchange/U_USDT?_from=search_spot_trade",
              },
              {
                id: 2,
                url: oneinch,
                title: '1inch',
                socialIcon: Twitter,
                twitterLink: 'https://twitter.com/1inch?s=11&t=GM3UOEaUwsDphTZtqr35aw',
                link: "https://app.1inch.io/#/56/simple/swap/BNB/0x89db9b433fec1307d3dc8908f2813e9f7a1d38f0/import-token",
              },
            ]
          },
          {
            data2: [
              {
                id: 3,
                url: Matcha,
                title: 'Matcha',
                socialIcon: Twitter,
                twitterLink: 'https://twitter.com/matchaxyz',
                link: "https://www.matcha.xyz/markets/56/0x89db9b433fec1307d3dc8908f2813e9f7a1d38f0/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
              },
              {
                id: 4,
                url: Bitrue,
                title: 'Bitrue',
                socialIcon: Twitter,
                twitterLink: 'https://twitter.com/BitrueOfficial',
                link: "https://www.bitrue.com/trade/u_usdt",
              },
            ]
          },
          {
            data3: [
              {
                id: 5,
                url: Bitforex,
                title: 'Bitforex',
                socialIcon: Twitter,
                twitterLink: 'https://twitter.com/bitforexcom?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
                link: "https://www.bitforex.com/en/spot/u_usdt",
              },
              {
                id: 6,
                url: Digifinex,
                title: 'Digifinex',
                socialIcon: Twitter,
                twitterLink: 'https://twitter.com/digifinex',
                link: "https://www.digifinex.com/en-ww/trade/USDT/U",
              },
            ]
          },
          {
            data4: [
              {
                id: 7,
                url: PancakeSwap,
                title: 'PancakeSwap',
                socialIcon: Twitter,
                twitterLink: 'https://twitter.com/pancakeswap',
                link: "https://pancakeswap.finance/swap?outputCurrency=0x89dB9B433FeC1307d3dc8908f2813e9f7a1d38F0&chainId=56&inputCurrency=BNB"
              },
            ]
          },
        ]
    },
    {
      id: 2,
      title: "Hold $U",
      customTile:
        [
          {
            data1: [
              {
                id: 1,
                url: MetaMask,
                title: 'MetaMask',
                socialIcon: Twitter,
                twitterLink: 'https://twitter.com/metamask?s=11&t=GM3UOEaUwsDphTZtqr35aw',
                link: "https://metamask.io/",
              },
              {
                id: 2,
                url: TrustWallet1,
                title: 'Trust Wallet',
                socialIcon: Twitter,
                twitterLink: 'https://twitter.com/trustwallet',
                link: "https://trustwallet.com/",
              },
            ]
          },
          {
            data2: [
              {
                id: 3,
                url: MathWallet,
                title: 'MathWallet',
                socialIcon: Twitter,
                twitterLink: 'https://twitter.com/Mathwallet',
                link: "https://mathwallet.org/en-us/",
              },
              {
                id: 4,
                url: Coinnine,
                title: 'Coin98',
                socialIcon: Twitter,
                twitterLink: 'https://twitter.com/coin98_wallet',
                link: "https://coin98.com/",
              },]
          },
          {
            data3: [
              {
                id: 5,
                url: SafePal,
                title: 'SafePal',
                socialIcon: Twitter,
                twitterLink: 'https://twitter.com/iSafePal',
                link: "https://www.safepal.com/",
              },
              {
                id: 6,
                url: TokenPocket,
                title: 'TokenPocket',
                socialIcon: Twitter,
                twitterLink: 'https://twitter.com/TokenPocket_TP?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
                link: "https://www.tokenpocket.pro/",
              },
            ]
          }
        ]
    },
    {
      id: 3,
      title: "Watch $U",
      customTile:
        [
          {
            data1: [
              {
                id: 1,
                url: CoinMarketCap,
                title: 'CoinMarketCap',
                socialIcon: Twitter,
                twitterLink: 'https://twitter.com/CoinMarketCap',
                link: "https://coinmarketcap.com/currencies/unidef/",
              },
              {
                id: 2,
                url: CoinGecko,
                title: 'CoinGecko',
                socialIcon: Twitter,
                twitterLink: 'https://twitter.com/coingecko',
                link: "https://www.coingecko.com/en/coins/unidef",
              },
            ]
          },
          {
            data2: [
              {
                id: 3,
                url: BSC,
                title: 'BSC Scan',
                socialIcon: Twitter,
                twitterLink: 'https://twitter.com/BscScan',
                link: "https://bscscan.com/token/0x89dB9B433FeC1307d3dc8908f2813e9f7a1d38F0",
              },
              {
                id: 4,
                url: Binance,
                title: 'Binance',
                socialIcon: Twitter,
                twitterLink: 'https://twitter.com/binance',
                link: "https://www.binance.com/en/price/unidef",
              },
            ]
          },
          {
            data3: [
              {
                id: 5,
                url: LiveConWatch,
                title: 'LiveConWatch',
                socialIcon: Twitter,
                twitterLink: 'https://twitter.com/livecoinwatch',
                link: "https://www.livecoinwatch.com/price/Unidef-U",
              },
            ]
          }
        ]
    },
    {
      id: 4,
      title: "Use $U",
      customTile:
        [
          {
            data1: [
              {
                id: 1,
                url: MetaMask,
                title: 'MetaMask',
                socialIcon: Twitter,
                twitterLink: 'https://twitter.com/metamask?s=11&t=GM3UOEaUwsDphTZtqr35aw',
                link: "https://metamask.io/",
              },
              {
                id: 2,
                url: TrustWallet,
                title: 'Trust Wallet',
                socialIcon: Twitter,
                twitterLink: 'https://twitter.com/trustwallet',
                link: "https://trustwallet.com/",
              },
            ]
          },
        ]
    },
  ],
  sticky_customTile:
    [
      {
        id: 1,
        url: Mexc,
        title: 'Mexc',
        link: "https://www.mexc.com/tr-TR/exchange/U_USDT?_from=search_spot_trade",
      },
      {
        id: 2,
        url: oneinch,
        title: '1inch',
        link: "https://app.1inch.io/#/56/simple/swap/BNB/0x89db9b433fec1307d3dc8908f2813e9f7a1d38f0/import-token",
      },
      {
        id: 3,
        url: Matcha,
        title: 'Matcha',
        link: "https://www.matcha.xyz/markets/56/0x89db9b433fec1307d3dc8908f2813e9f7a1d38f0/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      },
      {
        id: 4,
        url: Bitrue,
        title: 'Bitrue',
        link: "https://www.bitrue.com/trade/u_usdt",
      },
      {
        id: 5,
        url: Bitforex,
        title: 'Bitforex',
        link: "https://www.bitforex.com/en/spot/u_usdt",
      },
      {
        id: 6,
        url: Digifinex,
        title: 'Digifinex',
        link: "https://www.digifinex.com/en-ww/trade/USDT/U",
      },
      {
        id: 7,
        url: PancakeSwap,
        title: 'PancakeSwap',
        link: "https://pancakeswap.finance/swap?outputCurrency=0x89dB9B433FeC1307d3dc8908f2813e9f7a1d38F0&chainId=56&inputCurrency=BNB"
      },
    ],
  token_s5_sub2: "This information is provided solely for informational purposes and does not constitute an offer to buy or sell any digital assets, including but not limited to tokens, coins, and cryptocurrencies. Unidef.org does not endorse, recommend, or make any representations regarding any exchanges or the purchase or sale of digital assets. It is highly recommended that you conduct your own due diligence before relying on any third-party technology or provider, as compliance, data security, and privacy practices may vary significantly",
  token_s5_sub3: "Purchasing and selling digital assets, including Unidef (Ticker: U), is inherently risky, and holders must bear the risk of loss. Neither the owner(s) nor operator(s) of this website nor any participants in the Unidef ecosystem accept any liability for losses or taxes incurred by holders, purchasers, or sellers of U. The value of U may decrease and may be highly volatile.",
  token_s5_sub7: "This website is not intended for any person located in a jurisdiction where purchasing, selling, or holding U is prohibited or regulated. It is your responsibility to comply with all applicable laws and regulations in your jurisdiction before transacting any digital assets.",
  token_new_s10_sub_title1: [{
    id: 1,
    title: "Community",
  }],
  unidef_btn_data_uranium: [
    {
      id: 1,
      title: "Unidef",
      token_card_data: [
        {
          id: 1,
          flagImage: twitterTokenCard,
          title1: "Unidef Twitter",
          desc: "Stay in the loop with our ecosystem! Get updates now.",
          linkopen: "https://twitter.com/UnidefGlobal",
        },
        {
          id: 2,
          flagImage: instagramTokenCard,
          title1: "Unidef Instagram",
          desc: "Latest from Unidef's ecosystem! Stay in-the-know.",
          linkopen: "https://www.instagram.com/unidefglobal/",
        },
        {
          id: 3,
          flagImage: teligramTokenCard,
          title1: "Unidef Telegram",
          desc: "First to know about Unidef's ecosystem! Join us.",
          linkopen: "https://t.me/UnidefGlobal",
        },
        {
          id: 4,
          title1: "Unidef Facebook",
          flagImage: faceBookTokenCard,
          desc: "Connect and stay updated with Unidef's ecosystem!",
          linkopen: "https://www.facebook.com/UnidefGlobal/",
        },
        {
          id: 5,
          title1: "Unidef LinkedIn",
          flagImage: linkdinTokenCard,
          desc: "Connect with Unidef and be part of our network. Join us today!",
          linkopen: "https://www.linkedin.com/company/unidef",
        },
        {
          id: 6,
          title1: "Unidef CoinMarketCap",
          flagImage: coinMarkrtcap,
          desc: "Get the latest from the world of Unidef (U). Check us out!",
          linkopen: "https://coinmarketcap.com/currencies/unidef/",
        },
      ]
    },
    {
      id: 2,
      title: "Decemount",
      token_card_data: [
        {
          id: 1,
          flagImage: twitterTokenCard,
          title1: "Decemount Twitter",
          desc: "Get in the loop! Latest updates from Decemount's ecosystem",
          linkopen: "https://twitter.com/intent/follow?screen_name=unidefglobal",
        },
        {
          id: 2,
          flagImage: teligramTokenCard,
          title1: "Decemount Telegram",
          desc: "Join now! First to know about Decemount's ecosystem updates.",
          linkopen: "https://t.me/UnidefGlobal",
        },
        {
          id: 3,
          flagImage: ForumTokenCard,
          title1: "Decemount Forum",
          desc: "Shape Decemount's future! Join the discussion.",
          linkopen: "https://forum.decemount.unidef.org",
        },
        {
          id: 4,
          flagImage: instagramTokenCard,
          title1: "Decemount Instagram",
          desc: "Keep yourself updated with the latest scoop on Decemount.",
          linkopen: "https://www.instagram.com/unidefglobal/",
        },
        {
          id: 5,
          title1: "Decemount Facebook",
          flagImage: faceBookTokenCard,
          desc: "Connect now! Stay updated on Decemount's ecosystem.",
          linkopen: "https://www.facebook.com/UnidefGlobal/",
        },
        {
          id: 5,
          title1: "Unidef CoinMarketCap",
          flagImage: coinMarkrtcap,
          desc: "Get the latest from the world of Unidef (U). Check us out!",
          linkopen: "https://coinmarketcap.com/currencies/unidef/",
        },
      ]
    },
    {
      id: 3,
      title: "Unidef Way",
      token_card_data: [
        {
          id: 1,
          flagImage: twitterTokenCard,
          title1: "Unidef Way Twitter",
          desc: "Stay in the loop with Unidef Way's network! Get updates here.",
          linkopen: "https://twitter.com/intent/follow?screen_name=unidefglobal",
        },
        {
          id: 2,
          flagImage: teligramTokenCard,
          title1: "Unidef Way Telegram",
          desc: "Join us to stay ahead in Unidef Way's ecosystem!",
          linkopen: "https://t.me/UnidefGlobal",
        },
        {
          id: 3,
          flagImage: instagramTokenCard,
          title1: "Unidef Way Instagram",
          desc: "Be in-the-know with Unidef Way's network! Get the latest updates",
          linkopen: "https://www.instagram.com/unidefglobal/",
        },
        {
          id: 4,
          title1: "Unidef Way Facebook",
          flagImage: faceBookTokenCard,
          desc: "Connect to Unidef Way's ecosystem & stay updated! Don't miss a beat.",
          linkopen: "https://www.facebook.com/UnidefGlobal/",
        },
        {
          id: 5,
          title1: "Unidef Way LinkedIn",
          flagImage: linkdinTokenCard,
          desc: "Connect with Unidef Way and be part of our network. Join us today!",
          linkopen: "https://www.linkedin.com/company/unidef",
        },
        {
          id: 6,
          flagImage: coinMarkrtcap,
          title1: "Unidef CoinMarketCap",
          desc: "Get the latest from the world of Unidef (U). Check us out!",
          linkopen: "https://coinmarketcap.com/currencies/unidef/",
        },
      ]
    },
    {
      id: 4,
      title: "Unidef iLabs",
      token_card_data: [
        {
          id: 1,
          flagImage: twitterTokenCard,
          title1: "Unidef iLabs Twitter",
          desc: "Stay updated on Unidef iLabs's ecosystem. Get the news here!",
          linkopen: "https://twitter.com/intent/follow?screen_name=unidefglobal",
        },
        {
          id: 2,
          flagImage: teligramTokenCard,
          title1: "Unidef iLabs Telegram",
          desc: "First-hand info on Unidef iLabs's ecosystem. Join us today!",
          linkopen: "https://t.me/UnidefGlobal",
        },
        {
          id: 3,
          flagImage: instagramTokenCard,
          title1: "Unidef iLabs Instagram",
          desc: "Keep updated with Unidef iLabs's ecosystem. Be in-the-know!",
          linkopen: "https://www.instagram.com/unidefglobal/",
        },
        {
          id: 4,
          title1: "Unidef iLabs Facebook",
          flagImage: faceBookTokenCard,
          desc: "Never miss a beat with Unidef iLabs's ecosystem. Stay updated!",
          linkopen: "https://www.facebook.com/UnidefGlobal/",
        },
        {
          id: 5,
          flagImage: linkdinTokenCard,
          title1: "Unidef iLabs LinkedIn",
          desc: "Connect with Unidef Way and be part of our network. Join us today!",
          linkopen: "https://www.linkedin.com/company/unidef",
        },
        {
          id: 6,
          title1: "Unidef CoinMarketCap",
          flagImage: coinMarkrtcap,
          desc: "Get the latest from the world of Unidef (U). Check us out!",
          linkopen: "https://coinmarketcap.com/currencies/unidef/",
        },
      ]
    },
  ],
  unidef_btn_data_mobile: [
    {
      id: 1,
      title: "Unidef",
      token_card_data: [
        {
          part1: [
            {
              id: 1,
              flagImage: twitterIconMobile,
              title1: "Twitter",
              linkopen: "https://twitter.com/UnidefGlobal",
            },
            {
              id: 2,
              flagImage: instagramIconMobile,
              title1: "Instagram",
              linkopen: "https://www.instagram.com/unidefglobal/",
            },
            {
              id: 3,
              flagImage: telegramIconMobile,
              linkopen: "https://t.me/UnidefGlobal",
              title1: "Telegram",
            },
            {
              id: 4,
              title1: "Facebook",
              flagImage: facebookIconMobile,
              linkopen: "https://www.facebook.com/UnidefGlobal/",
            },
          ],
        },
        {
          part2: [
            {
              id: 5,
              title1: "LinkedIn",
              flagImage: linkedinIconMobile,
              linkopen: "https://www.linkedin.com/company/unidef",
            },
            {
              id: 6,
              flagImage: coinMarkrtcapMobile,
              title1: "CoinMarketCap",
              linkopen: "https://coinmarketcap.com/currencies/unidef/",
            },
          ]
        }
      ]
    },
    {
      id: 2,
      title: "Decemount",
      token_card_data: [
        {
          part1: [
            {
              id: 1,
              flagImage: twitterIconMobile,
              title1: "Twitter",
              linkopen: "https://twitter.com/Decemount",
            },
            {
              id: 4,
              title1: "Telegram",
              flagImage: telegramIconMobile,
              linkopen: "https://t.me/decemount",
            },
            {
              id: 2,
              flagImage: unidefFouramMobile,
              title1: "Forum",
              linkopen: "https://forum.decemount.unidef.org",
            },
            {
              id: 3,
              flagImage: instagramIconMobile,
              title1: "Instagram",
              linkopen: "https://www.instagram.com/decemount/?igshid=YmMyMTA2M2Y%3D",
            },
          ],
        },
        {
          part2: [
            {
              id: 6,
              flagImage: facebookIconMobile,
              title1: "Facebook",
              linkopen: "https://www.facebook.com/Decemount",
            },
            {
              id: 7,
              flagImage: coinMarkrtcapMobile,
              title1: "CoinMarketCap",
              linkopen: "https://coinmarketcap.com/currencies/unidef/",
            },
          ]
        }
      ]
    },
    {
      id: 3,
      title: "Unidef Way",
      token_card_data: [
        {
          part1: [
            {
              id: 3,
              flagImage: twitterIconMobile,
              title1: "Twitter",
              linkopen: "https://twitter.com/UnidefWay",
            },
            {
              id: 4,
              title1: "Telegram",
              flagImage: telegramIconMobile,
              linkopen: "https://t.me/unidefway",
            },
            {
              id: 1,
              flagImage: instagramIconMobile,
              title1: "Instagram",
              linkopen: "https://www.instagram.com/unidefway/",
            },
            {
              id: 2,
              flagImage: facebookIconMobile,
              title1: "Facebook",
              linkopen: "https://www.facebook.com/UnidefWay/",
            },
          ],
        },
        {
          part2: [
            {
              id: 5,
              title1: "LinkedIn",
              flagImage: linkedinIconMobile,
              linkopen: "https://www.linkedin.com/company/unidef",
            },
            {
              id: 7,
              flagImage: coinMarkrtcapMobile,
              title1: "CoinMarketCap",
              linkopen: "https://coinmarketcap.com/currencies/unidef/",
            },
          ]
        }
      ]
    },
    {
      id: 4,
      title: "Unidef iLabs",
      token_card_data: [
        {
          part1: [
            {
              id: 1,
              flagImage: twitterIconMobile,
              title1: "Twitter",
              linkopen: "https://twitter.com/UnidefiLabs",
            },
            {
              id: 2,
              flagImage: telegramIconMobile,
              title1: "Telegram",
              linkopen: "https://t.me/UnidefiLabs",
            },
            {
              id: 3,
              flagImage: instagramIconMobile,
              title1: "Instagram",
              linkopen: "https://www.instagram.com/unidefilabs/",
            },
            {
              id: 4,
              title1: "Facebook",
              flagImage: facebookIconMobile,
              linkopen: "https://www.facebook.com/UnidefiLabs",
            },
          ],
        },
        {
          part2: [
            {
              id: 5,
              flagImage: linkedinIconMobile,
              title1: "LinkedIn",
              linkopen: "https://www.linkedin.com/company/unidef",
            },
            {
              id: 6,
              title1: "CoinMarketCap",
              flagImage: coinMarkrtcapMobile,
              linkopen: "https://coinmarketcap.com/currencies/unidef/",
            },
          ]
        }
      ]
    },
  ],
  token_s3_single_btn_accordion_data: [
    {
      id: 1,
      img: PlusIcon1,
      // title:"read",
      description: [
        {
          id: 1,
          title: "Learn more about where we can hire",
          description: "Every U Counts: Uranium is governed by U holders via community votes, and U supports Uranium's extensive ecosystem as the underlying governance token."
        }
      ]
    },
    {
      id: 2,
      img: Info,
      description: [
        {
          id: 1,
          title: "Learn more about where we can hire11111",
          description: "11111Every U Counts: Uranium is governed by U holders via community votes, and U supports Uranium's extensive ecosystem as the underlying governance token."
        }
      ]
    },
  ],
  ///uranium-data aaaa
  building_the: "Building the",
  worlds: "World’s ",
  largest: "Largest",
  dao: "DAO",
  buy_unidef_coin: "Get U",
  buy_bnb: "Buy BNB",
  s5_bottom_des_small1: "Total dollar",
  uranium_s1_title_mobile: "Building the world’s Largest Decentralized Autonomous Organization",
  s5_bottom_des_small2: "amount of tokens held in uranium treasury",
  uranium_s3_subtitle: "The purpose of Decemount is to seed and grow an ecosystem of projects, infrastructure, and organizations that will evolve into the largest network of decentralized entities and accelerate universal adoption of the Unidef.",
  uranium_box_data1: [
    {
      id: 1,
      image: oneImage,
      title: "Pledged contributions from unidef members",
      tooltipText: "At Decemount, we're proud to be part of the Unidef ecosystem. Through partnerships with other like- minded organizations, we're able to access valuable resources and contribute to the growth of the industry as a whole.Unidef members have pledged contributions to our treasury, which we're using to fuel our expansion plans.",
    },
    {
      id: 2,
      image: twoImage,
      title: "Specialized Autonomous Entities ",
      tooltipText: "Decemount also collaborates with specialized autonomous entities to expand its capabilities and increase its treasury",
    },
  ],
  uranium_box_data2: [
    {
      id: 1,
      image: threeImage,
      title: "Token swaps and venture deals",
      tooltipText: "Decemount engages in token swaps and venture deals with emerging projects that align with its mission.",
    },
    {
      id: 2,
      image: fourImage,
      title: "Yield strategies",
      tooltipText: "Decemount employs yield strategies to ensure that its investments generate stable returns and its treasury grows over time.",
    },
  ],
  collectively: "Collectively",
  for_the_decentralized: "for the Decentralized",
  community_s10: "Community",
  //New Home Page Data
  cookies_title: "By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.",
  cookies_settings: "Cookies Settings",
  accept_all_cookies: "Accept All Cookies",
  about_as: "About us",
  s2_left_title1: "About Unidef",
  s2_left_subtitle: "Unidef is a global network of organizations, working together to accelerate the world's transition to a decentralized future. ",
  unidef: "Unidef",
  s3_main_title: "A Note On Names:",
  s3_sub_title1: "​Looking for a quick explanation of the names in the Unidef landscape? Here's what you need to know.",
  //newHome-a
  welcome_to_unidef: "Welcome to Unidef",
  h_s1_title1: "The Investor & Builder Of ",
  h_s1_title2: "A Decentralized Future.",
  for_a_decentralized_future: "For a Decentralized Future",
  follow_us_on: "Follow us on",
  scroll_down: "SCROLL DOWN TO SEE MORE",
  home_s3_datalist: [
    {
      id: 1,
      title: "Unidef",
      description: "Unidef is a global network of organizations working together to accelerate the world's transition to a decentralized future.",
    },
    {
      id: 2,
      title: "Agunium",
      description: "Agunium invests in decentralized assets and cryptos only.",
    },
    {
      id: 3,
      title: "Unidef iLabs ",
      description: "Unidef iLabs invests capital and expertise in exceptional startups and entrepreneurs.",
    },
    {
      id: 4,
      title: "Nexensu",
      description: "Nexensu helps brands predict and plan for a decentralized future.",
    },
    {
      id: 5,
      title: "Agilance",
      description: "Agilance offers Integrated legal solutions, for a decentralized future.",
    },
  ],
  our_network: "OUR NETWORKS",
  s4_sub_title1: "We team together to build a trusted global network of organizations for a decentralized future.",
  s4_sub_title2: "Together, we can do more than any one of us alone.",
  our_action: "OUR ACTIONS",
  s5_home_sub_title: "A proactive approach to address the evolving needs of our community.",
  home_new_s6_title: "What’s in the news?",
  press_hilights: "Press Highlights",
  home_coin_slider_data: [
    {
      id: 1,
      img: homeSlider1,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinName: "Coinbase",
      coinDate: "Wed . Mar 29, 2023",
    },
    {
      id: 2,
      img: homeSlider2,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinName: "New York Times",
      coinDate: "Mon . Mar 27, 2023",
    },
    {
      id: 3,
      img: homeSlider3,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinName: "Coinbase",
      coinDate: "Wed . Mar 29, 2023",
    },
    {
      id: 4,
      img: homeSlider1,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinName: "Coinbase",
      coinDate: "Mon . Mar 27, 2023",
    }, {
      id: 5,
      img: homeSlider2,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinName: "Coinbase",
      coinDate: "Wed . Mar 29, 2023",
    },
    {
      id: 6,
      img: homeSlider3,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinName: "Coinbase",
      coinDate: "Mon . Mar 27, 2023",
    },
    {
      id: 7,
      img: homeSlider1,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinName: "Coinbase",
      coinDate: "Wed . Mar 29, 2023",
    },
    {
      id: 8,
      img: homeSlider2,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinName: "New York Times",
      coinDate: "Mon . Mar 27, 2023",
    },
    {
      id: 9,
      img: homeSlider3,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinName: "Coinbase",
      coinDate: "Wed . Mar 29, 2023",
    }, {
      id: 10,
      img: homeSlider1,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinName: "New York Times",
      coinDate: "Mon . Mar 27, 2023",
    }
  ],
  faq: "FAQ",
  home_new_s7_title1: "Questions?",
  home_new_s7_title2: "Answers",
  home_new_s7_datalist: [
    {
      id: 1,
      title: "What is Unidef?",
      description0: "Unidef is a global network of organizations, investing and building together to accelerate the world's transition to a decentralized future.",
      description1: "Guided by this notion, we are committed to supporting decentralized technologies, protocols, assets, entities, and various initiatives that empower all people, communities, and the planet. Our global network brings together private enterprises, governments, nonprofits, and others who have common values and goals.",
    },
    {
      id: 2,
      title: "What is the typical investment amount by Unidef?",
      description: "Our investment sizes usually rely on the policies of Unidef members, but we maintain a significant level of flexibility. For instance, Agunium's typical investment size reaches up to $1,000,000, whereas Unidef iLabs may invest large amounts, depending on the particular situation.",
    },
    {
      id: 3,
      title: "Does Unidef provide investment for pre-seed or seed startups?",
      description: "Yes! At Unidef, our mission is to be the earliest supporters and advocates for the visionary founders who are shaping the decentralized future. We are committed to collaborating with these entrepreneurs from the very beginning and having faith in their ideas even when others may not yet grasp their potential. Our partnership always begins at the initial stages of a startup's journey.",
    },
    {
      id: 4,
      title: "Does Unidef back tokens and coins?",
      description: "We believe that decentralized assets and cryptos will fundamentally redefine the Internet, financial system, and money in a way that empowers all to take full control of their finances, data, and identity. As a result, we provide support and assistance in developing use cases and promoting widespread adoption of native currencies within Unidef and its partners, fostering global adoption and influence.",
    },
    {
      id: 5,
      title: "Looking for more information about Unidef? ",
      description0: "Join our community and get the help you need right away! Make sure to follow us @UnidefGlobal on  ",
      description001: " for the latest updates and news.",
      social_data: [
        {
          id: 1,
          btnName1: "Twitter, ",
          link1: "https://twitter.com/intent/follow?screen_name=unidefglobal",
        },
        {
          id: 2,
          btnName1: "Telegram, ",
          link1: "https://t.me/UnidefGlobal",
        },
        {
          id: 3,
          btnName1: "Instagram, ",
          link1: "https://www.instagram.com/unidefglobal/",
        },
        {
          id: 4,
          btnName1: "Facebook, ",
          link1: "https://www.facebook.com/UnidefGlobal/",
        },
        {
          id: 5,
          btnName1: "Youtube",
          link1: "https://www.youtube.com/@UnidefGlobal",
          event: " and "
        },
        {
          id: 6,
          btnName1: "Linkedin",
          link1: "https://www.linkedin.com/company/unidef",
        },
      ]
    },
  ],
  home_new_s5_datalist: [
    {
      id: 1,
      title: "Strategic Investments",
      description: "We invest capital and expertise in organizations and entrepreneurs to create incentives that will enable private enterprises to drive progress in alignment with our mission for a decentralized future.",
    },
    {
      id: 2,
      title: "Incubating New Ideas",
      description: "We incubate and drive forward the fresh ideas, insights, and research that can take us one step closer to a decentralized future.",
    },
    {
      id: 3,
      title: "Award Grants",
      description: "We provide grants to those who are making waves in the AI, Web3, Crypto, Metaverse, and blockchain space.",
    },
    {
      id: 4,
      title: "Building Networks for a Decentralized Future",
      description: "We build trusted networks that have the capability to influence millions of people. Our ambition is to shape brands that are dedicated to bringing about a decentralized future and beyond.",
    },
    {
      id: 5,
      title: "Post-Investment Support",
      description: "We direct our support for maximum impact. Immediately following each new investment,We provide Post-Investment Support to organizations and projects to address their administrative, regulatory, and marketing challenges.",
    },
    {
      id: 6,
      title: "Driving Positive Impact",
      description: "We use the positive power of decentralized assets, technologies, and entities to strengthen our communities. Together, we can be a force for good.",
    },
  ],
  investing_building: "Investing & Building",
  for_a_decentralized: "For A Decentralized Future",
  home_s2_sub_content1: "At Unidef, our mission is to accelerate the world's transition to a ",
  home_s2_sub_content2: "decentralized future ",
  home_s2_sub_content3: "that empowers all to take full control of their Finances, Identity and Data. ",
  home_s2_sub_content4: "Join the Movement Now!",
  home_new_s2_title1: "The Power of One",
  home_new_s2_title2: "The Decentralized Future",
  home_new_s2_title3: "Focused On Adoption",
  home_new_right_s2_desc1: "Unidef is organized into interconnected global networks  – ",
  home_new_right_s2_descb2: "Agilance",
  home_new_right_s2_descb3: "Nexensu ",
  home_new_right_s2_descb4: " Unidef Global Services",
  home_new_right_s2_desc9: " and more. At the core, ",
  home_new_right_s2_desc10: " and ",
  home_new_right_s2_desc8: " will drive the organization's operations to deliver services on — a greater scale.",
  home_new_right_s2_1_desc1: "Through this interconnected network of organizations, Unidef enables the world to take control over its finances, data, and identity, paving the way for a decentralized future. And all of this is made possible through ",
  home_new_right_s2_1_desc2: " fueled by a common purpose, an unwavering spirit, shared behaviors, greatness of character, and an unyielding commitment to our community.",
  home_new_right_s2_desc2: "Unidef and its members are committed to supporting decentralized technologies, protocols, assets, entities, and various initiatives that empower the world to own its finances, data, and identity and participate in a decentralized future.",
  home_new_right_s2_2_desc2: "Guided by this notion, Unidef invests capital and expertise, and gives grants to diverse groups and individuals who promote our values — through a unique network driven by local voices and global expertise.",
  home_new_right_s2_desc3: "Unidef proactively cooperates with numerous crypto businesses, aiding in the development of use cases and widespread acceptance of their native currencies among Unidef and its partners, enabling global adoption and impact.",
  boladText: '"The Power of One"; ',
  home_s2_hilights_desc1: [
    {
      id: 1,
      title: "Unidef",
      description: "Unidef refers to the global network of organizations and may refer to one or more of the members of United Decentralized Foundations, each of which is a separate legal entity (collectively, the “Unidef”)."
    },
    {
      id: 2,
      title: "global networks",
      description: "Unidef is a global network of independent entities and not a partnership or a single firm. Each member of the network operates autonomously while working together to achieve shared goals."
    },
  ],
  home_s2_hilights_desc2: [
    {
      id: 1,
      title: "decentralized",
      description: "A network and model that is not controlled by a centralized entity. "
    },
    {
      id: 2,
      title: "protocols",
      description: "Rules that define how data is allowed to be transferred between different computer systems."
    },
  ],
  join_us: "Join us",
  united_worldwide: "United Worldwide",
  home_new_s8_title1: "Connect ",
  home_new_s8_title2: "with us today!",
  home_s9_title1: "The Decentralized Future, ",
  home_s9_title3: " Faster.",
  home_s9_subtitle1: "Let’s talk about what we can build and scale together. ",
  home_s9_subtitle2: "The earlier we can invest, the more helpful we can be.",
  work_at_unidef: "Work at Unidef",
  partnar_at_unidef: " Partner with Unidef",
  decemount: "Decemount",
  home_bottom_line_data: [
    {
      image: bannerFooter,
      title: "For A Decentralized Future",
    },
    {
      image: bannerFooter,
      title: "For A Decentralized Future",
    },
    {
      image: bannerFooter,
      title: "For A Decentralized Future",
    },
    {
      image: bannerFooter,
      title: "For A Decentralized Future",
    },
  ],
  home_bottom_line_data1: [
    {
      image: bannerFooter,
      title: "For A Decentralized Future",
    },
    {
      image: bannerFooter,
      title: "For A Decentralized Future",
    },
  ],
  // eslint-disable-next-line no-dupe-keys
  home_s4_tooltip: "Digital asset prices can be volatile.You are solely responsible for your decisions and Unidef and/or its affiliates are not liable for any losses.",
  home_new_network_data: [
    {
      id: 1,
      buttonName: "Unidef iLabs",
    },
    {
      id: 2,
      buttonName: "Agunium",
    },
    {
      id: 3,
      buttonName: "Nexensu",
    },
    {
      id: 4,
      buttonName: "Agilance",
    },
    {
      id: 5,
      buttonName: "VisionX",
    },
  ],
  home_single_button_data: [
    {
      id: 1,
      topTitle: "Unidef iLabs",
      title: "Investing Capital & Expertise ",
      title2: "In Exceptional Founders",
      subTitle: "The investment and incubation arm of Unidef, Unidef iLabs, identifies, supports, and empowers innovative entrepreneurs, startups, enterprises, and communities through investments, incubation, and various initiatives in order to make up the foundations of the not-so-distant decentralized future.",
      followText: "Follow Unidef iLabs",
      image: UnidefIlabsHome,
      tooltipText: "Digital asset prices can be volatile. You are solely responsible for your decisions, and Unidef and/or its affiliates are not liable for any losses. Please see our Full Disclosure for important details.",
      titlelearn: "LEARN MORE",
      url: routePaths.ilabsScreen,
      home_social_data: [
        {
          id: 1,
          imgHover: TwitterHomeColored,
          imgOut: TwitterHome,
          url: "https://twitter.com/UnidefiLabs",
        },
        {
          id: 2,
          imgHover: TeligramHomeColored,
          imgOut: TeligramHome,
          url: "https://t.me/UnidefGlobal",
        },
        {
          id: 3,
          imgHover: InstagramHomeColored,
          imgOut: InstagramHome,
          url: "https://www.instagram.com/unidefglobal/",
        },
        {
          id: 4,
          imgHover: FacebookHomeColored,
          imgOut: FacebookHome,
          url: "https://www.facebook.com/UnidefGlobal/",
        },
        {
          id: 5,
          imgHover: YoutubHover,
          imgOut: Youtub,
          url: "https://www.youtube.com/@UnidefGlobal",
        },
        {
          id: 6,
          imgHover: LinkdinHomeHover,
          imgOut: LinkdinHome,
          url: "https://www.linkedin.com/company/unidef",
        }
      ]
    },
    {
      id: 2,
      topTitle: "Agunium",
      title: "Investing In Cryptos",
      title2: "Since 2015",
      subTitle: "Agunium invests in decentralized assets and cryptos that will fundamentally redefine the Internet, financial system, and money in a way that empowers all to take full control of their finances, data, and identity.",
      image: AguniumImage,
      followText: "Follow Agunium",
      tooltipText: "Digital asset prices can be volatile. You are solely responsible for your decisions, and Unidef and/or its affiliates are not liable for any losses. Please see our Full Disclosure for important details.",
      titlelearn: "LEARN MORE",
      url: routePaths.aguniumScreen,
      // link: "/agunium",
      // idName: "uranium-section-one",
      home_social_data: [
        {
          id: 1,
          imgHover: TwitterHomeColored,
          imgOut: TwitterHome,
          url: "https://twitter.com/Agunium"
        },
        {
          id: 2,
          imgHover: TeligramHomeColored,
          imgOut: TeligramHome,
          url: "https://t.me/UnidefGlobal"
        },
        {
          id: 3,
          imgHover: InstagramHomeColored,
          imgOut: InstagramHome,
          url: "https://www.instagram.com/unidefglobal/"
        },
        {
          id: 4,
          imgHover: FacebookHomeColored,
          imgOut: FacebookHome,
          url: "https://www.facebook.com/UnidefGlobal/"
        },
        {
          id: 5,
          imgHover: YoutubHover,
          imgOut: Youtub,
          url: "https://www.youtube.com/@UnidefGlobal",
        },
        {
          id: 6,
          imgHover: LinkdinHomeHover,
          imgOut: LinkdinHome,
          url: "https://www.linkedin.com/company/unidef",
        }
      ]
    },
    {
      id: 3,
      topTitle: "Nexensu",
      title: "Integrated Marketing Agency",
      title2: "Designed For What's Next",
      subTitle: "At Nexensu, we are inspired by the decentralized future. Our mission is to inspire people and brands to impact the world.",
      followText: "Follow Nexensu ",
      image: NexensuImage,
      tooltipText: "Digital asset prices can be volatile. You are solely responsible for your decisions, and Unidef and/or its affiliates are not liable for any losses. Please see our Full Disclosure for important details.",
      titlelearn: "LEARN MORE",
      url: routePaths.nexensuScreen,
      home_social_data: [
        {
          id: 1,
          imgHover: TwitterHomeColored,
          imgOut: TwitterHome,
          url: "https://twitter.com/Nexensu",
        },
        {
          id: 2,
          imgHover: TeligramHomeColored,
          imgOut: TeligramHome,
          url: "https://t.me/UnidefGlobal",
        },
        {
          id: 3,
          imgHover: InstagramHomeColored,
          imgOut: InstagramHome,
          url: "https://www.instagram.com/unidefglobal/",
        },
        {
          id: 4,
          imgHover: FacebookHomeColored,
          imgOut: FacebookHome,
          url: "https://www.facebook.com/UnidefGlobal/",
        },
        {
          id: 5,
          imgHover: YoutubHover,
          imgOut: Youtub,
          url: "https://www.youtube.com/@UnidefGlobal",
        },
        {
          id: 6,
          imgHover: LinkdinHomeHover,
          imgOut: LinkdinHome,
          url: "https://www.linkedin.com/company/unidef",
        }
      ]
    },
    {
      id: 4,
      topTitle: "Agilance",
      title: "Integrated Legal Solutions",
      title2: "For A Decentralized Future",
      subTitle: "Agilance works to empower a decentralized future where players are accountable to their community.",
      followText: "Follow Agilance",
      image: AgilanceImage,
      tooltipText: "Digital asset prices can be volatile. You are solely responsible for your decisions, and Unidef and/or its affiliates are not liable for any losses. Please see our Full Disclosure for important details.",
      titlelearn: "LEARN MORE",
      url:routePaths.agilanceScreen,
      // link: "/agilance",
      // idName: "token-section-one-main",
      home_social_data: [
        {
          id: 1,
          imgHover: TwitterHomeColored,
          imgOut: TwitterHome,
          url: "https://twitter.com/Agilance",
        },
        {
          id: 2,
          imgHover: TeligramHomeColored,
          imgOut: TeligramHome,
          url: "https://t.me/UnidefGlobal",
        },
        {
          id: 3,
          imgHover: InstagramHomeColored,
          imgOut: InstagramHome,
          url: "https://www.instagram.com/unidefglobal/",
        },
        {
          id: 4,
          imgHover: FacebookHomeColored,
          imgOut: FacebookHome,
          url: "https://www.facebook.com/UnidefGlobal/",
        },
        {
          id: 5,
          imgHover: YoutubHover,
          imgOut: Youtub,
          url: "https://www.youtube.com/@UnidefGlobal",
        },
        {
          id: 6,
          imgHover: LinkdinHomeHover,
          imgOut: LinkdinHome,
          url: "https://www.linkedin.com/company/unidef",
        }
      ]
    },
    {
      id: 5,
      topTitle: "VisionX Foundation",
      title: "Holding The Centralized World",
      title2: "Accountable — To their people.",
      subTitle: "Founded in 2023, The VisionX Foundation is an independent, non-profit that supports the decentralized economy by defending victims of the centralized world.",
      followText: "Follow VisionX Foundation",
      image: AgilanceImage,
      tooltipText: "The VisionX Foundation does not guarantee the recovery of any assets or the success of any legal proceedings against any individual or entity. For more comprehensive information, please refer to our full Disclosure available on VisionX.Foundation.",
      titlelearn: "LEARN MORE",
      url:"http://visionx.foundation/",
      // link: "/agilance",
      // idName: "token-section-one-main",
      home_social_data: [
        {
          id: 1,
          imgHover: TwitterHomeColored,
          imgOut: TwitterHome,
          url: "https://twitter.com/VisionXFdn",
        },
        {
          id: 2,
          imgHover: TeligramHomeColored,
          imgOut: TeligramHome,
          url: "https://t.me/VisionXFdn",
        },
        {
          id: 3,
          imgHover: InstagramHomeColored,
          imgOut: InstagramHome,
          url: "https://www.instagram.com/unidefglobal/",
        },
        {
          id: 4,
          imgHover: FacebookHomeColored,
          imgOut: FacebookHome,
          url: "https://www.facebook.com/UnidefGlobal/",
        },
        {
          id: 5,
          imgHover: YoutubHover,
          imgOut: Youtub,
          url: "https://www.youtube.com/@UnidefGlobal",
        },
        {
          id: 6,
          imgHover: LinkdinHomeHover,
          imgOut: LinkdinHome,
          url: "https://www.linkedin.com/company/unidef",
        }
      ]
    },
  ],
  home_single_button_mobile_data: [
    {
      id: 1,
      topTitle: "Unidef iLabs",
      title: "Investing Capital & Expertise ",
      title2: "In Exceptional Founders",
      subTitle: "The investment and incubation arm of Unidef, Unidef iLabs, identifies, supports, and empowers innovative entrepreneurs, startups, enterprises, and communities through investments, incubation, and various initiatives in order to make up the foundations of the not-so-distant decentralized future.",
      followText: "Follow Unidef iLabs",
      image: UnidefIlabsHome,
      tooltipText: "Digital asset prices can be volatile. You are solely responsible for your decisions, and Unidef and/or its affiliates are not liable for any losses. Please see our Full Disclosure for important details.",
      titlelearn: "LEARN MORE",
      url: routePaths.ilabsScreen,
      home_social_data: [
        {
          id: 1,
          imgHover: TwitterHomeColored,
          imgOut: TwitterHome1,
          url: "https://twitter.com/UnidefiLabs",
        },
        {
          id: 2,
          imgHover: TeligramHomeColored,
          imgOut: TeligramHome1,
          url: "https://t.me/UnidefGlobal",
        },
        {
          id: 3,
          imgHover: InstagramHomeColored,
          imgOut: InstagramHome1,
          url: "https://www.instagram.com/unidefglobal/",
        },
        {
          id: 4,
          imgHover: FacebookHomeColored,
          imgOut: FacebookHome1,
          url: "https://www.facebook.com/UnidefGlobal/",
        },
        {
          id: 5,
          imgHover: YoutubHover,
          imgOut: Youtub1,
          url: "https://www.youtube.com/@UnidefGlobal",
        },
        {
          id: 6,
          imgHover: LinkdinHomeHover,
          imgOut: LinkdinHome1,
          url: "https://www.linkedin.com/company/unidef",
        }
      ]
    },
    {
      id: 2,
      topTitle: "Agunium",
      title: "Investing In Cryptos",
      title2: "Since 2015",
      subTitle: "Agunium invests in decentralized assets and cryptos that will fundamentally redefine the Internet, financial system, and money in a way that empowers all to take full control of their finances, data, and identity.",
      image: AguniumImage,
      followText: "Follow Agunium",
      tooltipText: "Digital asset prices can be volatile. You are solely responsible for your decisions, and Unidef and/or its affiliates are not liable for any losses. Please see our Full Disclosure for important details.",
      titlelearn: "LEARN MORE",
      url: routePaths.aguniumScreen,
      // link: "/agunium",
      // idName: "uranium-section-one",
      home_social_data: [
        {
          id: 1,
          imgHover: TwitterHomeColored,
          imgOut: TwitterHome1,
          url: "https://twitter.com/Agunium"
        },
        {
          id: 2,
          imgHover: TeligramHomeColored,
          imgOut: TeligramHome1,
          url: "https://t.me/UnidefGlobal"
        },
        {
          id: 3,
          imgHover: InstagramHomeColored,
          imgOut: InstagramHome1,
          url: "https://www.instagram.com/unidefglobal/"
        },
        {
          id: 4,
          imgHover: FacebookHomeColored,
          imgOut: FacebookHome1,
          url: "https://www.facebook.com/UnidefGlobal/"
        },
        {
          id: 5,
          imgHover: YoutubHover,
          imgOut: Youtub1,
          url: "https://www.youtube.com/@UnidefGlobal",
        },
        {
          id: 6,
          imgHover: LinkdinHomeHover,
          imgOut: LinkdinHome1,
          url: "https://www.linkedin.com/company/unidef",
        }
      ]
    },
    {
      id: 3,
      topTitle: "Nexensu",
      title: "Integrated Marketing Agency",
      title2: "Designed For What's Next",
      subTitle: "At Nexensu, we are inspired by the decentralized future. Our mission is to inspire people and brands to impact the world.",
      followText: "Follow Nexensu ",
      image: NexensuImage,
      tooltipText: "Digital asset prices can be volatile. You are solely responsible for your decisions, and Unidef and/or its affiliates are not liable for any losses. Please see our Full Disclosure for important details.",
      titlelearn: "LEARN MORE",
      url: routePaths.nexensuScreen,
      home_social_data: [
        {
          id: 1,
          imgHover: TwitterHomeColored,
          imgOut: TwitterHome1,
          url: "https://twitter.com/Nexensu",
        },
        {
          id: 2,
          imgHover: TeligramHomeColored,
          imgOut: TeligramHome1,
          url: "https://t.me/UnidefGlobal",
        },
        {
          id: 3,
          imgHover: InstagramHomeColored,
          imgOut: InstagramHome1,
          url: "https://www.instagram.com/unidefglobal/",
        },
        {
          id: 4,
          imgHover: FacebookHomeColored,
          imgOut: FacebookHome1,
          url: "https://www.facebook.com/UnidefGlobal/",
        },
        {
          id: 5,
          imgHover: YoutubHover,
          imgOut: Youtub1,
          url: "https://www.youtube.com/@UnidefGlobal",
        },
        {
          id: 6,
          imgHover: LinkdinHomeHover,
          imgOut: LinkdinHome1,
          url: "https://www.linkedin.com/company/unidef",
        }
      ]
    },
    {
      id: 4,
      topTitle: "Agilance",
      title: "Integrated Legal Solutions",
      title2: "For A Decentralized Future",
      subTitle: "Agilance works to empower a decentralized future where players are accountable to their community.",
      followText: "Follow Agilance",
      image: AgilanceImage,
      tooltipText: "Digital asset prices can be volatile. You are solely responsible for your decisions, and Unidef and/or its affiliates are not liable for any losses. Please see our Full Disclosure for important details.",
      titlelearn: "LEARN MORE",
      // url:routePaths.agilanceScreen,
      // link: "/agilance",
      // idName: "token-section-one-main",
      home_social_data: [
        {
          id: 1,
          imgHover: TwitterHomeColored,
          imgOut: TwitterHome1,
          url: "https://twitter.com/Agilance",
        },
        {
          id: 2,
          imgHover: TeligramHomeColored,
          imgOut: TeligramHome1,
          url: "https://t.me/UnidefGlobal",
        },
        {
          id: 3,
          imgHover: InstagramHomeColored,
          imgOut: InstagramHome1,
          url: "https://www.instagram.com/unidefglobal/",
        },
        {
          id: 4,
          imgHover: FacebookHomeColored,
          imgOut: FacebookHome1,
          url: "https://www.facebook.com/UnidefGlobal/",
        },
        {
          id: 5,
          imgHover: YoutubHover,
          imgOut: Youtub1,
          url: "https://www.youtube.com/@UnidefGlobal",
        },
        {
          id: 6,
          imgHover: LinkdinHomeHover,
          imgOut: LinkdinHome1,
          url: "https://www.linkedin.com/company/unidef",
        }
      ]
    },
    {
      id: 5,
      topTitle: "VisionX Foundation",
      title: "Holding The Centralized World",
      title2: "Accountable — To their people.",
      subTitle: "Founded in 2023, The VisionX Foundation is an independent, non-profit that supports the decentralized economy by defending victims of the centralized world.",
      followText: "Follow VisionX Foundation",
      image: AgilanceImage,
      tooltipText: "The VisionX Foundation does not guarantee the recovery of any assets or the success of any legal proceedings against any individual or entity. For more comprehensive information, please refer to our full Disclosure available on VisionX.Foundation.",
      titlelearn: "LEARN MORE",
      url:"http://visionx.foundation/",
      // link: "/agilance",
      // idName: "token-section-one-main",
      home_social_data: [
        {
          id: 1,
          imgHover: TwitterHomeColored,
          imgOut: TwitterHome1,
          url: "https://twitter.com/VisionXFdn",
        },
        {
          id: 2,
          imgHover: TeligramHomeColored,
          imgOut: TeligramHome1,
          url: "https://t.me/VisionXFdn",
        },
        {
          id: 3,
          imgHover: InstagramHomeColored,
          imgOut: InstagramHome1,
          url: "https://www.instagram.com/unidefglobal/",
        },
        {
          id: 4,
          imgHover: FacebookHomeColored,
          imgOut: FacebookHome1,
          url: "https://www.facebook.com/UnidefGlobal/",
        },
        {
          id: 5,
          imgHover: YoutubHover,
          imgOut: Youtub1,
          url: "https://www.youtube.com/@UnidefGlobal",
        },
        {
          id: 6,
          imgHover: LinkdinHomeHover,
          imgOut: LinkdinHome1,
          url: "https://www.linkedin.com/company/unidef",
        }
      ]
    },
  ],
  uranium_s5_m_t1: "Treasury Growth",
  s5_sub_title_mobile: "Decemount's Strategic Plan for Treasury Expansion",
  home_s9_Card_data_main: [
    {
      id: 1,
      title: "Unidef",
      isopen_data: [
        {
          id: 1,
          title: "Unidef",
          social_buttom_home_s91: [
            {
              title: 'Twitter',
              prependIcon: TwitterHomeS9,
              buttonLink: "https://twitter.com/UnidefGlobal",
            },
            {
              title: 'Telegram',
              prependIcon: TelegramHomeS9,
              buttonLink: "https://t.me/UnidefGlobal",
            },
            {
              title: 'Instagram',
              prependIcon: InstagramHomeS9,
              buttonLink: "https://www.instagram.com/unidefglobal/",
            },
          ],
          social_buttom_home_s92: [
            {
              title: 'Facebook',
              prependIcon: FacebookHomeS9,
              buttonLink: "https://www.facebook.com/UnidefGlobal/",
            },
            {
              title: 'LinkedIn',
              prependIcon: LinkedInHomeS9,
              buttonLink: "https://www.linkedin.com/company/unidef",
            },
            {
              title: 'Youtube',
              prependIcon: Youtub,
              buttonLink: "https://www.youtube.com/@UnidefGlobal",
            },
          ],
          social_buttom_home_s9M1: [
            {
              title: 'Twitter',
              prependIcon: TwitterHomeS9,
              buttonLink: "https://twitter.com/UnidefGlobal",
            },
            {
              title: 'Telegram',
              prependIcon: TelegramHomeS9,
              buttonLink: "https://t.me/UnidefGlobal",
            },
          ],
          social_buttom_home_s9M2: [
            {
              title: 'Instagram',
              prependIcon: InstagramHomeS9,
              buttonLink: "https://www.instagram.com/unidefglobal/",
            },
            {
              title: 'Facebook',
              prependIcon: FacebookHomeS9,
              buttonLink: "https://www.facebook.com/UnidefGlobal/",
            },
          ],
          social_buttom_home_s9M3: [
            {
              title: 'LinkedIn',
              prependIcon: LinkedInHomeS9,
              buttonLink: "https://www.linkedin.com/company/unidef",
            },
            {
              title: 'Youtube',
              prependIcon: Youtub,
              buttonLink: "https://www.youtube.com/@UnidefGlobal",
            },
          ],
        }
      ],
    },
    {
      id: 2,
      title: "Unidef iLabs",
      isopen_data: [
        {
          id: 1,
          title: "Unidef iLabs",
          social_buttom_home_s9M1: [
            {
              title: 'Twitter',
              prependIcon: TwitterHomeS9,
              buttonLink: "https://twitter.com/UnidefiLabs",
            },
            {
              title: 'Telegram',
              prependIcon: TelegramHomeS9,
              buttonLink: "https://t.me/UnidefGlobal",
            },
          ],
          social_buttom_home_s9M2: [
            {
              title: 'Instagram',
              prependIcon: InstagramHomeS9,
              buttonLink: "https://www.instagram.com/unidefglobal/",
            },
            {
              title: 'Facebook',
              prependIcon: FacebookHomeS9,
              buttonLink: "https://www.facebook.com/UnidefGlobal/",
            },
          ],
          social_buttom_home_s9M3: [
            {
              title: 'LinkedIn',
              prependIcon: LinkedInHomeS9,
              buttonLink: "https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFy1gQ4LufzNwAAAYj8pp5wwgSc2BlBwG6IFVLrKGIWWpU7TGU7xltBp-Voglqmh1KwY3lG3sWr935EnGWWfdrl0P7EPPUKcamyPbzF8R57zcyGc_7Qltr8DpIuz2nP6Nfv3iA=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Funidef",
            },
            {
              title: 'Youtube',
              prependIcon: Youtub,
              buttonLink: "https://www.youtube.com/@UnidefGlobal",
            },
          ],
          social_buttom_home_s91: [
            {
              title: 'Twitter',
              prependIcon: TwitterHomeS9,
              buttonLink: "https://twitter.com/UnidefiLabs",
            },
            {
              title: 'Telegram',
              prependIcon: TelegramHomeS9,
              buttonLink: "https://t.me/UnidefGlobal",
            },
            {
              title: 'Instagram',
              prependIcon: InstagramHomeS9,
              buttonLink: "https://www.instagram.com/unidefglobal/",
            },
          ],
          social_buttom_home_s92: [
            {
              title: 'Facebook',
              prependIcon: FacebookHomeS9,
              buttonLink: "https://www.facebook.com/UnidefGlobal/",
            },
            {
              title: 'LinkedIn',
              prependIcon: LinkedInHomeS9,
              buttonLink: "https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFy1gQ4LufzNwAAAYj8pp5wwgSc2BlBwG6IFVLrKGIWWpU7TGU7xltBp-Voglqmh1KwY3lG3sWr935EnGWWfdrl0P7EPPUKcamyPbzF8R57zcyGc_7Qltr8DpIuz2nP6Nfv3iA=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Funidef",
            },
            {
              title: 'Youtube',
              prependIcon: Youtub,
              buttonLink: "https://www.youtube.com/@UnidefGlobal",
            },
          ],
        }
      ],
    },
    {
      id: 3,
      title: "Agunium",
      isopen_data: [
        {
          id: 1,
          title: "Agunium",
          social_buttom_home_s9M1: [
            {
              title: 'Twitter',
              prependIcon: TwitterHomeS9,
              buttonLink: "https://twitter.com/Agunium",
            },
            {
              title: 'Telegram',
              prependIcon: TelegramHomeS9,
              buttonLink: "https://t.me/UnidefGlobal",
            },
          ],
          social_buttom_home_s9M2: [
            {
              title: 'Instagram',
              prependIcon: InstagramHomeS9,
              buttonLink: "https://www.instagram.com/unidefglobal/",
            },
            {
              title: 'Facebook',
              prependIcon: FacebookHomeS9,
              buttonLink: "https://www.facebook.com/UnidefGlobal/",
            },
          ],
          social_buttom_home_s9M3: [
            {
              title: 'LinkedIn',
              prependIcon: LinkedInHomeS9,
              buttonLink: "https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFy1gQ4LufzNwAAAYj8pp5wwgSc2BlBwG6IFVLrKGIWWpU7TGU7xltBp-Voglqmh1KwY3lG3sWr935EnGWWfdrl0P7EPPUKcamyPbzF8R57zcyGc_7Qltr8DpIuz2nP6Nfv3iA=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Funidef",
            },
            {
              title: 'Youtube',
              prependIcon: Youtub,
              buttonLink: "https://www.youtube.com/@UnidefGlobal",
            },
          ],
          social_buttom_home_s91: [
            {
              title: 'Twitter',
              prependIcon: TwitterHomeS9,
              buttonLink: "https://twitter.com/Agunium",
            },
            {
              title: 'Telegram',
              prependIcon: TelegramHomeS9,
              buttonLink: "https://t.me/UnidefGlobal",
            },
            {
              title: 'Instagram',
              prependIcon: InstagramHomeS9,
              buttonLink: "https://www.instagram.com/unidefglobal/",
            },
          ],
          social_buttom_home_s92: [
            {
              title: 'Facebook',
              prependIcon: FacebookHomeS9,
              buttonLink: "https://www.facebook.com/UnidefGlobal/",
            },
            {
              title: 'LinkedIn',
              prependIcon: LinkedInHomeS9,
              buttonLink: "https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFy1gQ4LufzNwAAAYj8pp5wwgSc2BlBwG6IFVLrKGIWWpU7TGU7xltBp-Voglqmh1KwY3lG3sWr935EnGWWfdrl0P7EPPUKcamyPbzF8R57zcyGc_7Qltr8DpIuz2nP6Nfv3iA=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Funidef",
            },
            {
              title: 'Youtube',
              prependIcon: Youtub,
              buttonLink: "https://www.youtube.com/@UnidefGlobal",
            },
          ],
        }
      ],
    },
    {
      id: 4,
      title: "Nexensu",
      isopen_data: [
        {
          id: 1,
          title: "Nexensu",
          social_buttom_home_s9M1: [
            {
              title: 'Twitter',
              prependIcon: TwitterHomeS9,
              buttonLink: "https://twitter.com/Nexensu",
            },
            {
              title: 'Telegram',
              prependIcon: TelegramHomeS9,
              buttonLink: "https://t.me/UnidefGlobal",
            },
          ],
          social_buttom_home_s9M2: [
            {
              title: 'Instagram',
              prependIcon: InstagramHomeS9,
              buttonLink: "https://www.instagram.com/unidefglobal/",
            },
            {
              title: 'Facebook',
              prependIcon: FacebookHomeS9,
              buttonLink: "https://www.facebook.com/UnidefGlobal/",
            },
          ],
          social_buttom_home_s9M3: [
            {
              title: 'LinkedIn',
              prependIcon: LinkedInHomeS9,
              buttonLink: "https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFy1gQ4LufzNwAAAYj8pp5wwgSc2BlBwG6IFVLrKGIWWpU7TGU7xltBp-Voglqmh1KwY3lG3sWr935EnGWWfdrl0P7EPPUKcamyPbzF8R57zcyGc_7Qltr8DpIuz2nP6Nfv3iA=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Funidef",
            },
            {
              title: 'Youtube',
              prependIcon: Youtub,
              buttonLink: "https://www.youtube.com/@UnidefGlobal",
            },
          ],
          social_buttom_home_s91: [
            {
              title: 'Twitter',
              prependIcon: TwitterHomeS9,
              buttonLink: "https://twitter.com/Nexensu",
            },
            {
              title: 'Telegram',
              prependIcon: TelegramHomeS9,
              buttonLink: "https://t.me/UnidefGlobal",
            },
            {
              title: 'Instagram',
              prependIcon: InstagramHomeS9,
              buttonLink: "https://www.instagram.com/unidefglobal/",
            },
          ],
          social_buttom_home_s92: [
            {
              title: 'Facebook',
              prependIcon: FacebookHomeS9,
              buttonLink: "https://www.facebook.com/UnidefGlobal/",
            },
            {
              title: 'LinkedIn',
              prependIcon: LinkedInHomeS9,
              buttonLink: "https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFy1gQ4LufzNwAAAYj8pp5wwgSc2BlBwG6IFVLrKGIWWpU7TGU7xltBp-Voglqmh1KwY3lG3sWr935EnGWWfdrl0P7EPPUKcamyPbzF8R57zcyGc_7Qltr8DpIuz2nP6Nfv3iA=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Funidef",
            },
            {
              title: 'Youtube',
              prependIcon: Youtub,
              buttonLink: "https://www.youtube.com/@UnidefGlobal",
            },
          ],
        }
      ],
    },
    {
      id: 5,
      title: "Agilance",
      isopen_data: [
        {
          id: 1,
          title: "Agilance",
          social_buttom_home_s9M1: [
            {
              title: 'Twitter',
              prependIcon: TwitterHomeS9,
              buttonLink: "https://twitter.com/Agilance",
            },
            {
              title: 'Telegram',
              prependIcon: TelegramHomeS9,
              buttonLink: "https://t.me/UnidefGlobal",
            },
          ],
          social_buttom_home_s9M2: [
            {
              title: 'Instagram',
              prependIcon: InstagramHomeS9,
              buttonLink: "https://www.instagram.com/unidefglobal/",
            },
            {
              title: 'Facebook',
              prependIcon: FacebookHomeS9,
              buttonLink: "https://www.facebook.com/UnidefGlobal/",
            },
          ],
          social_buttom_home_s9M3: [
            {
              title: 'LinkedIn',
              prependIcon: LinkedInHomeS9,
              buttonLink: "https://www.linkedin.com/company/unidef",
            },
            {
              title: 'Youtube',
              prependIcon: Youtub,
              buttonLink: "https://www.youtube.com/@UnidefGlobal",
            },
          ],
          social_buttom_home_s91: [
            {
              title: 'Twitter',
              prependIcon: TwitterHomeS9,
              buttonLink: "https://twitter.com/Agilance",
            },
            {
              title: 'Telegram',
              prependIcon: TelegramHomeS9,
              buttonLink: "https://t.me/UnidefGlobal",
            },
            {
              title: 'Instagram',
              prependIcon: InstagramHomeS9,
              buttonLink: "https://www.instagram.com/unidefglobal/",
            },
          ],
          social_buttom_home_s92: [
            {
              title: 'Facebook',
              prependIcon: FacebookHomeS9,
              buttonLink: "https://www.facebook.com/UnidefGlobal/",
            },
            {
              title: 'LinkedIn',
              prependIcon: LinkedInHomeS9,
              buttonLink: "https://www.linkedin.com/company/unidef",
            },
            {
              title: 'Youtube',
              prependIcon: Youtub,
              buttonLink: "https://www.youtube.com/@UnidefGlobal",
            },
          ],
        }
      ],
    },
  ],
  //Uranium
  uranium_s1_text: "We are Decemount. We team together to empower the next generation of organizations with Unidef-governd initiatives.We are the network designed for those who are building what's next. Let's build a decentralized future together.",
  uranium_s1_text_mobile: "We are Decemount. We team together to empower the next generation of organizations with Unidef-governedinitiatives.We are the network designed for those who are building what's next. Let's build a decentralized future together.",
  introducing: "Introducing",
  unidef_decemou: "Unidef Decemount",
  uranium_s2_text1: "Unidef Decemount (commonly known as Decemount) is a global community made up of a band of creators,  builders, communities, organizations, and ecosystems united by a mutually beneficial pact to bring the world to a decentralized future and beyond.",
  uranium_s2_text2: "Guided by curiosity and a desire to invent and empower new products and brands, Decemount is at the forefront of a decentralized movement",
  token_s2_text2_uranium: "Audited by leading global security firms, providing real-time monitoring and analysis.",
  driving_value: "Driving Value",
  for_our_community: "For Our Community",
  uranium_breakBox_data: [
    {
      id: 1,
      title1: "Building the World's",
      title2: "Largest Network Of Dao",
      desc: "Through strategic partnerships and ventures, we are building a robust ecosystem capable of becoming the world's largest network of decentralized autonomous organizations.",
      flagImage: newTokenCard1,
    },
    {
      id: 2,
      title1: "Token Swap",
      title2: "and Co-Development",
      desc: "We offer token swap and co-development initiatives with both existing and emerging projects. We provide support through token holdings and pledges to ensure the success of these collaborations.",
      flagImage: newTokenCard2,
    },
    {
      id: 3,
      title1: "Supporting Ideas and",
      title2: "Emerging Technologies",
      desc: "We seek out and support like-minded individuals and organizations in Web3, Crypto, AI, Metaverse, and ecentralized space to nurture emerging technologies",
      flagImage: newTokenCard3,
    },
    {
      id: 4,
      title1: "Awarding Grants",
      title2: "for Social Good",
      desc: "We provide grants to individuals and organizations working towards social good through innovative products or research.",
      flagImage: newTokenCard4,
    },
    {
      id: 5,
      title1: "Accelerating the Transition",
      title2: "Into a New Era of DAOs",
      desc: "We collaborate with specialized DAOs in finance, art and culture, farming, research and development, events, news, and education. Additionally, we provide operational services to existing and future DAOs to help drive the transition into a new era of DAOs",
      flagImage: newTokenCard3,
    },
    {
      id: 6,
      title1: "Building the Investing",
      title2: "Infrastructure for Web3",
      desc: "Investing is about to enter a new era - one that is decentralized, and community-driven. We are leading the way with our innovative initiatives that enable communities to raise, coordinate, and invest their capital and resources in unprecedented ways",
      flagImage: newTokenCard4,
    },
    {
      id: 7,
      title1: "Connecting",
      title2: "with the Community",
      desc: "We're all about pioneering the creation of digital public goods and paving the way for financial freedom. So join us as we embark on an exciting journey towards shaping a decentralized future.",
      flagImage: newTokenCard4,
    },
    {
      id: 8,
      title1: "Driving Global Adoption",
      title2: "of Unidef (U)",
      desc: "We strive to accelerate the global adoption of Unidef (U) and explore synergies that link its value to the broader ecosystem.",
      flagImage: newTokenCard4,
    },
  ],
  treasury_growth: "Treasury Growth",
  uranium_s5_t1: "Decemount's Strategic Plan for Treasury",
  uranium_s5_t2: "Expansion",
  uranium_s5_sub_title: "As a forward-thinking organization, Decemount is constantly exploring new ways to expand its treasury. We aim to maximize growth, increase our financial resources and support industry success. Here are some of the ways we're achieving this:",
  s5_bottom_des: "The total dollar amount of tokens held in the Decemount's DAO Treasury",
  uranium_s3_title: "A Decentralized Currency ",
  uranium_s3_title2: "For A Decentralized Future",
  uranium_s3_t2: "Decemount is at the forefront of the decentralized economy, and as such, we don't accept traditional forms of currency. Instead, we use Unidef (U) to power our economy. If you're interested in participating in activities in Decemount, you'll need to hold the Unidef (U) tokens.",
  uranium_s6_breakBox_data: [
    {
      id: 1,
      title1: "Connect your wallet ",
      desc: 'Follow the instructions provided to connect your wallet to the exchange',
      flagImage: newTokenCard1S6,
    },
    {
      id: 2,
      title1: "Purchase BNB Coins ",
      desc: "Add BNB as a payment option and ",
      linkText1: "follow the instructions",
      linkurl: "https://www.binance.com/en/how-to-buy/bnb",
      desc3: " for purchasing BNB tokens",
      flagImage: newTokenCard2S6,
    },
    {
      id: 3,
      title1: "Search for Unidef Smart Contract",
      desc: "Return to PancakeSwap and search for 0x89dB9B433FeC1307d3dc8908f2813e9f7a1d38F0 in the 'Tokens' tab, and select the amount of Unidef (U) you would like to buy",
      flagImage: newTokenCard3S6,
    },
    {
      id: 4,
      title1: "Confirm Your Purchase",
      desc: "Confirm your purchase on your wallet, double-checking that all details are accurate before confirming your transaction",
      flagImage: newTokenCard4S6,
    },
    {
      id: 5,
      title1: "Welcome to the Unidef Ecosystem!",
      desc: "Congratulations, you have now successfully purchased Unidef (U) on PancakeSwap. Finance! Welcome to the Unidef Ecosystem",
      flagImage: newTokenCard5S6,
    },
  ],
  what: "What's",
  new_at_decemount: "New at Decemount?",
  uranium_s8_title1: "Your Questions About",
  uranium_s8_title2: "Decemount, Answered.",
  for_a_decentralized_futur: "For A Decentralized Future",
  uranium_s10_sub_title: "Join the Unidef community to accelerate the world's transition to",
  decentralized_future: " a decentralized future",
  //Verify Data
  verify_text1: "Please use ,Unidef Verify to check whether the source officially represents Unidef. ",
  verify_text2: "Website Link, Email Address, Phone Number, Twitter Account or Telegram ID.",
  verify_textm1: "Please use ,Unidef Verify to check whether the source",
  verify_textm2: "officially represents Unidef. Website Link, Email Address,",
  verify_textm3: "Phone Number, Twitter Account or Telegram ID",
  verify_data: [
    {
      id: 1,
      value: "unidef"
    },
    {
      id: 2,
      value: "unidef ilabs"
    },
    {
      id: 3,
      value: "unidef decemount"
    },
    {
      id: 4,
      value: "way"
    },
    {
      id: 5,
      value: "contact"
    },
  ],
  //New About Page
  new_about_s1_title1: "Accelerating",
  new_about_s1_title2: "the world’s transition to",
  new_about_s1_title3: "Decentralized Economy",
  s3_sub_title_about: "At Unidef, we focus on attracting and retaining the best talent with the skills.", s4_sub_title: "At Unidef, we focus on attracting and retaining the best talent with the skills.",
  our_action1: "Our Actions ",
  home_s9_titlea1: "The decentralized",
  home_s9_titlea2: "future,",
  our_locations: "Our locations",
  // about_new_s8_title: "The Unidef Offices",
  get_direction1: "GET DIRECTION",
  new_about_s5_title1: "John",
  new_about_s5_title2: "Giannandrea",
  new_about_s5_sub_title: "Senior Vice President and Chief Financial Officer",
  follow_on_twitter: "Follow on Twitter",
  follow_on_linkdin: "Follow on LinkedIn",
  about_s5_right_content: "The world has never been more in need of radical solutions. Gradual improvement is not enough. Disruption is the antidote to incrementalism. The world has never been more in need of radical solutions. ",
  s6_about_title: "Leadership Principles",
  s6_about_subtitle: "At Unidef, we focus on attracting and retaining the best talent with the skills.",
  close: "close",
  open: "open",
  s6_about_subtitle2: "Unidef funds entrepreneurs and organizations to create incentives that harness the power of private unidef enterprises to create advancement in the industry aligned with the mission of Unidef.",
  visit_other_offices: "Visit Other Offices",
  about_new_s3_datalist: [
    {
      id: 1,
      title: "Unidef",
      description: "We fund organizations and entrepreneurs to create incentives that will enable private enterprises    to drive progress in alignment with our mission for a decentralized future.",
      BoldTitle: "Find more about unidef",
      home_social_data: [
        {
          id: 1,
          imgHover: TwitterHomeColored,
          imgOut: TwitterHome,
          url: "https://twitter.com/UnidefGlobal",
        },
        {
          id: 2,
          imgHover: TeligramHomeColored,
          imgOut: TeligramHome,
          url: "https://t.me/UnidefGlobal",
        },
        {
          id: 3,
          imgHover: InstagramHomeColored,
          imgOut: InstagramHome,
          url: "https://www.instagram.com/unidefglobal/",
        },
        {
          id: 4,
          imgHover: FacebookHomeColored,
          imgOut: FacebookHome,
          url: "https://www.facebook.com/UnidefGlobal/",
        },
      ]
    },
    {
      id: 2,
      title: "Unidef Uranium",
      description: "We provide grants to those who are making waves in the AI, Web3, Crypto, Metaverse, and blockchain space.In addition, we support those who are pushing the boundaries of technology to shape a decentralized future.",
      BoldTitle: "Find more about unidef",
      home_social_data: [
        {
          id: 1,
          imgHover: TwitterHomeColored,
          imgOut: TwitterHome,
          url: "https://twitter.com/UnidefGlobal",
        },
        {
          id: 2,
          imgHover: TeligramHomeColored,
          imgOut: TeligramHome,
          url: "https://t.me/UnidefGlobal",
        },
        {
          id: 3,
          imgHover: InstagramHomeColored,
          imgOut: InstagramHome,
          url: "https://www.instagram.com/unidefglobal/",
        },
        {
          id: 4,
          imgHover: FacebookHomeColored,
          imgOut: FacebookHome,
          url: "https://www.facebook.com/UnidefGlobal/",
        },
      ]
    },
    {
      id: 3,
      title: "Unidef Coin",
      description: "We incubate and drive forward the fresh ideas, insights, and research that can take us one step closer to a decentralized future.",
      BoldTitle: "Find more about unidef",
      home_social_data: [
        {
          id: 1,
          imgHover: TwitterHomeColored,
          imgOut: TwitterHome,
          url: "https://twitter.com/UnidefGlobal",
        },
        {
          id: 2,
          imgHover: TeligramHomeColored,
          imgOut: TeligramHome,
          url: "https://t.me/UnidefGlobal",
        },
        {
          id: 3,
          imgHover: InstagramHomeColored,
          imgOut: InstagramHome,
          url: "https://www.instagram.com/unidefglobal/",
        },
        {
          id: 4,
          imgHover: FacebookHomeColored,
          imgOut: FacebookHome,
          url: "https://www.facebook.com/UnidefGlobal/",
        },
      ]
    },
    {
      id: 4,
      title: "Unidef Times",
      description: "We build trusted networks that have the capability to influence millions of people. Our ambition is to shape brands that are dedicated to bringing about a decentralized future and beyond.",
      BoldTitle: "Find more about unidef",
      home_social_data: [
        {
          id: 1,
          imgHover: TwitterHomeColored,
          imgOut: TwitterHome,
          url: "https://twitter.com/UnidefGlobal",
        },
        {
          id: 2,
          imgHover: TeligramHomeColored,
          imgOut: TeligramHome,
          url: "https://t.me/UnidefGlobal",
        },
        {
          id: 3,
          imgHover: InstagramHomeColored,
          imgOut: InstagramHome,
          url: "https://www.instagram.com/unidefglobal/",
        },
        {
          id: 4,
          imgHover: FacebookHomeColored,
          imgOut: FacebookHome,
          url: "https://www.facebook.com/UnidefGlobal/",
        },
      ]
    },
    {
      id: 5,
      title: "Unidef iLabs",
      description: "Our organization is built on community. We use the positive power of decentralized asset technologies, and entities to strengthen our communities. Together, we can be a force for good.",
      BoldTitle: "Find more about unidef",
      home_social_data: [
        {
          id: 1,
          imgHover: TwitterHomeColored,
          imgOut: TwitterHome,
          url: "https://twitter.com/UnidefGlobal",
        },
        {
          id: 2,
          imgHover: TeligramHomeColored,
          imgOut: TeligramHome,
          url: "https://t.me/UnidefGlobal",
        },
        {
          id: 3,
          imgHover: InstagramHomeColored,
          imgOut: InstagramHome,
          url: "https://www.instagram.com/unidefglobal/",
        },
        {
          id: 4,
          imgHover: FacebookHomeColored,
          imgOut: FacebookHome,
          url: "https://www.facebook.com/UnidefGlobal/",
        },
      ]
    },
  ],
  about_coin_slider_data: [
    {
      id: 1,
      img: aboutSlider1,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinName: "Coinbase",
      coinDate: "Wed . Mar 29, 2023",
    },
    {
      id: 2,
      img: aboutSlider2,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinName: "New York Times",
      coinDate: "Mon . Mar 27, 2023",
    },
    {
      id: 3,
      img: aboutSlider3,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinName: "Coinbase",
      coinDate: "Wed . Mar 29, 2023",
    },
    {
      id: 4,
      img: aboutSlider1,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinName: "Coinbase",
      coinDate: "Mon . Mar 27, 2023",
    }, {
      id: 5,
      img: aboutSlider2,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinName: "Coinbase",
      coinDate: "Wed . Mar 29, 2023",
    },
    {
      id: 6,
      img: aboutSlider3,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinName: "Coinbase",
      coinDate: "Mon . Mar 27, 2023",
    },
    {
      id: 7,
      img: aboutSlider1,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinName: "Coinbase",
      coinDate: "Wed . Mar 29, 2023",
    },
    {
      id: 8,
      img: aboutSlider2,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinName: "New York Times",
      coinDate: "Mon . Mar 27, 2023",
    },
    {
      id: 9,
      img: aboutSlider3,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinName: "Coinbase",
      coinDate: "Wed . Mar 29, 2023",
    }, {
      id: 10,
      img: aboutSlider1,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinName: "New York Times",
      coinDate: "Mon . Mar 27, 2023",
    }
  ],
  about_our_action_accordion: [
    {
      id: 1,
      title: "Integrity, fairness and responsibility",
      subTextOpen: "open",
      description: "Unidef funds entrepreneurs and organizations to create incentives that harness the power of private unidef enterprises to create advancement in the industry aligned with the mission of Unidef. Unidef funds entrepreneurs and organizations to create incentives that harness the power of private unidef enterprises to create advancement in the industry aligned with the mission of Unidef."
    },
    {
      id: 2,
      title: "Success in unity",
      subTextOpen: "open",
      subTextClose: "close",
      description: "Unidef funds entrepreneurs and organizations to create incentives that harness the power of private unidef enterprises to create advancement in the industry aligned with the mission of Unidef. Unidef funds entrepreneurs and organizations to create incentives that harness the power of private unidef enterprises to create advancement in the industry aligned with the mission of Unidef."
    },
    {
      id: 3,
      title: "Bring good vibes",
      description: "Unidef funds entrepreneurs and organizations to create incentives that harness the power of private unidef enterprises to create advancement in the industry aligned with the mission of Unidef. Unidef funds entrepreneurs and organizations to create incentives that harness the power of private unidef enterprises to create advancement in the industry aligned with the mission of Unidef."
    },
    {
      id: 4,
      title: "Exceptional Excellence ",
      description: "Unidef funds entrepreneurs and organizations to create incentives that harness the power of private unidef enterprises to create advancement in the industry aligned with the mission of Unidef. Unidef funds entrepreneurs and organizations to create incentives that harness the power of private unidef enterprises to create advancement in the industry aligned with the mission of Unidef."
    },
    {
      id: 5,
      title: "Integrity, fairness and responsibility",
      description: "Unidef funds entrepreneurs and organizations to create incentives that harness the power of private unidef enterprises to create advancement in the industry aligned with the mission of Unidef. Unidef funds entrepreneurs and organizations to create incentives that harness the power of private unidef enterprises to create advancement in the industry aligned with the mission of Unidef."
    },
  ],
  location_data: [
    {
      id: 1,
      locatiomImg: Loc1,
      cityName: "New York, ",
      countryName: "USA",
      address: "Charlemont Exchange, Charlemont St, SaintKevin's, Dublin, D02 VN88",
      mailAddress: "ie.office@ag.email",
      mailUrl: "emailto:tr.office@unidef.org",
      getDirectionUrl: ""
    },
    {
      id: 2,
      locatiomImg: Loc2,
      cityName: "Istanbul, ",
      countryName: "TURKEY",
      address: "Maslak Meydan Sk. No:5, Spring Plaza, Sarıyer, İstanbul 34398, Turkey",
      mailAddress: "tr.office@unidef.org",
      mailUrl: "emailto:tr.office@unidef.org",
      getDirectionUrl: "https://www.google.com/maps/place/Spring+Giz+Plaza/@41.1096949,29.0220665,15z/data=!4m5!3m4!1s0x0:0x34dce4557cd7bea0!8m2!3d41.1096949!4d29.022066"
    },
    {
      id: 3,
      locatiomImg: Loc3,
      cityName: "Dublin, ",
      countryName: "UAE",
      address: "Charlemont Exchange, Charlemont St, SaintKevin's, Dublin, D02 VN88",
      mailAddress: "ie.office@ag.email",
      mailUrl: "emailto:tr.office@unidef.org",
      getDirectionUrl: ""
    },
    {
      id: 4,
      locatiomImg: Loc4,
      cityName: "London, ",
      countryName: "UK",
      address: "Charlemont Exchange, Charlemont St, SaintKevin's, Dublin, D02 VN88",
      mailAddress: "ie.office@ag.email",
      mailUrl: "emailto:tr.office@unidef.org",
      getDirectionUrl: ""
    },
    {
      id: 5,
      locatiomImg: Loc5,
      cityName: "Dubai, ",
      countryName: "UAE",
      address: "Charlemont Exchange, Charlemont St, SaintKevin's, Dublin, D02 VN88",
      mailAddress: "ie.office@ag.email",
      mailUrl: "emailto:tr.office@unidef.org",
      getDirectionUrl: ""
    },
    {
      id: 6,
      locatiomImg: Loc6,
      cityName: "New Delhi, ",
      countryName: "INDIA",
      address: "Charlemont Exchange, Charlemont St, SaintKevin's, Dublin, D02 VN88",
      mailAddress: "ie.office@ag.email",
      mailUrl: "emailto:tr.office@unidef.org",
      getDirectionUrl: ""
    },
    {
      id: 7,
      locatiomImg: Loc7,
      cityName: "Dublin, ",
      countryName: "IRELAND",
      address: "Charlemont Exchange, Charlemont St, Saint Kevin's, Dublin, D02 VN88",
      mailAddress: "ie.office@ag.email",
      mailUrl: "emailto:tr.office@unidef.org",
      getDirectionUrl: "https://www.google.com/maps/dir//Charlemont+Exchange,+Charlemont+St,+Saint+Kevin's,+Dublin+2,+Ireland/@53.3310642,-6.2605533,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x48670ea1108c22bb:0x48147e2055503eab!2m2!1d-6.2605533!2d53.3310642"
    },
    {
      id: 8,
      locatiomImg: Loc8,
      cityName: "Hong Kong, ",
      countryName: "CHINA",
      address: "33 Wyndham Street G/F LKF Tower, Hong Kong",
      mailAddress: "hk.office@unidef.org",
      mailUrl: "emailto:hk.office@unidef.org",
      getDirectionUrl: "https://www.google.com/maps/dir//33+Wyndham+Street+G%2FF+LKF+Tower,+Hong+Kong/@22.2804247,114.120125,13z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x3404007b2800ab8d:0x3eeac1b2f5808f92!2m2!1d114.1552304!2d22.280349"
    },
  ],
  location_block_data_mobile: [
    {
      id: 1,
      img: LocationNew1,
      flagImage: USA,
      cityName: "Hong Kong, ",
      countryName: "CHINA",
      address: "33 Wyndham Street G/F LKF Tower, Hong Kong",
      mailAddress: "hk.office@unidef.org",
      mailUrl: "emailto:hk.office@unidef.org",
    },
    {
      id: 2,
      img: LocationNew2,
      flagImage: Turkey,
      cityName: "Istanbul, ",
      countryName: "TURKEY",
      address: "Maslak Meydan Sk. No:5, Spring Plaza, Sarıyer, İstanbul 34398, Turkey",
      mailAddress: "tr.office@unidef.org",
      mailUrl: "emailto:tr.office@unidef.org",
    },
    {
      id: 3,
      img: LocationNew3,
      flagImage: UAE,
      cityName: "Dublin, ",
      countryName: "UAE",
      address: "Charlemont Exchange, Charlemont St, SaintKevin's, Dublin, D02 VN88",
      mailAddress: "ie.office@ag.email",
      mailUrl: "emailto:tr.office@unidef.org",
    },
    {
      id: 4,
      img: LocationNew4,
      flagImage: UK,
      cityName: "London, ",
      countryName: "UK",
      address: "Charlemont Exchange, Charlemont St, SaintKevin's, Dublin, D02 VN88",
      mailAddress: "ie.office@ag.email",
      mailUrl: "emailto:tr.office@unidef.org",
    },
    {
      id: 5,
      img: LocationNew5,
      flagImage: UAE,
      cityName: "Dubai, ",
      countryName: "UAE",
      address: "Charlemont Exchange, Charlemont St, SaintKevin's, Dublin, D02 VN88",
      mailAddress: "ie.office@ag.email",
      mailUrl: "emailto:tr.office@unidef.org",
    },
    {
      id: 6,
      img: LocationNew6,
      flagImage: IndiaFlag,
      cityName: "New Delhi, ",
      countryName: "INDIA",
      address: "Charlemont Exchange, Charlemont St, SaintKevin's, Dublin, D02 VN88",
      mailAddress: "ie.office@ag.email",
      mailUrl: "emailto:tr.office@unidef.org",
    },
    {
      id: 7,
      img: LocationNew7,
      flagImage: DublinFlag,
      cityName: "Dublin, ",
      countryName: "IRELAND",
      address: "Charlemont Exchange, Charlemont St, Saint Kevin's, Dublin, D02 VN88",
      mailAddress: "ie.office@ag.email",
      mailUrl: "emailto:tr.office@unidef.org",
    },
    {
      id: 8,
      img: LocationNew8,
      flagImage: Chine,
      cityName: "Hong Kong, ",
      countryName: "CHINA",
      address: "33 Wyndham Street G/F LKF Tower, Hong Kong",
      mailAddress: "hk.office@unidef.org",
      mailUrl: "emailto:hk.office@unidef.org",
    },
  ],
  about_leadership_slider_data: [
    {
      id: 1,
      title: "Integrity,",
      subTitle: "fairness and responsibility",
    },
    {
      id: 2,
      title: "Integrity,",
      subTitle: "fairness and responsibility",
    },
    {
      id: 3,
      title: "Integrity,",
      subTitle: "fairness and responsibility",
    }
  ],
  //Manager page Data
  manager: "Manager",
  read_more: "Read more",
  // read_less:"Read less",
  manager_s1_readmore_text: "A cross-border strategic capital investor that leverages resources across Unidef and its global partners to support projects in the Crypto, Web3 and Metaverse space.It provides a variety of services for you to learn, so thriveand also have fun! Free Tutorials, Millions of Articles, Live,Online and Classroom Courses ,Frequent Coding Competitions,Webinars by Industry Experts, Internship opportunities, and Job Opportunities. Knowledge is power!",
  how_it_strated: "How it strated?",
  our_story: "Our Story",
  open_letter: "Open Letter",
  insights: "Insights",
  title_about_articles: "Title about articles",
  your_questions: "Your questions",
  about_unidef_answered: "about Unidef, answered.",
  download_image: "Download Image",
  manager_s7_content: 'Usage Restrictions: Images may only be used to illustrate articles about Unidef.',
  load_more_articles: "Load more articles",
  contact_manager: "Contact Manager",
  manager_s7_m_txt: "Unidef is the global network of organizations working together.",
  manareg_our_story: [
    {
      id: 1,
      description: "Founded in 2022, Unidef iLabs is the investment arm of Unidef that identifies, invests in, and empowers blockchain entrepreneurs, startups, and communities",
    },
    {
      id: 2,
      description: "A cross-border strategic capital investor that leverages resources across Unidef and its global partners to support projects in the Crypto, Web3 and Metaverse space. Unidef iLabs brings startups and projects closer to the resources they need to scale globally.",
    },
    {
      id: 3,
      description: "Founded in 2022, Unidef iLabs is the investment arm of Unidef that identifies, invests in, and empowers blockchain entrepreneurs, startups, and communities.",
    },
    {
      id: 4,
      description: "A cross-border strategic capital investor that leverages resources across Unidef and its global partners to support projects in the Crypto, Web3 and Metaverse space. Unidef iLabs brings startups and projects closer to the resources they need to scale globally.",
    }
  ],
  manager_open_letter: [
    {
      id: 1,
      description: [
        {
          id: 1,
          subDescription: "Founded in 2022, Unidef iLabs is the investment arm of Unidef that identifies, invests in, and empowers blockchain entrepreneurs, startups, and communities."
        },
        {
          id: 2,
          subDescription: "A cross-border strategic capital investor that leverages resources across Unidef and its global partners to support projects in the Crypto, Web3 and Metaverse space. Unidef iLabs brings startups and projects closer to the resources they need to scale globally."
        },
        {
          id: 3,
          subDescription: "Founded in 2022, Unidef iLabs is the investment arm of Unidef that identifies, invests in, and empowers blockchain entrepreneurs, startups, and communities."
        },
      ]
    },
    {
      id: 2,
      description: [
        {
          id: 1,
          subDescription: "A cross-border strategic capital investor that leverages resources across Unidef and its global partners to support projects in the Crypto, Web3 and Metaverse space. Unidef iLabs brings startups and projects closer to the resources they need to scale globally."
        },
        {
          id: 2,
          subDescription: "Founded in 2022, Unidef iLabs is the investment arm of Unidef that identifies, invests in, and empowers blockchain entrepreneurs, startups, and communities."
        },
        {
          id: 3,
          subDescription: "A cross-border strategic capital investor that leverages resources across Unidef and its global partners to support projects in the Crypto, Web3 and Metaverse space. Unidef iLabs brings startups and projects closer to the resources they need to scale globally."
        },
      ]
    },
    {
      id: 3,
      description: [
        {
          id: 1,
          subDescription: "Founded in 2022, Unidef iLabs is the investment arm of Unidef that identifies, invests in, and empowers blockchain entrepreneurs, startups, and communities."
        },
        {
          id: 2,
          subDescription: "A cross-border strategic capital investor that leverages resources across Unidef and its global partners to support projects in the Crypto, Web3 and Metaverse space. Unidef iLabs brings startups and projects closer to the resources they need to scale globally."
        },
      ]
    }
  ],
  manager_s6_accordion_data: [
    {
      id: 1,
      title: "What is Unidef?",
      description: "Unidef is a global network of organizations working together to accelerate the world's transition to a decentralized future.",
    },
    {
      id: 2,
      title: "What is U?",
      description: "Unidef Way is a global network of local and global nonprofit fundraising and charity affiliates working together to fight poverty, disease, and inequity around the world.",
    },
    {
      id: 3,
      title: "Where can I get Unidef?",
      description: "Unidef Decemount (commonly known as Decemount) is a network of DAOs (Decentralized Autonomous Organizations) working together to empower the next generation of organizations with Unidef - governed      initiatives.",
    },
    {
      id: 4,
      title: "What Is Decemount?",
      description: "Unidef Decemount (commonly known as Decemount) is made up of DAOs (Decentralized Autonomous Organizations). They team together to empower the next generation of organizations with Unidef-governed initiatives. This network is designed for those who are building the decentralized future.",
    },
    {
      id: 5,
      title: "Contact Manager",
      BoldTitle: "Find more about unidef",
      home_social_data: [
        {
          id: 1,
          imgHover: TwitterHomeColored,
          imgOut: TwitterHome,
          url: "https://twitter.com/intent/follow?screen_name=unidefglobal",
        },
        {
          id: 2,
          imgHover: TeligramHomeColored,
          imgOut: TeligramHome,
          url: "https://t.me/UnidefGlobal",
        },
        {
          id: 3,
          imgHover: InstagramHomeColored,
          imgOut: InstagramHome,
          url: "https://www.instagram.com/unidefglobal/",
        },
        {
          id: 4,
          imgHover: FacebookHomeColored,
          imgOut: FacebookHome,
          url: "https://www.facebook.com/UnidefGlobal/",
        },
        {
          id: 5,
          imgHover: LinkdinHomeColored,
          imgOut: LinkdinHome,
          url: "https://www.facebook.com/UnidefGlobal/",
        },
      ]
    },
  ],
  download_button: [
    {
      id: 1,
      title: "Original",
      url: OrigalFile,
      fileName: "Original.txt"
    },
    {
      id: 2,
      title: "HD (1621 x 1080px)",
      url: HDFile,
      fileName: "HD.txt"
    },
    {
      id: 3,
      title: "4K (3241 x 2160px)",
      url: K4File,
      fileName: "4K.txt"
    },
    {
      id: 4,
      title: "Web (800 x 533px)",
      url: WebFile,
      fileName: "Web.txt"
    },
  ],
  loardmore_artcle_data: [
    {
      id: 1,
      img: Article1,
      title: "20 definitions of the metaverse",
      btnTitle: "finance",
      description: "Inspired by Jaron Lanier who created 52 definitions for Virtual Reality more than 30 years ago, this list explores the indefinite aspect of this concept."
    },
    {
      id: 2,
      img: Article2,
      title: "Discord: eldorado for communities?",
      btnTitle: "Interview",
      description: "For CB-News, we analyzed how Discord became the first platform for communities and how it can help brands reach their consumers in a new way."
    },
    {
      id: 3,
      img: Article3,
      title: "Do brands should enter the Metaverse?",
      btnTitle: "Article",
      description: "We’ve been invited by the French media La Réclame to talk about the Metaverse and why brands should enter this new world."
    },
    // {
    //   id: 4,
    //   img: Article4,
    //   title: "20 definitions of the metaverse",
    //   btnTitle: "finance",
    //   description: "Inspired by Jaron Lanier who created 52 definitions for Virtual Reality more than 30 years ago, this list explores the indefinite aspect of this concept."
    // },
    // {
    //   id: 5,
    //   img: Article5,
    //   title: "Discord: eldorado for communities?",
    //   btnTitle: "Interview",
    //   description: "For CB-News, we analyzed how Discord became the first platform for communities and how it can help brands reach their consumers in a new way."
    // },
    // {
    //   id: 6,
    //   img: Article6,
    //   title: "Do brands should enter the Metaverse?",
    //   btnTitle: "Article",
    //   description: "We’ve been invited by the French media La Réclame to talk about the Metaverse and why brands should enter this new world."
    // },
    // {
    //   id: 7,
    //   img: Article7,
    //   title: "20 definitions of the metaverse",
    //   btnTitle: "finance",
    //   description: "We’ve been invited by the French media La Réclame to talk about the Metaverse and why brands should enter this new world."
    // },
  ],
  contact_manager_social_data: [
    {
      id: 1,
      social_contact: [
        {
          id: 1,
          socialImage: twitterIconMobile,
          title: "Twitter",
          socialLink: "https://twitter.com/UnidefGlobal",
        },
        {
          id: 2,
          socialImage: instagramIconMobile,
          title: "Instagram",
          socialLink: "https://www.instagram.com/unidefglobal/",
        },
        {
          id: 3,
          socialImage: discordIconMobile,
          title: "Discord",
          socialLink: "",
        },
        {
          id: 4,
          socialImage: telegramIconMobile,
          title: "Telegram",
          socialLink: "https://t.me/UnidefGlobal",
        },
      ],
    },
    {
      id: 2,
      social_contact: [
        {
          id: 1,
          socialImage: instagramIconMobile,
          title: "Instagram",
          socialLink: "https://www.instagram.com/unidefglobal/",
        },
        {
          id: 2,
          socialImage: twitterIconMobile,
          title: "Twitter",
          socialLink: "https://twitter.com/UnidefGlobal",
        },
        {
          id: 3,
          socialImage: telegramIconMobile,
          title: "Telegram",
          socialLink: "https://t.me/UnidefGlobal",
        },
        {
          id: 4,
          socialImage: facebookIconMobile,
          title: "Facebook",
          socialLink: "https://www.facebook.com/UnidefGlobal/",
        },
      ],
    },
    {
      id: 3,
      social_contact: [
        {
          id: 1,
          socialImage: telegramIconMobile,
          title: "Telegram",
          socialLink: "https://t.me/UnidefGlobal",
        },
        {
          id: 2,
          socialImage: facebookIconMobile,
          title: "Facebook",
          socialLink: "https://www.facebook.com/UnidefGlobal/",
        },
        {
          id: 3,
          socialImage: twitterIconMobile,
          title: "Twitter",
          socialLink: "https://twitter.com/UnidefGlobal",
        },
        {
          id: 4,
          socialImage: instagramIconMobile,
          title: "Instagram",
          socialLink: "https://www.instagram.com/unidefglobal/",
        },
      ],
    }
  ],
  ///NewIlabs page Data
  ilabs: "Ilabs",
  ilabs_s1_title1: "Empowering",
  ilabs_s1_title2: "A Decentralized Future",
  // ilabs_s1_subtitle: "Unidef iLabs invests in exceptional startups and founders who share Unidef’s mission of accelerating the world’s transition to decentralization.",
  ilabs_s2_txt1: "Founded in 2022, Unidef iLabs is the investment arm of Unidef that identifies, invests in, and empowers blockchain entrepreneurs, startups, projects and communities. It is a cross-border strategic investor that leverages resources across Unidef and its global partners to support projects in the AI, Crypto, Web3, and Metaverse spaces.",
  ilabs_s2_txt2: "We are actively looking for scalable and resilient projects that are crucial to the growth of Web3. We partner with founding teams at the earliest stages and invest broadly across all categories within the decentralized economy.",
  ilabs_s2_txt3: "In collaboration with Unidef and its members, we will initiate incubation programs to help nurture viable ideas and invest in projects that can expand cryptocurrency use cases and drive adoption for Web3 and blockchain technologies. For inquiries related to investments in decentralized assets and cryptocurrencies, please visit",
  investment_focus: "Investment Focus",
  ilabs_s4_subtitle: "Unidef iLabs supports the full startup lifecycle from early-stage idea incubation to late-stage venture capital investment. We welcome ventures at any stage and valuation.",
  //ilabs Screen
  ilabs_new_s7_title: "You Asked, We Answered",
  lets_apply1: "Let’s Apply!",
  lets_sub_title1: "If you're an entrepreneur building the new financial economy, reach out!",
  lets_sub_title2: "We invest from pre- seed to product-market fit.",
  s5_inner_content_mobile: "Founded in 2022, Unidef iLabs is the investment arm of Unidef that identifies, invests in, and empowers blockchain entrepreneurs and startups.",
  s5_person_position_mobile: "Senior Vice President",
  apply: "Apply",
  //Agunium Page
  agunium: "Agunium (/əˈɡjuːniəm/)",
  empowering: "Empowering",
  the_decentralized: "the Decentralized",
  web: "Web",
  agunium_s1_subtitle_mobile: "Empower the world to own its finances, data, and identity – bringing it closer to a decentralized future.",
  our_portfolio: "Our Portfolio",
  post_invesment_support: "Post Investment Support",
  lets_sub_title11: "If you're an entrepreneur building the new financial",
  lets_sub_title22: "economy, reach out! We invest from pre-seed to",
  lets_sub_title33: "product-market fit.",
  lets_sub_title_mobile: "If you're an entrepreneur building the new financial economy, reach out! We invest from pre-seed to product-market fit.",
  invested_year: "First Investment:",
  social_medias: "Social Medias:",
  view_full_portfolio: "View full portfolio",
  post_invesment: "Post Investment ",
  support: "Support",
  option_status: [
    {
      id: 1,
      name: "All",
      eventKey: "All"
    },
    {
      id: 2,
      name: "Active",
      eventKey: "Active"
    },
    {
      id: 3,
      name: "Acquired",
      eventKey: "Acquired"
    },
    {
      id: 4,
      name: "Public",
      eventKey: "Public"
    },
    {
      id: 5,
      name: "Exited",
      eventKey: "Exited"
    },
    {
      id: 6,
      name: "Inactive",
      eventKey: "Inactive"
    },
  ],
  option_year: [
    {
      id: 1,
      name: "All",
      eventKey: "All"
    },
    {
      id: 2,
      name: "2016",
      eventKey: "2016"
    },
    {
      id: 3,
      name: "2017",
      eventKey: "2017"
    },
    {
      id: 4,
      name: "2018",
      eventKey: "2018"
    },
    {
      id: 5,
      name: "2019",
      eventKey: "2019"
    },
    {
      id: 6,
      name: "2020",
      eventKey: "2020"
    },
    {
      id: 7,
      name: "2021",
      eventKey: "2021"
    },
    {
      id: 8,
      name: "2022",
      eventKey: "2022"
    },
  ],
  agunium_portfolio_data: [
    {
      id: 1,
      Name: 'Bitcoin (BTC)',
      image: Bitcoin,
      description: "Bitcoin is a decentralized cryptocurrency originally described in a 2008 whitepaper by a person, or group of people, using the alias Satoshi Nakamoto.",
      invYear: "2015",
      visitUrl: "https://bitcoin.org/en/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/Bitcoin",
        },
      ]
    },
    {
      id: 2,
      Name: 'Ethereum (ETH)',
      image: Ethereum,
      description: "Ethereum is a decentralized open-source blockchain system that features its own cryptocurrency, Ether. ETH works as a platform for numerous other cryptocurrencies, as well as for the execution of decentralized smart contracts.",
      invYear: "2016",
      visitUrl: "https://ethereum.org/en/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/ethereum",
        },
      ]
    },
    {
      id: 3,
      Name: 'BNB Chain (BNB)',
      image: BNB,
      description: "Binance is the biggest cryptocurrency exchange globally based on daily trading volume.",
      invYear: "2017",
      visitUrl: "https://bnbchain.org/en",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/BNBCHAIN?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
        },
        {
          id: 2,
          imgHover: TeligramColoredAgunium,
          imgOut: TeligramAgunium,
          url: "https://t.me/bnbchain",
        },
        {
          id: 3,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://www.linkedin.com/company/bnbchaininnovation/",
        },
      ]
    },
    {
      id: 4,
      Name: 'XRP Ledger (XRP)',
      image: XRPLedger,
      description: "XRP Ledger (XRPL) is the open-source distributed ledger that is created by Ripple. The native cryptocurrency of the XRP Ledger is XRP.",
      invYear: "2017",
      visitUrl: "https://xrpl.org/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/Ripple ",
        },
        {
          id: 2,
          imgHover: TeligramColoredAgunium,
          imgOut: TeligramAgunium,
          url: "https://t.me/Ripple",
        },
      ]
    },
    {
      id: 5,
      Name: 'Dogecoin (DOGE)',
      image: Dogecoin,
      description: "Dogecoin (DOGE) is based on the popular \"doge\" Internet meme and features a Shiba Inu on its logo. Tesla CEO Elon Musk posted several tweets on social media that Dogecoin is his favorite coin.",
      invYear: "2016",
      visitUrl: "https://dogecoin.com/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/dogecoin",
        },
        {
          id: 2,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://www.linkedin.com/company/dogecoin",
        },
      ]
    },
    {
      id: 6,
      Name: 'Tron (TRX)',
      image: Tron,
      description: "TRON (TRX) is a decentralized blockchain-based operating system developed by the Tron Foundation and launched in 2017.",
      invYear: "2017",
      visitUrl: "https://tron.network/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/trondao?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
        },
        {
          id: 2,
          imgHover: TeligramColoredAgunium,
          imgOut: TeligramAgunium,
          url: "https://t.me/tronnetworkEN",
        },
        {
          id: 3,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://www.linkedin.com/company/trondao/",
        },
      ]
    },
    {
      id: 7,
      Name: 'Polygon ( MATIC)',
      image: Polygon,
      description: "Polygon (previously Matic Network) is the first well-structured, easy-to-use platform for Ethereum scaling and infrastructure development.",
      invYear: "2019",
      visitUrl: "https://polygon.technology/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/0xPolygon?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
        },
        {
          id: 2,
          imgHover: TeligramColoredAgunium,
          imgOut: TeligramAgunium,
          url: "https://t.me/polygonofficial",
        },
        {
          id: 3,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://www.linkedin.com/company/polygonlabs/",
        },
      ]
    },
    {
      id: 8,
      Name: 'Uniswap (UNI)',
      image: Uniswap,
      description: "Uniswap is a popular decentralized trading protocol, known for its role in facilitating automated trading of decentralized finance (DeFi) tokens.",
      invYear: "2020",
      visitUrl: "https://uniswap.org/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/Uniswap?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
        },
        {
          id: 2,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://www.linkedin.com/company/uniswaporg/jobs/",
        },
      ]
    },
    {
      id: 9,
      Name: 'OKB (OKB)',
      image: OKB,
      description: "OKB is a cryptocurrency released by the OK Blockchain Foundation and Maltese crypto exchange, OKEx.",
      invYear: "2019",
      visitUrl: "https://www.okx.com/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/okx",
        },
        {
          id: 2,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://www.linkedin.com/company/okxofficial/",
        },
      ]
    },
    {
      id: 10,
      Name: 'The Graph (GRT)',
      image: TheGraph,
      description: "The Graph is an indexing protocol for querying data for networks like Ethereum and IPFS, powering many applications in both DeFi and the broader Web3 ecosystem. ",
      invYear: "2022",
      visitUrl: "https://thegraph.com/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/graphprotocol?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
        },
        {
          id: 2,
          imgHover: TeligramColoredAgunium,
          imgOut: TeligramAgunium,
          url: "http://t.me/graphprotocol",
        },
        {
          id: 3,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://www.linkedin.com/company/thegraph",
        },
      ]
    },
    {
      id: 11,
      Name: 'Aave (AAVE)',
      image: Aave,
      description: "Aave is a decentralized finance protocol that allows people to lend and borrow crypto.",
      invYear: "2018",
      visitUrl: "https://aave.com/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/aaveaave",
        },
        {
          id: 2,
          imgHover: TeligramColoredAgunium,
          imgOut: TeligramAgunium,
          url: "https://t.me/Aavesome",
        },
        {
          id: 3,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://uk.linkedin.com/company/aaveaave",
        },
      ]
    },
    {
      id: 12,
      Name: 'Algorand (ALGO)',
      image: Algorand,
      description: "Algorand is a self-sustaining, decentralized, blockchain-based network that supports a wide range of applications.",
      invYear: "2019",
      visitUrl: "https://algorand.com/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/Algorand?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
        },
        {
          id: 2,
          imgHover: TeligramColoredAgunium,
          imgOut: TeligramAgunium,
          url: "https://t.me/algorand",
        },
        {
          id: 3,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://www.linkedin.com/company/algorand",
        },
      ]
    },
    {
      id: 13,
      Name: 'Fantom (FTM)',
      image: Fantom,
      description: "Fantom is a directed acyclic graph (DAG) smart contract platform providing decentralized finance (DeFi) services to developers using its own bespoke consensus algorithm.",
      invYear: "2019",
      visitUrl: "https://fantom.foundation/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/FantomFDN?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
        },
        {
          id: 2,
          imgHover: TeligramColoredAgunium,
          imgOut: TeligramAgunium,
          url: "https://t.me/Fantom_English",
        },
        {
          id: 3,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://www.linkedin.com/company/fantom-foundation/",
        },
      ]
    },
    {
      id: 14,
      Name: 'Decentraland (MANA)',
      image: Decentraland,
      description: "Decentraland (MANA) defines itself as a virtual reality platform powered by the Ethereum blockchain that allows users to create, experience, and monetize content and applications.",
      invYear: "2018",
      visitUrl: "https://decentraland.org/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/decentraland?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
        },
        {
          id: 2,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://ky.linkedin.com/company/decentralandorg",
        },
      ]
    },
    {
      id: 15,
      Name: 'Pepe (PEPE)',
      image: Pepe,
      description: "PEPE is a memecoin launched on Ethereum. The cryptocurrency was created as a tribute to the Pepe the Frog internet meme, created by Matt Furie, which gained popularity in the early 2000s.",
      invYear: "2023",
      visitUrl: "https://www.pepe.vip/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/pepecoineth",
        },
        {
          id: 2,
          imgHover: TeligramColoredAgunium,
          imgOut: TeligramAgunium,
          url: "https://t.me/pepecoineth",
        },
      ]
    },
    {
      id: 16,
      Name: 'NEO (NEO)',
      image: Neo,
      description: "Neo bills itself as a “rapidly growing and developing” ecosystem that has the goal of becoming the foundation for the next generation of the internet — a new economy where digitized payments, identities and assets come together.",
      invYear: "2017",
      visitUrl: "https://neo.org/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/neo_blockchain",
        },
        {
          id: 2,
          imgHover: TeligramColoredAgunium,
          imgOut: TeligramAgunium,
          url: "https://t.me/NEO_EN",
        },
        {
          id: 3,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://www.linkedin.com/company/neo-blockchain",
        },
      ]
    },
    {
      id: 17,
      Name: 'KuCoin (KCS)',
      image: KuCoin,
      description: "KCS is the native token of KuCoin.",
      invYear: "2017",
      visitUrl: "https://www.kucoin.com/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/kucoincom?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
        },
        {
          id: 2,
          imgHover: TeligramColoredAgunium,
          imgOut: TeligramAgunium,
          url: "https://t.me/KCCOfficialEnglishCommunity",
        },
        {
          id: 3,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://sc.linkedin.com/company/kucoin",
        },
      ]
    },
    {
      id: 18,
      Name: 'Chiliz (CHZ)',
      image: Chiliz,
      description: "Chiliz is the leading digital currency for sports and entertainment by the eponymous Malta-based FinTech provider.",
      invYear: "2019",
      visitUrl: "https://www.chiliz.com/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/Chiliz?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
        },
        {
          id: 2,
          imgHover: TeligramColoredAgunium,
          imgOut: TeligramAgunium,
          url: "https://t.me/chiliz_io",
        },
        {
          id: 3,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://www.linkedin.com/company/chilizdotcom",
        },
      ]
    },
    {
      id: 19,
      Name: 'IOTA (MIOTA)',
      image: IOTA,
      description: "IOTA is a distributed ledger with one big difference: it isn’t actually a blockchain.",
      invYear: "2017",
      visitUrl: "https://www.iota.org/ ",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/iota?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
        },
        {
          id: 2,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://de.linkedin.com/company/iotafoundation",
        },
      ]
    },
    {
      id: 20,
      Name: 'Huobi (HT)',
      image: Huobi,
      description: "Huobi is a centralized cryptocurrency exchange established in 2013 and is registered in Seychelles.",
      invYear: "2018",
      visitUrl: "https://www.huobi.com/en-us/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/HuobiGlobal?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
        },
        {
          id: 2,
          imgHover: TeligramColoredAgunium,
          imgOut: TeligramAgunium,
          url: "https://t.me/huobiglobalofficial",
        },
        {
          id: 3,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://sc.linkedin.com/company/huobi",
        },
      ]
    },
    {
      id: 21,
      Name: 'Trust Wallet (TWT)',
      image: TrustWalletCoin,
      description: "Trust Wallet Token, or TWT, is a simple BEP-20 utility token that provides a range of benefits and incentives to Trust Wallet users.",
      invYear: "2023",
      visitUrl: "https://trustwallet.com/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/trustwalletapp?lang=en",
        },
        {
          id: 2,
          imgHover: TeligramColoredAgunium,
          imgOut: TeligramAgunium,
          url: "https://t.me/trust_announcements",
        },
        {
          id: 3,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://www.linkedin.com/company/trustwallet",
        },
      ]
    },
    {
      id: 22,
      Name: 'PancakeSwap (CAKE)',
      image: PancakeSwapCoin,
      description: "PancakeSwap is an automated market maker (AMM) — a decentralized finance (DeFi) application that allows users to exchange tokens.",
      invYear: "2020",
      visitUrl: "https://pancakeswap.finance/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/PancakeSwap?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
        },
        {
          id: 2,
          imgHover: TeligramColoredAgunium,
          imgOut: TeligramAgunium,
          url: "https://t.me/PancakeSwap",
        },
        {
          id: 3,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://www.linkedin.com/company/pancakeswap",
        },
      ]
    },
    {
      id: 23,
      Name: 'Loopring (LRC)',
      image: Loopring,
      description: "LRC is the Ethereum-based cryptocurrency token of Loopring, an open protocol designed for the building of decentralized crypto exchanges.",
      invYear: "2017",
      visitUrl: "https://loopring.org/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/loopringorg",
        },
        {
          id: 2,
          imgHover: TeligramColoredAgunium,
          imgOut: TeligramAgunium,
          url: "https://t.me/loopring_en",
        },
        {
          id: 3,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://www.linkedin.com/company/loopring",
        },
      ]
    },
    {
      id: 24,
      Name: 'Waves (WAVES)',
      image: Waves,
      description: "Waves is a multi-purpose blockchain platform which supports various use cases including decentralized applications (DApps) and smart contracts.",
      invYear: "2017",
      visitUrl: "https://waves.tech/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/wavesprotocol",
        },
        {
          id: 2,
          imgHover: TeligramColoredAgunium,
          imgOut: TeligramAgunium,
          url: "https://t.me/Wavescommunity",
        },
        {
          id: 3,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://ca.linkedin.com/company/wwwaves",
        },
      ]
    },
    {
      id: 25,
      Name: 'Harmony ONE (ONE)',
      image: HarmonyONE,
      description: "Harmony is a blockchain platform designed to facilitate the creation and use of decentralized applications (DApps).",
      invYear: "2019",
      visitUrl: "https://www.harmony.one/ ",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/harmonyprotocol?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
        },
        {
          id: 2,
          imgHover: TeligramColoredAgunium,
          imgOut: TeligramAgunium,
          url: "https://t.me/harmony_one",
        },
        {
          id: 3,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://www.linkedin.com/company/harmony-one",
        },
      ]
    },
    {
      id: 26,
      Name: 'Verge (XVG)',
      image: Verge,
      description: "Verge is a privacy-focused cryptocurrency and blockchain that seeks to offer a fast, efficient, decentralized payments network that improves upon the original Bitcoin (BTC) blockchain.",
      invYear: "2016",
      visitUrl: "https://vergecurrency.com/",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/vergecurrency",
        },
        {
          id: 2,
          imgHover: TeligramColoredAgunium,
          imgOut: TeligramAgunium,
          url: "https://t.me/VERGExvg",
        },
        {
          id: 3,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://www.linkedin.com/company/verge-currency/",
        },
      ]
    },
    {
      id: 27,
      Name: 'Bitmart (BMX)',
      image: Bitmart,
      description: "BitMart is a centralized cryptocurrency exchange established in 2017 and is registered in Cayman Islands.",
      invYear: "2018",
      visitUrl: "https://www.bitmart.com/en-US",
      agunium_portfolio_social_data: [
        {
          id: 1,
          imgHover: TwitterColoredAgunium,
          imgOut: TwitterAgunium,
          url: "https://twitter.com/BitMartExchange?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
        },
        {
          id: 2,
          imgHover: TeligramColoredAgunium,
          imgOut: TeligramAgunium,
          url: "https://t.me/BitmartExchange",
        },
        {
          id: 3,
          imgHover: linkdinColoredAgunium,
          imgOut: linkdinAgunium,
          url: "https://www.linkedin.com/company/bitmart",
        },
      ]
    },
  ],
  status: "Status: ",
  year: "Year: ",
  //nexen page data aaaaa
  fundraising: "Fundraising ",
  anperon: "&",
  community_for: "community for",
  crypto: "crypto ",
  projects: "projects.",
  successfully_attract: "Successfully attract ",
  paying: "paying",
  customers: "customers.",
  create: "Create",
  software_that: "software that makes profit.",
  get_proposal: "Get Proposal",
  nexensu: "Nexensu",
  navigation_link_nexensu_page: [
    {
      id: 1,
      name: "About",
      to: routePaths.nexensuScreen,
      eventKey: "about",
    },
    {
      id: 2,
      name: "Our Networks",
      to: routePaths.homeScreenNew,
      eventKey: "ourNetworks"
    },
    {
      id: 3,
      name: "Our Actions",
      to: routePaths.homeScreenNew,
      eventKey: "ourActions"
    },
    {
      id: 4,
      name: "Careers",
      to: routePaths.nexensuScreen,
      eventKey: "careers"
    },
    {
      id: 5,
      name: "Contact",
      to: routePaths.nexensuScreen,
      eventKey: "contact"
    },
  ],
  position_count: [
    {
      id: 1,
      number: "40 +",
      title: "Nexensu Members",
    },
    {
      id: 2,
      number: "1.3K +",
      title: "Job Positions",
    },
    {
      id: 3,
      number: "200 +",
      title: "Weekly Interviews",
    }
  ],
  //Nexensu page
  our_services: "Our Services",
  find_us_in: "Find us in",
  nexensu_coin_slider_data: [
    {
      id: 1,
      img: nexenSlider1,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinDate1: "Wed . Mar 29, 2023",
      cryptoImage: coinBase,
      cryptoName: "CoinBase",
    },
    {
      id: 2,
      img: nexenSlider2,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinDate1: "Mon . Mar 27, 2023",
      cryptoImage: binance,
      cryptoName: "Binance",
    },
    {
      id: 3,
      img: nexenSlider3,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinDate1: "Wed . Mar 29, 2023",
      cryptoImage: cnn,
      cryptoName: "CNN",
    },
    {
      id: 4,
      img: nexenSlider1,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinDate1: "Mon . Mar 27, 2023",
      cryptoImage: coinBase,
      cryptoName: "CoinBase",
    }, {
      id: 5,
      img: nexenSlider2,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinDate1: "Wed . Mar 29, 2023",
      cryptoImage: binance,
      cryptoName: "Binance",
    },
    {
      id: 6,
      img: nexenSlider3,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinDate1: "Mon . Mar 27, 2023",
      cryptoImage: cnn,
      cryptoName: "CNN",
    },
    {
      id: 7,
      img: nexenSlider1,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinDate1: "Wed . Mar 29, 2023",
      cryptoImage: coinBase,
      cryptoName: "CoinBase",
    },
    {
      id: 8,
      img: nexenSlider2,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinDate1: "Mon . Mar 27, 2023",
      cryptoImage: binance,
      cryptoName: "Binance",
    },
    {
      id: 9,
      img: nexenSlider3,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinDate1: "Wed . Mar 29, 2023",
      cryptoImage: cnn,
      cryptoName: "CNN",
    }, {
      id: 10,
      img: nexenSlider1,
      name: "VICE WORLD NEWS",
      description: "An unofficial guide to the British Art’s in disputed artefacts.",
      coinDate1: "Mon . Mar 27, 2023",
      cryptoImage: coinBase,
      cryptoName: "CoinBase",
    }
  ],
  view_all: "View All",
  Find_us_in: "Find us in",
  footer_descriptionlight_1: "Unidef refers to the global organization and may refer to one or more of the members of United Decentralized Economy Foundations, each of which is a separate legal entity",
  footer_descriptionlight_2: " This property and any marketing on the property are provided by Unidef Global Services. The products, services, information, and/or materials contained within these web pages may not be available for residents of certain jurisdictions. Investing in tokens is speculative and involves a high degree of risk. Tokens (e.g., shares, SAFTs, contracts, interests) may have no value, and investors may lose some or all of their investment, and in the future, investors may not be able to sell their tokens for anything. All product names, logos, and brands are the property of their respective owners. The use of these names, logos, and brands is for identification purposes only and does not imply endorsement or affiliation.",
  power_link: [
    {
      id: 1,
      ptextLink: "Unidef Ilabs, ",
      plink: routePaths.ilabsScreen,
    },
    {
      id: 2,
      ptextLink: "Agunium, ",
      plink: routePaths.aguniumScreen,
    },
    {
      id: 3,
      ptextLink: "Agilance ",
      plink: routePaths.agilanceScreen,
    },
  ],
  power_link_about: [
    {
      id: 1,
      ptextLink: "Unidef Ilabs, ",
      plink: routePaths.ilabsScreen,
    },
    {
      id: 2,
      ptextLink: "Agunium, ",
      plink: routePaths.aguniumScreen,
    },
  ],
  //ilabs page updates
  ilabs_metatitle: "Unidef iLabs | Empowering A Decentralized Future",
  ilabs_description: "Unidef iLabs invests capital and expertise in exceptional startups and entrepreneurs who share Unidef’s mission of accelerating the world’s transition to a decentralized future.",
  ilabs_keyword: "Unidef iLabs, iLabs, Decentralized Future, Web3 Venture Capital, Venture Capital, Crypto VC, Unidef Ventures, Investor",
  unidef_ilabs: "Unidef iLabs ",
  unidef_ilabs_dot: "Unidef iLabs.",
  ilabs_s1_subtitle2: "invests capital and expertise in exceptional startups and entrepreneurs who share Unidef’s mission of accelerating the world’s transition to a",
  ilabs_s1_subtitle1: "Unidef iLabs ",
  ilabs_s1_subtitle3: " decentralized future.",
  agunium1: "Agunium.",
  ilabs_new_s7_title1: "You asked.",
  ilabs_new_s7_title2: "We answered",
  // s5_inner_content2: "identifies, invests in, and empowers blockchain entrepreneurs and startups.",
  s5_inner_content1: "Unidef iLabs is accelerating the decentralized movement by partnering with forward-thinking builders.",
  s5_person_name: "Azim Ghader,",
  s5_person_position: "Global CEO, Unidef",
  about_new_s8_title: "Unidef offices and Sponsored Locations",
  location_sub_title: "Unidef is a decentralized organization, with no headquarters",
  ilabs_lets_apply1: "Are You Working On An Innovative Startup?",
  ilabs_lets_sub_title1: "If you are an entrepreneur building the new open economy, reach out to us!",
  ilabs_lets_sub_title2: "We invest from pre-seed to product-market fit.",
  apply_now1: "Apply now",
  ilabs_sub_navbar_data: [
    {
      id: 1,
      title: "Overview",
      redirect: ''
    },
    {
      id: 2,
      title: "Investment Focus",
      redirect: ''
    },
  ],
  ilabs_s4_card_data: [
    {
      id: 1,
      img: InvCard1,
      title: "Verticals",
      subTitle: "Unidef iLabs invests in companies and projects that are building for a decentralized future. ",
      content: [
        {
          id: 1,
          description: "We support projects that aim to add real value to the ecosystem. The following categories should help you determine what products are compatible with our aspirations.."
        },
        // {
        //   id: 2,
        //   description: "Unidef iLabs invests in companies and projects that are building for the great to decentralized economy. Unidef iLabs invests in companies and projects."
        // }
      ]
    },
    {
      id: 2,
      img: InvCard2,
      title: "Targets",
      subTitle: "We believe that with the structure of our program, we can help incubated startups succeed, grow, and reach ambitious valuation targets.",
      content: [
        {
          id: 1,
          description: "We believe that with the structure of our program, we can help incubated startups succeed, grow, and reach ambitious valuation targets."
        },
      ]
    },
    {
      id: 3,
      img: InvCard3,
      title: "Stage",
      subTitle: "Focus on the Pre-Seed, Seed and A rounds as entry points into equity and crypto tokens pre-launch.",
      content: [
        {
          id: 1,
          description: "Focus on the Pre-Seed, Seed and A rounds as entry points into equity and crypto tokens pre-launch, with follow-ons for outperforming companies in Series B and onward.."
        },
      ]
    },
  ],
  ilabs_new_s7_datalist_acordion: [
    {
      id: 1,
      title: "When is the right time to reach out?",
      description0: "It is never too early to start a conversation. We most commonly talk to founders as they're starting their pre-seed or seed fundraising process.The earlier we can invest, the more helpful we can be.",
      description1: "We like to be early investors in your business, even if you are pre-product or pre-revenue. If you're confident in your concept and have started validating your idea, we'd love to learn about you. Typically, we'd like to see a prototype alongside a road map illustrating your approach. If you have a lead investor, we can move quickly to match your timeline.",
    },
    {
      id: 2,
      title: "Does Unidef provide investment for pre-seed or seed startups?",
      description: "Absolutely! At Unidef, our mission is to be the earliest supporters and advocates for the visionary founders who are shaping the decentralized future. We are committed to collaborating with these entrepreneurs from the very beginning and having faith in their ideas even when others may not yet grasp their potential. Our partnership always begins at the initial stages of a startup's journey.",
    },
    {
      id: 3,
      title: "What is your investment process and how long does it take?",
      description0: "The investment process varies but typically consists of an initial meeting, follow-up discussions, a partner meeting, and a final decision. The timeline can range from under a week to a couple months, depending on the founder's pace and our conviction about the company. Key steps include evaluating the business, in-depth conversations with partners, presenting to the entire partnership, and receiving a final decision within 24 hours after the partner meeting. Kindly follow ",
      btnName: "this link",
      link: "https://go.unidef.org/i",
      description001: " to view the comprehensive process on that page.",
    },
    {
      id: 4,
      title: "What is your typical check size?",
      description0: "Our investment amounts generally depend on Unidef members' policies; however, we maintain a high level of flexibility. Please complete the ",
      btnName: "form",
      link: "https://go.unidef.org/i",
      description001: ", and we will get in touch with you.",
      // imageYellowLine: true,
    },
    {
      id: 5,
      title: "Does Unidef iLabs invest in tokens?",
      description0: "Yes, Unidef iLabs considers token-exclusive investments when appropriate. Meanwhile, for immediate investment in digital assets and cryptocurrencies, ",
      btnName: "Agunium",
      link: routePaths.aguniumScreen,
      description001: " is an option, as their investment strategy is solely focused on these areas.",
    },
  ],
  //Agnium page Updates
  metaheader_title_agunium: "Agunium | Investing In Decentralized Assets And Cryptos Since 2015",
  metaheader_description_agunium: "Agunium supports and invests in decentralized assets and cryptos that will fundamentally redefine the internet, financial system, and money in a way that empowers all to take full control of their finances, data, and identity.",
  metaheader_keyword_agunium: "Agunium, Unidef, Unidef Agunium, Decentralized Future, Web3 Venture Capital, Crypto VC, Blockchain Technology, Unidef Funding",
  agunium_sub_navbar_data: [
    {
      id: 1,
      title: "Overview",
      redirect: ''
    },
    {
      id: 2,
      title: "Investment Focus",
      redirect: ''
    },
    {
      id: 3,
      title: "Our Portfolio ",
      redirect: ''
    },
  ],
  tooltip_text_agnium: "Digital asset prices can be volatile. You are solely responsible for your decisions, and Unidef and/or its affiliates are not liable for any losses. Please see our Full Disclosure for important details.",
  agnium_s1_title1: "Investing In Cryptos",
  agnium_s1_title2: "Since 2015",
  agunium_s1_subtitle1: "Following the rebranding ",
  agunium_s1_subtitle2: "and restructuring of the Open Market Economy in 2023, Agunium has emerged as a member of Unidef, focusing exclusively on supporting and investing in decentralized assets ",
  agunium_s1_subtitle3: "and cryptocurrencies.",
  Agunium_s2_txt1: "In 2015, Azim Ghader founded Open Market Economy with the primary goal of investing in tokens and cryptocurrencies. Over the years, the company invested in an extensive portfolio of crypto and digital assets, including BTC, ETH, XRP, BNB, MATIC, ADA, IOTA, TRX, XMR, UNI, SYS, XVG, NEO, SC, LSK, DOGE, and various other cryptocurrencies. Despite facing setbacks due to failed crypto projects and unethical activities by some centralized crypto exchanges, Open Market Economy persisted in its growth by investing in a wide array of tokens and digital assets.",
  Agunium_s2_txt2: "After a transformative rebranding and restructuring in 2023, the Open Market Economy underwent a significant evolution, emerging as Agunium. This notable transition led Agunium to become a member of Unidef, centering its operations solely on fostering the growth and investment potential of decentralized assets and cryptocurrencies.",
  Agunium_s2_txt3: "Agunium is committed to investing only in digital assets and cryptocurrencies. For inquiries regarding incubation or other types of investments, please visit ",
  our_investment_thesis: "Our Investment Thesis",
  inv1_s2_txt1: "We believe decentralized assets and crypto will fundamentally redefine the Internet, financial system, and money in a way that empowers all to take full control of their finances, data, and identity. To ensure ongoing growth, broader adoption, and success of the crypto ecosystem, it is crucial to support these projects.",
  our_investment_focus: "Our Investment Focus",
  inv2_s3_txt: "Since 2015, Agunium's investment focus has been primarily on tokens and cryptocurrencies. The company has dedicated itself to supporting and investing in decentralized assets and cryptocurrencies, aiming to tap into the expanding digital asset market and foster the adoption of cryptos.",
  s4_description: "Agunium has focused on investing in tokens and cryptocurrencies since 2015. Please note that this is not an exhaustive list, as certain projects have been left out due to their failure over time or the exit strategies we had in place for some tokens. ",
  agnium_s5_subtitle: "Advancing the Global Economies of the Future, Together.",
  agunium_s5_inner_content1: "Through persistence and innovation, we turn the challenges of the crypto landscape into opportunities for growth and progress.",
  s5_person_position_agunium: "Global CEO of Unidef",
  apply_to_agunium: "Apply to Agunium",
  agunium_sub_title: "Calling decentralized assets and cryptos only!",
  agunium_new_s6_title: "News & Insights",
  agunium_sub_title2: "Contact us at ",
  agunium_sub_title3: "agunium@unidef.org",
  agunium_sub_title4: " for inquiries and supplementary materials.",
  agunium2: "Agunium",
  fundin_title: "Are you seeking funding?",
  fundin_title1: "Are you",
  fundin_title2: "seeking funding?",
  agunium_s4_card_data: [
    {
      id: 1,
      img: InvaCard1,
      title: "Global Resources",
      subTitle: "At Unidef Agunium, we offer projects access to a vast array of international resources. Our aim is to streamline our partner' global expansion efforts by addressing administrative, regulatory, and marketing needs for their worldwide business ventures.",
      // content: [
      //   {
      //     id: 1,
      //     description: "Unidef iLabs invests in companies and projects that are building for the great to decentralized economy. Unidef iLabs invests in companies and projects that are building for the great to decentralized economy."
      //   },
      //   {
      //     id: 2,
      //     description: "Unidef iLabs invests in companies and projects that are building for the great to decentralized economy. Unidef iLabs invests in companies and projects."
      //   }
      // ]
    },
    {
      id: 2,
      img: InvaCard2,
      title: "Ecosystem Support",
      subTitle: "Projects that involve our deep engagement and investment are referred to Unidef, presenting an opportunity to tap into numerous Unidef resources.",
      // content: [
      //   {
      //     id: 1,
      //     description: "Unidef iLabs invests in companies and projects that are building for the great to decentralized economy. Unidef iLabs invests in companies and projects that are building for the great to decentralized economy."
      //   },
      //   {
      //     id: 2,
      //     description: "Unidef iLabs invests in companies and projects that are building for the great to decentralized economy. Unidef iLabs invests in companies and projects."
      //   }
      // ]
    },
    {
      id: 3,
      img: InvaCard3,
      title: "Decentralized Future",
      subTitle: "At Unidef, our primary objective is clear: decentralization. We are dedicated to assisting our projects in achieving decentralization, ensuring success in this key area.",
      // content: [
      //   {
      //     id: 1,
      //     description: "Unidef iLabs invests in companies and projects that are building for the great to decentralized economy. Unidef iLabs invests in companies and projects that are building for the great to decentralized economy."
      //   },
      //   {
      //     id: 2,
      //     description: "Unidef iLabs invests in companies and projects that are building for the great to decentralized economy. Unidef iLabs invests in companies and projects."
      //   }
      // ]
    },
  ],
  agunium_new_s7_datalist_acordion: [
    {
      id: 1,
      title: "When is the right time to reach out?",
      description: "It is never too early to initiate a conversation. At Agunium, we invest in private sales, ICOs, and in some cases, post-ICO or IDO stages. You can contact us at any time or stage.",
    },
    {
      id: 2,
      title: "What is your typical investment process?",
      description: "Generally, we invest in tokens based on our internal processes and R&D. Most of the time, we invest in projects without direct interaction with the project team. As such, any community member can introduce any project to us, regardless of its scale. We will review and evaluate it based on our protocol and decide whether to support and invest in it. ",
    },
    {
      id: 3,
      title: "What is your typical check size?",
      description: "Since we invest and support projects without necessarily interacting with the team, our investment process is different and we cannot disclose more specific details. However, the investment amount can vary based on the token and digital asset market criteria.",
    },
    {
      id: 4,
      title: "What is the relationship between Unidef and Agunium?",
      description: "The Agunium team works closely with Unidef, The Investor & Builder of a Decentralized Future, to leverage Unidef's global footprint and operating experience. We recently rebranded from Open Market economy to Agunium, to emphasize our link with Unidef.",
      // imageYellowLine: true,
    },
  ],
  //Nexensu page updates
  metaheader_nexensu_title: "Nexensu | Helping brands predict and plan for a decentralized future",
  metaheader_nexensu_description: "At Nexensu, we are inspired by the decentralized future. Our mission is to inspire people and brands to impact the world.",
  metaheader_nexensu_keyword: "Nexensu, Unidef, Decentralized Future, Crypto, Decentralization, Web3, Web3 Branding,Web3 Marketing, Web3 Transformation, Advertising Agency",
  nexus_s4_sub_title: "At Nexensu, we offer a wide range of services designed to help brands and people to impact the world.",
  nexensu1: "Nexensu (/nɛk-sɛn-su/)",
  nexensu_sub_navbar_data: [
    {
      id: 1,
      title: "Overview",
      redirect: ''
    },
    {
      id: 2,
      title: "Our Services",
      redirect: ''
    },
    {
      id: 3,
      title: "Jobs",
      redirect: ''
    },
  ],
  nexensu_new_s3_datalist: [
    {
      id: 1,
      title: "Strategy",
      description: "Expert guidance to navigate the complexities of marketing, branding, and business growth.",
    },
    {
      id: 2,
      title: "Creatives",
      description: "Compelling visual and written content that captures attention and communicates your vision.",
    },
    {
      id: 3,
      title: "Media",
      description: "Targeted media planning, buying, and analytics to maximize reach and engagement.",
    },
    {
      id: 4,
      title: "KOL",
      description: "Influencer partnerships that amplify your message and build authentic connections with your audience.",
    },
    {
      id: 5,
      title: "Public Relation & Affairs",
      description: "Effective PR strategies and campaigns to enhance brand reputation and stakeholder relationships.",
    },
    {
      id: 6,
      title: "Policy Advocacy",
      description: "Shaping public policy through strategic advocacy and thought leadership.",
    },
    {
      id: 7,
      title: "Web3 Marketing",
      description: "Innovative marketing solutions for decentralized technologies and blockchain projects.",
    },
    {
      id: 8,
      title: "Technology Consulting",
      description: "Cutting-edge technology advice and implementation to drive digital transformation.",
    },
  ],
  nexensu_new_s7_datalist_acordion: [
    {
      id: 1,
      title: "What types of advertising services does Nexensu specialize in?",
      description: "Nexensu is a full-service advertising agency specializing in a wide range of services to cater to diverse client needs. Click on “ Our Services” for more Information. ",
      subContent: [
        {
          id: 1,
          boldtext: "Strategy: ",
          boldsubtext: "Developing effective marketing strategies by analyzing target audiences, industry trends, and competition to create comprehensive plans for maximum reach and ROI. "
        },
        {
          id: 2,
          boldtext: "Creative: ",
          boldsubtext: "Crafting unique and engaging ad campaigns with a talented team of designers and copywriters that resonate with the target audience."
        },
        {
          id: 3,
          boldtext: "Media: ",
          boldsubtext: "Strategic media planning and buying to ensure the right message reaches the right audience at the right time, using data and insights for channel and platform selection."
        },
        {
          id: 4,
          boldtext: "KOL (Key Opinion Leader) Management: ",
          boldsubtext: "Connecting brands with influencers who can effectively endorse their products or services, increasing brand awareness and credibility. "
        },
        {
          id: 5,
          boldtext: "Public Affairs: ",
          boldsubtext: "Helping businesses navigate complex regulatory environments and maintain positive relationships with stakeholders. "
        },
        {
          id: 6,
          boldtext: "Policy Advocacy: ",
          boldsubtext: "Assisting organizations in shaping public policy and influencing government decisions to support their business objectives. "
        },
        {
          id: 7,
          boldtext: "Web3 Marketing: ",
          boldsubtext: "Designing and implementing marketing strategies tailored for decentralized platforms, blockchain technology, and cryptocurrency audiences. "
        },
        {
          id: 8,
          boldtext: "Technology Consulting: ",
          boldsubtext: "Providing expert guidance on the latest technologies and digital solutions to optimize marketing efforts and drive innovation. "
        },
      ]
    },
    {
      id: 2,
      title: "How does Nexensu ensure the privacy and security of its clients' data?",
      description: "Nexensu takes the privacy and security of its clients' data very seriously. We implement industry best practices and robust security measures to protect all sensitive information entrusted to us. Our team adheres to strict confidentiality protocols and ensures that all data is stored securely, with access limited to authorized personnel only. Additionally, we comply with all relevant data protection regulations and work diligently to maintain the trust and confidence of our clients.",
    },
    {
      id: 3,
      title: "What is the typical response time when I contact Nexensu for advertising assistance?",
      description: "While response times may vary depending on the complexity of your inquiry, We strive to respond to all inquiries as quickly as possible. You can typically expect a response from us within 1-2 business days.",
    },
  ],
  nexensu_title1: "Helping Brands",
  nexensu_title2: " Predict And Plan For A",
  nexensu_title3: " Decentralized Future",
  more_about_nexensu: "More About Nexensu",
  nexensu_s3_txt1: "Hello. We are Nexensu. Founded in 2023, a member of Unidef, is committed to empowering brands and organizations in their branding and marketing efforts, assisting them in becoming well-known leaders in the decentralized ecosystem.",
  nexensu_s3_txt2: "Decentralization presents both challenges and opportunities. At Nexensu, we firmly believe that smart organizations can leverage these to their advantage by strategically positioning their brand and marketing efforts. With this belief at our core, we focus on providing services that enable brands to make a lasting impact in the world.",
  nexensu_s3_txt3: " Nexensu offers forward-thinking services that assist brands in their marketing efforts, guiding them in navigating the challenges and opportunities arising from decentralization.",
  nexensu_s3_title: "The Future Inspires Us; We Work to Inspire",
  nexensu_s3_title1: "The Future",
  nexensu_s3_title2: "Inspires Us; We Work",
  nexensu_s3_title3: "to Inspire",
  nexensu_s3_txt11: "At Nexensu, we are inspired by the decentralized future. Our mission is to inspire people and brands to impact the world.",
  nexensu_s1_subtitle1: "We are ",
  nexensu_s1_subtitle2: "Nexensu.",
  nexensu_s1_subtitle3: " We team together to ",
  nexensu_s1_subtitle4: "help ",
  nexensu_s1_subtitle5: "brands predict and plan for a decentralized future. We are the network designed for what’s ",
  nexensu_s1_subtitle6: "next.",
  nexensu_sbt_1: "We are    ",
  nexensu_sbt_2: "to help  ",
  nexensu_sbt_3: " next.",
  nexensu_s7_title1: "The Decentralized Future, ",
  nexensu_s7_title2: "Faster.",
  nexensu_s7_titlemob1: "The Decentralized",
  nexensu_s7_titlemob2: "Future, Faster.",
  nexensu_lets_sub_title1: "Eager to explore our services? Get in touch!",
  nexensu_lets_sub_title2: "Drop us a line at  ",
  nexensu_lets_sub_title3: "nexensu@unidef.org ",
  nexensu_lets_sub_title4: "for inquiries.",
  hometooltipText: "SOME OF OUR INVESTED ASSETS MAY NOT BE OFFERED OR SOLD IN THE UNITED STATES OR TO U.S. PERSONS AND ANY OTHER PROHIBITED PERSONS OR JURISDICTIONS. PLEASE CONDUCT YOUR OWN RESEARCH AND INVEST BASED ON YOUR OWN DISCRETION. UNIDEF AND ITS MEMBERS SHALL NOT BE HELD LIABLE OR RESPONSIBLE IN ANY MANNER WHATSOEVER FOR YOUR CHOICES.",
  s5_right_title: "At Unidef, we believe in a decentralized future and are building an ever- expanding ecosystem to help make it a reality.",
  //Agilance page Data
  metaheader_title_agilance: "Agilance | Integrated legal solutions, for a decentralized future",
  metaheader_description_agilance: "Agilance, a member of Unidef, works to empower a decentralized future where players are accountable to their communities.",
  metaheader_keyword_agilance: "Unidef, Agilance, Decentralized Future, Web3 Legal firm, Crypto law firm, Blockchain Lawyers, Blockchain Law, Law, Crypto Lawyer",
  agilance1: "Agilance (/ˈædʒɪləns/)",
  agilance_sub_navbar_data: [
    {
      id: 1,
      title: "Overview",
      redirect: ''
    },
    {
      id: 2,
      title: "Our Approach",
      redirect: ''
    },
    {
      id: 3,
      title: "Jobs ",
      redirect: ''
    },
  ],
  agliance_new_s6_title: "The Latest From Agilance",
  Agilance: "Agilance",
  agilance_s1_title1: "Integrated Legal Solutions",
  agilance_s1_title2: "For A Decentralized Future",
  agilance_s1_titlemob1: "Integrated Legal",
  agilance_s1_titlemob2: "Solutions For A",
  agilance_s1_titlemob3: "Decentralized Future",
  agilance_s1_subtitle1: "Agilance",
  agilance_s1_subtitle2: " works to ",
  agilance_s1_subtitle3: "empower",
  agilance_s1_subtitle4: " a decentralized future where players are accountable to their ",
  agilance_s1_subtitle5: "community.",
  learn_more2: "Learn More",
  disclaimer_agilance: "Unidef does not provide legal services.",
  tooltip_text_agilance: "Agilance means the legal practices of Unidef member firms, their affiliates or their related entities that provide legal services. The exact nature of these relationships and provision of legal services differs by jurisdiction, to allow compliance with local laws and professional regulations. Each Agilance Legal practice is legally separate and independent, and cannot obligate any other Agilance Legal practice. Each Agilance Legal practice is liable only for its own acts and omissions, and not those of other Agilance Legal practices. Due to legal, regulatory, and other factors, not all member firms, their affiliates, or their related entities provide legal services or are associated with Agilance Legal practices. Unidef does not provide legal services.",
  our_story_data: [
    {
      id: 1,
      title: "Our Story",
      subtitle1: "Founded in 2023, Agilance, a member of Unidef, is a visionary Integrated Legal Solutions inspired by the need to address unaccountable players within communities. We are dedicated to empowering a decentralized future and have confidence in the transformative power of decentralized assets, entities, and technologies. Our mission is to support these innovations by providing Integrated Legal Solutions, combating malicious players, ensuring their accountability, and protecting decentralized communities through our global network of affiliated law firms.",
      subtitle2: "At Agilance, we connect you with competent legal professionals and industry experts who offer customized, insightful legal advice tailored to your specific needs. Our worldwide affiliates boast a deep understanding of these emerging technologies and consistently stay at the forefront of industry trends and challenges.",
    },
    {
      id: 2,
      title: "Our Approach",
      subtitle1: "As the global community becomes increasingly interconnected, opportunities and risks arise across various markets, sectors, and legal areas. To successfully achieve business objectives and mitigate risk, adopting a connected perspective is crucial. Focusing on integrated legal solutions, we simplify complexities and concentrate on essentials, ensuring clear, targeted, and practical advice for a decentralized future.",
      idName: "our-approach",
    },
    {
      id: 3,
      title: "Our Global Network",
      subtitle1: "Our worldwide network of affiliated law firms and personnel covers every time zone, allowing us to actively listen and pose pertinent questions addressing what truly matters.",
    },
    {
      id: 4,
      title: "Our Services",
      subtitle1: "Agilance specializes in providing a variety of services related to the crypto market and decentralized future. We also focus on combating malicious players in the field and protecting the community. To achieve these goals, we rely on our extensive worldwide network of affiliated law firms that share our commitment to empowering a decentralized future.",
    },
  ],
  agilance_new_s7_datalist_acordion: [
    {
      id: 1,
      title: "What is Agilance and what does it specialize in?",
      description: "Agilance is a visionary Integrated Legal Solutions firm founded in 2023 and a Unidef member. We specialize in providing legal services related to the crypto market and decentralized future, combating malicious players, ensuring accountability, and protecting communities through our global network of affiliated law firms.",
    },
    {
      id: 2,
      title: "How does Agilance support the decentralized future?",
      description: "Agilance supports the decentralized future by offering integrated legal solutions tailored to the specific needs of clients involved in decentralized assets, entities, and technologies. Our worldwide affiliates have a deep understanding of emerging technologies and consistently stay at the forefront of industry trends and challenges.",
    },
    {
      id: 3,
      title: "What types of legal services does Agilance specialize in?",
      description: "Agilance specializes in providing legal services related to the crypto market, decentralized technologies, entities and assets. We collaborate with top law firms that focus on cryptocurrencies, blockchain technology, and other emerging innovations in this space. Additionally, we actively combat malicious players in the field to ensure a secure and fair environment for all.",
    },
    {
      id: 4,
      title: "How does Agilance protect clients from malicious players in the decentralized market?",
      description: "Agilance takes a proactive approach to combating malicious players in the decentralized market by providing legal solutions that ensure accountability and protect communities. Our team works closely with clients to identify potential threats and develop strategies to mitigate risks. Additionally, we collaborate with our global network of affiliated law firms to address any legal disputes or issues that may arise.",
      // imageYellowLine: true,
    },
    {
      id: 5,
      title: "How does Agilance ensure client confidentiality and data privacy?",
      description: "Agilance takes client confidentiality and data privacy very seriously. We follow strict protocols to safeguard sensitive information, adhere to applicable data protection laws and regulations, and implement robust security measures to protect client data from unauthorized access and breaches.",
    },
    {
      id: 6,
      title: "What is the typical response time when I contact Agilance for legal assistance?",
      description: "While response times may vary depending on the complexity of your inquiry. We strive to respond to all inquiries as quickly as possible. You can expect a response from our team within 1-2 business days. In some cases, we may need to coordinate with affiliates to obtain a clear response, which could take longer, but rest assured, we will get back to you.",
    },
  ],
  agilance_last_title1: "Looking for",
  tooltip_text_agilance1: "Agilance is a global legal practice providing client services worldwide through its member firms and/or affiliates. This website and its publications are not designed to provide legal or other advice, and you should not take, or refrain from taking, action based on its content. For specific legal advice, please consult with one of our attorneys or affiliates. Unidef does not provide legal services.",
  agilance_last_title2: "Integrated legal solutions?",
  agilance_lets_sub_title1: "Let us connect you with our highly qualified legal ",
  agilance_lets_sub_title2: "professionals and industry experts.",
  get_in_touch_now: "Get in touch now",
  contact_now: "Contact Now",
  contact_nexensu: "Contact Nexensu",
  work_ar_unidef: "Work at Unidef",
  // slider_text: "Unidef Launches Revolutionary Initiative to Assist Crypto Community Affected by Centralized Platforms Misconduct",
  say_hello: "Say Hello",
}
