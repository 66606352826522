import Loadable from 'react-loadable';
const Loading = ({ error }) => {
  if (error) {
    return 'Oh nooess!';
  } else {
    return '';
  }
}
const HomeScreenNew = Loadable({
  loader: () => import(/* webpackChunkName: "HomePage" */'../pages/HomeScreenNew'),
  loading: Loading
});
const TokenNewScreen = Loadable({
  loader: () => import(/* webpackChunkName: "UnidefPage" */'../pages/TokenNewScreen'),
  loading: Loading
});
const UraniumScreen = Loadable({
  loader: () => import(/* webpackChunkName: "DesamountPage" */'../pages/UraniumScreen'),
  loading: Loading
});
const NewVerifyScreen = Loadable({
  loader: () => import(/* webpackChunkName: "VerifyPage" */'../pages/NewVerifyScreen'),
  loading: Loading
});
const ManagerScreen = Loadable({
  loader: () => import(/* webpackChunkName: "ManagerPage" */'../pages/ManagerScreen'),
  loading: Loading
});
const AboutScreen = Loadable({
  loader: () => import(/* webpackChunkName: "AboutPage" */'../pages/AboutScreen'),
  loading: Loading
});
const IlabsScreen = Loadable({
  loader: () => import(/* webpackChunkName: "IlabsPage" */'../pages/IlabsScreen'),
  loading: Loading
});
const AguniumScreen = Loadable({
  loader: () => import(/* webpackChunkName: "AguniumPage" */'../pages/AguniumScreen'),
  loading: Loading
});
const NexensuScreen = Loadable({
  loader: () => import(/* webpackChunkName: "NexensuPage" */'../pages/NexensuScreen'),
  loading: Loading
});
const AgilanceScreen = Loadable({
  loader: () => import(/* webpackChunkName: "AgilanceScreen" */'../pages/AgilanceScreen'),
  loading: Loading
});
export const routePaths = {
  homeScreenNew: "/",
  homeScreenNewId: "/:id",
  // tokenNewScreen: "/unidef",
  // uraniumScreen: "/decemount",
  newVerifyScreen: "/verify",
  // newAboutScreen: "/about",
  managerScreen: "/manager",
  ilabsScreen: "/iLabs",
  aguniumScreen: "/agunium",
  nexensuScreen: "/nexensu",
  agilanceScreen: "/agilance",
};
export const authRoutes = [
  {
    title: "TokenNewScreen",
    path: routePaths.tokenNewScreen,
    url: routePaths.tokenNewScreen,
    exact: true,
    element: <TokenNewScreen />,
  },
  {
    title: "UraniumScreen",
    path: routePaths.uraniumScreen,
    url: routePaths.uraniumScreen,
    exact: true,
    element: <UraniumScreen />,
  },
  {
    title: "HomeScreenNew",
    path: routePaths.homeScreenNew,
    url: routePaths.homeScreenNew,
    exact: true,
    element: <HomeScreenNew />
  },
  {
    title: "HomeScreenNew",
    path: routePaths.homeScreenNewId,
    url: routePaths.homeScreenNewId,
    exact: true,
    element: <HomeScreenNew />
  },
  {
    title: "NewVerifyScreen",
    path: routePaths.newVerifyScreen,
    url: routePaths.newVerifyScreen,
    exact: true,
    element: <NewVerifyScreen />,
  },
  {
    title: "AboutScreen",
    path: routePaths.newAboutScreen,
    url: routePaths.newAboutScreen,
    exact: true,
    element: <AboutScreen />,
  },
  {
    title: "ManagerScreen",
    path: routePaths.managerScreen,
    url: routePaths.managerScreen,
    exact: true,
    element: <ManagerScreen />,
  },
  {
    title: "IlabsScreen",
    path: routePaths.ilabsScreen,
    url: routePaths.ilabsScreen,
    exact: true,
    element: <IlabsScreen />,
  },
  {
    title: "AguniumScreen",
    path: routePaths.aguniumScreen,
    url: routePaths.aguniumScreen,
    exact: true,
    element: <AguniumScreen />,
  },
  {
    title: "NexensuScreen",
    path: routePaths.nexensuScreen,
    url: routePaths.nexensuScreen,
    exact: true,
    element: <NexensuScreen />,
  },
  {
    title: "AgilanceScreen",
    path: routePaths.agilanceScreen,
    url: routePaths.agilanceScreen,
    exact: true,
    element: <AgilanceScreen />,
  },
];